import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { FaEllipsisV } from "react-icons/fa";
import AddEntityGroupModal from "./AddEntityGroupModal";
import AddEntityCustomerCompanyModal from "./AddEntityCustomerCompanyModal";
import DropdownActionsMenu from "../../modUtils/components/dropdownActionsMenu/DropdownActionsMenu";

export default function EntityNodeActionsMenu({ entityData, supportId, getEntities }) {
    const { t } = useTranslation();
    const [isMenuVisible, setIsMenuVisible] = useState(false);
    
    //fermer le popover
    const hide = () => {
        setIsMenuVisible(false);
    };

    const addEntityGroupModal = [
            <AddEntityGroupModal
                closeModal={hide}
                entityCompany={entityData}
                getEntities={getEntities}
            />
    ];
    const addEntityCustomerCompanyModal = [
        <AddEntityCustomerCompanyModal
            closeMenu={hide}
            entityParent={entityData.id}
            supportId={supportId}
            getEntities={getEntities}
        />
    ];
    const menuItemsEntityCompany = [...addEntityGroupModal, ...addEntityCustomerCompanyModal];
    const menuItemsEntityGroup = [...addEntityCustomerCompanyModal];
    const menuItems = entityData.entity_type === "EntityCompany" ? menuItemsEntityCompany : menuItemsEntityGroup;

    return (
        <div onClick={(event) => event.stopPropagation()}>
            <DropdownActionsMenu
                trigger="hover"
                triggerImg={<FaEllipsisV className="text-primary-color"/>}
                placement={"right"}
                menuItems={menuItems}
                visible={isMenuVisible}
                handleVisibleChange={() => setIsMenuVisible(!isMenuVisible)}
            />
        </div>
    );
};
