import React, { useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { message } from "antd";
import {
  Input,
  Button,
} from "../../modUtils/components/componentsLibrary/componentsLibrary";
import { useDispatch } from "react-redux";
import { delete_users_password } from "../slices/authenticationSlice";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AlertMessage from "../../modUtils/components/AlertMessage";

const PasswordResetForm = ({ onBackToLoginClick }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const schema = Yup.object().shape({
    email: Yup.string()
      .required(t("loginInformation.required"))
      .email(t("loginInformation.emailInvalid")),
  });
  const handleSubmit = async (values, resetForm) => {
    setIsSubmitting(true);
    try {
     await dispatch(delete_users_password(values.email)).unwrap();
     message.success({
      content: (
        <AlertMessage alertMessage={t(`loginInformation.resetMessage ${values.email}`)} />
      ),
    });
  }
    catch (error) {
      message.error({
        content: (
          <AlertMessage
            status={error.status}
            errorDetail={error.data.detail}
          />
        ),
      });
    } finally {  
      resetForm();
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <Formik
        validationSchema={schema}
        initialValues={{ email: "" }}
        onSubmit={(values, { resetForm }) => {
          handleSubmit(values, resetForm);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isValid,
        }) => (
          <div className="login">
            <div className="form">
              <form id="form" noValidate onSubmit={handleSubmit}>
                <Input
                  type="email"
                  name="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  placeholder={t("loginInformation.emailPlaceholder")}
                  id="email"
                />
                <p className="error text-error-color">
                  {errors.email && touched.email && errors.email}
                </p>

                <Button
                  htmltype="submit"
                  type="primary"
                  style={{ borderRadius: "15px", height: "40px" }}
                  htmlType="submit"
                  block
                  disabled={isSubmitting || !isValid}
                >
                  {t("loginInformation.reset")}
                </Button>
              </form>
            </div>
          </div>
        )}
      </Formik>
      <div
        className="underline text-[9pt] text-[#41B9FF] text-center align-middle cursor-pointer mt-5 hover:text-[#E53D3A]"
        onClick={onBackToLoginClick}
      >
        Se connecter
      </div>
    </>
  );
};

export default PasswordResetForm;
