import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { support_logs_by_group_paginated_get } from "../../modSupport/slices/supportSlices";
import { dateFormat } from "../../modUtils/functions/dateFormat";
import TablePaginagtedScroll from "../../modUtils/components/componentsLibrary/table/TablePaginagtedScroll";
import { ReactComponent as CheckIconSvg } from "../../modUtils/assets/images/checkIcon.svg";
import { ReactComponent as NotCheckIconSvg } from "../../modUtils/assets/images/notCheckIcon.svg";
import CKToolTip from "../../modUtils/components/CKToolTip/CKToolTip";

export default function EntityLog({ entity }) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { groupId } = useSelector((state) => state.manager);
    
    const columns = [
        {
            title: t("entityLog.date"),
            dataIndex: "date_creation",
            key: "date_creation",
            render: (text, record) => <span className="truncate text-[10px]">{dateFormat(text)}</span>,
            width: "10%"
        },
        {
            title: t("entityLog.supportNumber"),
            dataIndex: "support_number",
            key: "support_number",
            width: "10%",
            render: (text, record) => <span className="truncate text-[10px]">{text}</span>,
        },
        {
            title: t("entityLog.siteName"),
            dataIndex: "site_name",
            key: "site_name",
            width: "10%",
            render: (text, record) => <span className="truncate text-[10px]">{text}</span>,
        },
        {
            title: t("entityLog.equipmentName"),
            dataIndex: "equipment_name",
            key: "equipment_name",
            width: "10%",
            render: (text, record) => <span className="truncate text-[10px]">{text}</span>,
        },
        {
            title: t("entityLog.session"),
            dataIndex: "session_id",
            key: "session_id",
            width: "10%",
            render: (text, record) => 
                <CKToolTip
                   placement="topLeft"
                   content={t("entityLog.toolTipContent")}// TODO CH A TRADUIRE APRES VERIFICATION
                   trigger="click"
               >
                   <span className="truncate text-[10px]">{text}</span>
               </CKToolTip>
        },
        {
            title: t("entityLog.content"),
            dataIndex: "content",
            key: "content",
            width: "60%",
            render: (text, record) => <span className="truncate text-[10px]">{text}</span>,
        },
        {
            title: t("entityLog.status"),
            dataIndex: "error_support",
            key: "error_support",
            // Todo ajouté un popover avec le detail de l'ereur
            render: (text, record) => text === 0 ? <CheckIconSvg/> : <NotCheckIconSvg/>,
            width: "1%"
        }
    ];

    const getSupportTransactions = async (limit, offset) => {
        const response = await dispatch(
            support_logs_by_group_paginated_get({
                groupId: groupId,
                entityId: entity?.id,
                limit,
                offset
            })
        ).unwrap();
        return response;
    };

    return (
        <div> 
        <TablePaginagtedScroll
            label={t("entityLog.supportLogs")}
            fetch={getSupportTransactions}
            effect={entity}
            columns={columns}
        />
        </div>
    );
};
