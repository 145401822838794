import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { message } from "antd";
import { get_constraint_identification } from "../slices/constraintIdentificationSlice";
import Button from "../../modUtils/components/buttons/Button";
import AlertMessage from "../../modUtils/components/AlertMessage";
import { Label, Spinner } from "../../modUtils/components/componentsLibrary/componentsLibrary";

export default function IdentificationConstraints({
    setSelectedOptions,
    selectedOptions,
    selectedConfigId,
    configurationDetail
}) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const { groupId } = useSelector((state) => state.manager);
    const [idConfigue, setIdConfigue] = useState(selectedConfigId);

    const SupportFormatToDisplay = Object.freeze({
        1: 'eSupportFormat.badgeMifare',
        2: 'eSupportFormat.qrCode'
    });
      
    // récupérer la liste des contraintes d'identification
    const getConstraintsIdentification = async () => {
        try {
            setLoading(true);
            const response = await dispatch(
                get_constraint_identification({
                    groupId,
                    configurationId: selectedConfigId
                })
            ).unwrap();

            if (response === "Object Not found") {
                // Activer tous les boutons
                setSelectedOptions(Object.keys(SupportFormatToDisplay).map(Number));
            } else if (response?.formats_authorized) {
                // Ajouter tous les formats autoriser à selectedOptions pour activer les boutons autoriser
                setSelectedOptions(response?.formats_authorized);
            }
        } catch (error) {
            message.error({
                content: (
                    <AlertMessage
                        status={error?.status}
                        alertMessage={`Une erreur est survenue lors de la récupération des contraintes d'identification !`}
                        errorDetail={error?.data?.detail}
                    />
                )
            });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        setIdConfigue(selectedConfigId);
        if (selectedConfigId !== idConfigue) {
            if (groupId && selectedConfigId) {
                getConstraintsIdentification();
            }
        }
    }, [groupId, selectedConfigId]);
    
    // Met à jour l'état des options sélectionnées
    const handleSelect = (index) => {
        setSelectedOptions(prevState => {
            if (typeof prevState === "object") {
                if (prevState?.includes(index)) {
                    // Si l'index est déjà dans selectedOptions, on le retire
                    return prevState?.filter(option => option !== index);
                } else {
                    // Sinon, on l'ajoute à selectedOptions
                    return [...prevState, index];
                }
            }else if (typeof prevState === "number") {
                if ([prevState]?.includes(index)) {
                    // Si l'index est déjà dans selectedOptions, on le retire
                    return [prevState]?.filter(option => option !== index);
                }else {
                    // Sinon, on l'ajoute à selectedOptions
                    return [prevState, index];
                }
            }
        });
    };

    return (
        <>
        {loading ? <Spinner/> :
            <div className="flex flex-col justify-start items-start w-max m-auto">
                <Label label="identificationConstraints.identification"/>

                <div className="flex flex-row">
                    {Object.keys(SupportFormatToDisplay).map((key, index) => (
                        <Button
                            key={key}
                            className={`${!configurationDetail?.is_future && "!cursor-not-allowed"} m-2`}
                            color={typeof selectedOptions === "object"
                                ? selectedOptions?.includes(parseInt(key)) ? "primary" : "outline"
                                : [selectedOptions]?.includes(parseInt(key)) ? "primary" : "outline"} 
                            onClick={configurationDetail?.is_future ? () =>  handleSelect(parseInt(key)) : null}
                        >
                            {t(SupportFormatToDisplay[key])}
                        </Button>
                    ))}
                </div>
            </div>
        }
        </>
    );
};
