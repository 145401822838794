import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Input, Modal } from "../../../modUtils/components/componentsLibrary/componentsLibrary";
import { eEquipmentType } from "../../../../commun_global/enums";
import { update_equipment_detail_by_group } from "../../../modEquipment/slices/equipmentSlice";
import { hasPermission } from "ck_commun/src/app/modUtils/functions/handlePermissions";
import PermissionDeniedPage from "ck_commun/src/app/modUtils/components/errorPage/PermissionDeniedPage";
import ButtonDisabled from "../../../modUtils/components/buttons/ButtonDisabled";
//import ReactPDF from '@react-pdf/renderer';
import "./EquipmentUpdateModal.scss";
import Button from "../../../modUtils/components/buttons/Button";
//import EquipmentConfirmityCertificate from "../equipmentConfirmityCertificate/EquipmentConfirmityCertificate";

export default function EquipmentUpdateModal({
  closeModal,
  buttonType,
  equipment
}) {
    const [modalVisible, setModalVisible] = useState(false);
    const [serverMode, setServerMode] = useState("aucun");
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { groupId, loadingGroup } = useSelector((state) => state.manager);
    const { selectedGroup } = useSelector((state) => state.manager);
    const permissions = selectedGroup?.permissions;
    const verifyPermission = hasPermission('mod_equipment:can_configure', permissions);
    const groupPermissions = selectedGroup?.plan?.permissions;
    const verifyGroupPermission = hasPermission('mod_equipment:can_configure', groupPermissions);

    // close EquipmentManageModel when opening EquipementUpdateModel
    const openModal = () => {
        formik.resetForm();
        setModalVisible(true);
        closeModal();
    };

    const onChangeServerMode = ({ target: { value } }) => {
        setServerMode(value);
    };
    const handleCancel = () => {
        formik.resetForm();
        setModalVisible(false);
    };
    const formik = useFormik({
        initialValues: {
            id: equipment.id || "",
            serialNumber: equipment.serial_number || "",
            name: equipment.name || "",
            type: t(eEquipmentType[equipment.type]) || "",
            connectionType: equipment.connection_type || "",
            ipWan: equipment.ip_wan || "",
            portAccessWan: equipment.port_access_wan ,
            // typeImprimante: equipement.typeImprimante,
            // mdpEquipement: equipement.mdpEquipement,
            // modeCnxEquipement: equipement.modeCnxEquipement,
        },
        onSubmit: (values) => {
            dispatch(
                update_equipment_detail_by_group({
                    groupId,
                    equipmentId: values.id,
                    name: values.name,
                    ipWan: values.ipWan,
                    portAccessWan: values.portAccessWan,
                })
            );
            formik.resetForm();
            setModalVisible(false);
        }
    });

    return (
        <>
        {buttonType === "button" ? (
            verifyPermission && verifyGroupPermission ?
                <Button 
                    onClick={openModal} 
                    color="primary"
                    className="h-10 w-full mr-[5px]"
                    size="sm"
                >
                    {t("equipment.transUpdateEquipmentButton1")}
                </Button>
            :
                <ButtonDisabled
                    onClick={openModal}
                    className="h-10 w-full mr-[5px]"
                    size="sm">
                    {t("equipment.transUpdateEquipmentButton1")}
                </ButtonDisabled>
        ) : (
            <p className={`${!verifyPermission || !verifyGroupPermission ? 'disabled' : ''}`} onClick={openModal}>{t("equipment.transUpdateEquipmentButton2")}</p>
        )}

        <Modal
            title={t("equipment.transUpdateEquipmentModalTitle")}
            isOpen={modalVisible}
            onClose={() => setModalVisible(false)}
            onCancel={verifyPermission && handleCancel}
            onOk={verifyPermission && formik.handleSubmit}
            zIndex={999}
        >
            {!verifyPermission ?
                <PermissionDeniedPage
                    permission={verifyGroupPermission}
                />
            :
                <form onSubmit={formik.handleSubmit}>
                    <Input
                        name="id"
                        label={t("equipment.transEquipmentIdLabel")}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.id}
                        disabled
                    />
                    <Input
                        name="name"
                        label={t("equipment.transEquipmentNameLabel")}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.name}
                    />
                    <Input
                        name="serialNumber"
                        label={t("equipment.transEquipmentSNLabel")}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.serialNumber}
                        disabled
                    />
                    <Input
                        name="type"
                        label={t("equipment.transEquipmentType")}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.type}
                        disabled
                    />

                    {/* <>
                        <Input label={t("equipment.transEquipmentTypePrinter")} value=""/>
                        <Input label={t("equipment.transEquipmentPwd")} name="mdpEquipement"value=""/>
                        <div>
                            <img className="complianceBadge"src={process.env.PUBLIC_URL + "/img/complianceBadge.png"}alt="img"/>
                            <Button type="text">
                                {t("equipment.transEquipmentDownloadCertificate")}
                            </Button>
                            //<span onClick={ReactPDF.render(<EquipmentConfirmityCertificate />,`${__dirname}/example.pdf`)}>{t("equipment.transEquipmentDownloadCertificate")}</span> 
                        </div>
                        <div className="serverModeConnexionContainer">
                            <div>{t("equipment.transEquipmentServerCnxMode")}</div>
                        </div>
                    </> */}

                    <Input
                        label={t("equipment.transConnexionType")}
                        name="connectionType"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.connectionType}
                        disabled
                    />

                    <div className="flex-display justify-content-between">
                        <Input
                            label={t("equipment.transIpAddress")}
                            name="ipWan"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.ipWan}
                        />
                        <Input
                            label="Port"
                            name='portAccessWan'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            type='number'
                            value={formik.values.portAccessWan}
                        />
                    </div>
                </form>
            }
        </Modal>
        </>
    );
};