import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Spinner, Pagination } from "../../../modUtils/components/componentsLibrary/componentsLibrary";
import { shared_equipments_paginated_get } from "../../slices/sharedEquipmentSlice";
import SharedEquipmentList from "../../components/sharedEquipmentList/SharedEquipmentList";
import NoData from "../../../modUtils/components/noData/NoData";
import FormFilter from "../../components/formFilter/FormFilter";
import EquipmentAccessResquestForm from "../../components/equipmentAccessResquestForm/EquipmentAccessResquestForm";
import i18next from "i18next";

export default function SharedEquipmentPage() {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { sharedEquipment, sharedEquipmentCount } = useSelector((state) => state.sharedEquipment);
    const [loading , setLoading] = useState(true);
    const [loadingSearch , setLoadingSearch] = useState(false);
    const { groupId } = useSelector((state) => state.manager);

    const [currentPage, setCurrentPage] = useState(1);
    const [limit, setLimit] = useState(20);
    const [offset, setOffset] = useState(0);
    const [serialNumber, setSerialNumber] = useState(null);
    const [groups, setGroups] = useState(null);
    const [sites, setSites] = useState(null);

    async function fetchDataSharedEquipments(limit, offset, serial_number, groups, sites, setLoading) {
        if (typeof setLoading === "function") {
            setLoading(true);
        }
        await dispatch(
            shared_equipments_paginated_get({
                limit: limit && limit,
                offset: offset && offset,
                serial_number: serial_number && serial_number,
                groups: groups && groups,
                sites: sites && sites
            })
        );
        if (typeof setLoading === "function") {
            setLoading(false);
        }
    };

    // Fonction de gestion de changement de pagination
    function handlePaginationChange(page, pageSize) {
        // Mettez à jour les valeurs de limit et offset en fonction de la page et de la taille de page
        const newOffset = (page - 1) * pageSize;
        setOffset(newOffset);
        if (newOffset < 0) {
            setCurrentPage(1);
        } else {
            setCurrentPage(page);
        }
        setLimit(pageSize);

        // Effectuez une requête à l'API avec les nouvelles valeurs de limit et offset pour obtenir les données correspondantes
        fetchDataSharedEquipments(limit, offset, serialNumber, groups, sites, setLoadingSearch);
    };

    useEffect(() => {
        if (groupId) {
            fetchDataSharedEquipments(limit, offset, serialNumber, groups, sites, setLoading);
        }
    }, [groupId, i18next.language]);

    return (
        <>
        {loading ? (
            <Spinner/>
        ) : (
            <>
            <FormFilter
                fetchDataSharedEquipments={fetchDataSharedEquipments}
                setSerialNumber={setSerialNumber}
                setGroups={setGroups}
                setSites={setSites}
                limit={limit}
                setLoadingSearch={setLoadingSearch}
            />

            <div className="text-center">
                <h1>{t("SharedEquipmentPage.listOfEquipment")}</h1>
            </div>

            <div className="flex flex-raw justify-end items-baseline mb-5">
                <span className="font-semibold mr-2.5">{t("SharedEquipmentPage.missingEquipment")}</span>
                <EquipmentAccessResquestForm/>
            </div>

            {loadingSearch ? (
                <Spinner/>
            ) : (
                sharedEquipment?.length > 0 ? (
                    <>
                    <Pagination
                        defaultCurrent={currentPage}
                        defaultPageSize={limit}
                        showQuickJumper={true}
                        showTotal={false}
                        total={sharedEquipmentCount}
                        onChange={handlePaginationChange}
                        onShowSizeChange={handlePaginationChange}
                        hideOnSinglePage={true}
                    />
                    {sharedEquipment?.map((equipement) => (
                        <SharedEquipmentList
                            equipment={equipement}
                            loading={loading}
                        />
                    ))}
                    </>
                ) : (
                    <NoData
                        text={t("SharedEquipmentPage.noData")}
                    />
                )
            )}
            </>
        )}
        </>
    );
};
