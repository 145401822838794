import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { httpGet, httpPost, httpPut } from "../../modUtils/apis/httpConfig";

//retourner la liste de tous les badges
export const get_supports_by_group_paginated = createAsyncThunk(
  "mod_wallet_local/get_supports_by_group_paginated",
  async (values, { rejectWithValue }) => {
    try {
      const response = await httpGet(
        `mod_wallet_local/groups/${values.groupId}/supports/`,
        {
          params: {
            limit: values.limit,
            offset: values.offset,
            date_start: values.dateStart,
            date_end: values.dateEnd,
            entity: values.entity,
            status: values.status ? values.status : null,
            badge_number: values.badgeNumber,
            action_in_progress: values.actionInProgress,
            entity_link: values.entityLink,
            ordering: values.ordering,
            blacklisted: values.blacklisted,
            search: values.search,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue({
        status: error.response.status,
        data: error.response.data,
      });
    }
  }
);

//retourner les actions d'un badge
export const get_actions_by_support_by_group_paginated = createAsyncThunk(
  "mod_wallet_local/get_actions_by_support_by_group_paginated",
  async (values, { rejectWithValue }) => {
    try {
      const response = await httpGet(
        `mod_wallet_local/groups/${values.groupId}/supports/${values.supportId}/actions`,
        {
          params: {
            limit: values.limit,
            offset: values.offset,
            ordering: values.ordering,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue({
        status: error.response.status,
        data: error.response.data,
      });
    }
  }
);

// retourner les transactions d'un badge DEPRECIÉ
export const get_transactions_by_support_by_group_paginated = createAsyncThunk(
  "mod_wallet_local/get_transactions_by_support_by_group_paginated",
  async (values, { rejectWithValue }) => {
    try {
      const response = await httpGet(
        `mod_wallet_local/groups/${values.groupId}/supports/${values.supportId}/transactions`,
        {
          params: {
            limit: values.limit,
            offset: values.offset,
            ordering: values.ordering,
            sale_id: values.saleId,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue({
        status: error.response.status,
        data: error.response.data,
      });
    }
  }
);

// retourner les transactions d'un badge 
export const transactions_by_group_paginated_get = createAsyncThunk(
  "mod_wallet_local/transactions_by_group_paginated_get",
  async (values, { rejectWithValue }) => {
    try {
      const response = await httpGet(
        `mod_wallet_local/groups/${values.groupId}/transactions/`,
        {
          params: {
            sale_id: values.saleId,
            support_id: values.supportid,
            ordering: values.ordering,
            limit: values.limit,
            offset: values.offset
          }
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue({
        status: error.response.status,
        data: error.response.data,
      });
    }
  }
);

//retourner les détails du support
export const get_support_detail_by_group = createAsyncThunk(
  "mod_wallet_local/get_support_detail_by_group",
  async (values, { rejectWithValue }) => {
    try {
      const response = await httpGet(
        `mod_wallet_local/groups/${values.groupId}/supports/${values.supportId}/`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue({
        status: error.response.status,
        data: error.response.data,
      });
    }
  }
);

//retourner les détails d'une action d'un support par identifiant
export const get_action_detail_by_support_by_group = createAsyncThunk(
  "mod_wallet_local/get_action_detail_by_support_by_group",
  async (values, { rejectWithValue }) => {
    try {
      const response = await httpGet(
        `mod_wallet_local/groups/${values.groupId}/supports/${values.supportId}/actions/${values.actionId}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue({
        status: error.response.status,
        data: error.response.data,
      });
    }
  }
);

//modifier les détails d'un badge
export const update_support_detail_by_group = createAsyncThunk(
  "mod_wallet_local/update_support_detail_by_group",
  async (values, { rejectWithValue }) => {
    try {
      const response = await httpPut(
        `mod_wallet_local/groups/${values.groupId}/supports/${values.supportId}/`,
        { status: values.status, entity: values.entity }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue({
        status: error.response.status,
        data: error.response.data,
      });
    }
  }
);

//blacklister ou déblacklister un badge
export const create_blacklist_history_by_support = createAsyncThunk(
  "mod_wallet_local/create_blacklist_history_by_support",
  async (values, { rejectWithValue }) => {
    try {
      const response = await httpPost(
        `mod_wallet_local/groups/${values.groupId}/supports/${values.supportId}/blacklist_history`,
        { blocked: values.blocked, reason: values.reason }
      );
      return { id: values.supportId, blocked: values.blocked };
    } catch (error) {
      return rejectWithValue({
        status: error.response.status,
        data: error.response.data,
      });
    }
  }
);

//modifer les détails d'une action d'un badge
export const update_action_detail_by_support_by_group = createAsyncThunk(
  "mod_wallet_local/update_action_detail_by_support_by_group",
  async (values, { rejectWithValue }) => {
    try {
      const response = await httpPut(
        `mod_wallet_local/groups/${values.groupId}/supports/${values.supportId}/actions/${values.actionId}`,
        { status: values.status }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue({
        status: error.response.status,
        data: error.response.data,
      });
    }
  }
);

//créer une action pour un badge
export const create_actions_by_support_by_group = createAsyncThunk(
  "mod_wallet_local/create_actions_by_support_by_group",
  async (values, { rejectWithValue }) => {
    try {
      const response = await httpPost(
        `mod_wallet_local/groups/${values.groupId}/supports/${values.supportId}/actions`,
        {
          amount: values.amount,
          type: values.type,
          validated_date: values.validatedDate,
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue({
        status: error.response.status,
        data: error.response.data,
      });
    }
  }
);

//modifier les détails d'une transaction d'un badge
export const update_transaction_detail_by_group = createAsyncThunk(
  "mod_wallet_local/update_transaction_detail_by_group",
  async (values, { rejectWithValue }) => {
    try {
      const response = await httpPut(
        `mod_wallet_local/groups/${values.groupId}/supports/${values.supportId}/transactions/${values.transactionId}`,
        { is_suspected_fraud: values.isSuspectedFraud }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue({
        status: error.response.status,
        data: error.response.data,
      });
    }
  }
);

const walletLocalSlice = createSlice({
  name: "walletLocal",
  initialState: {
    badges: [],
    supportActionsById: [],
    supportTransactionsById: [],
    loading: true,
    supportDetails: {},
    supportActionDetails: [],
    supportsCount: 0,
    supportActionsCount: 0,
  },
  reducers: {
    update_support_entity_detail(state, action) {
      const { supportId, updatedEntity } = action.payload;
      // Find the badge and update its details
      state.badges = state.badges?.map((badge) => {
        if (badge.id === supportId) {
          const { entity } = badge;
          if (entity.entity_company) {
            return {
              ...badge,
              entity: {
                ...badge.entity,
                entity_company: {
                  ...badge.entity.entity_company,
                  ...updatedEntity,
                },
              },
            };
          } else if (entity.entity_customer) {
            return {
              ...badge,
              entity: {
                ...badge.entity,
                entity_customer: {
                  ...badge.entity.entity_customer,
                  ...updatedEntity,
                },
              },
            };
          }
        }
        return badge;
      });
    },
  },
  extraReducers: (builder) => {
    builder
      /* get_supports_by_group_paginated */
      .addCase(get_supports_by_group_paginated.pending, (state, action) => {
        state.badges = [];
      })
      .addCase(get_supports_by_group_paginated.fulfilled, (state, action) => {
        state.loading = false;
        state.badges = action.payload.results;
        state.supportsCount = action.payload.count;
      })
      .addCase(get_supports_by_group_paginated.rejected, (state, action) => {
        state.loading = false;
      })

      /* get_actions_by_support_by_group_paginated */
      .addCase(
        get_actions_by_support_by_group_paginated.pending,
        (state, action) => {
          state.supportActionsById = [];
        }
      )
      .addCase(
        get_actions_by_support_by_group_paginated.fulfilled,
        (state, action) => {
          state.loading = false;
          state.supportActionsById = action.payload.results;
          state.supportActionsCount = action.payload.count;
        }
      )
      .addCase(
        get_actions_by_support_by_group_paginated.rejected,
        (state, action) => {
          state.loading = false;
        }
      )

      /* get_transactions_by_support_by_group_paginated */
      .addCase(
        get_transactions_by_support_by_group_paginated.pending,
        (state, action) => {
          state.supportTransactionsById = [];
        }
      )
      .addCase(
        get_transactions_by_support_by_group_paginated.fulfilled,
        (state, action) => {
          state.loading = false;
          state.supportTransactionsById = action.payload.results;
        }
      )
      .addCase(
        get_transactions_by_support_by_group_paginated.rejected,
        (state, action) => {
          state.loading = false;
        }
      )

      /* transactions_by_group_paginated_get */
      .addCase(transactions_by_group_paginated_get.pending, (state, action) => {
        state.supportTransactionsById = [];
      })
      .addCase(transactions_by_group_paginated_get.fulfilled, (state, action) => {
        state.loading = false;
        state.supportTransactionsById = action.payload.results;
      })
      .addCase(transactions_by_group_paginated_get.rejected, (state, action) => {
          state.loading = false;
      })

      /* get_support_detail_by_group */
      .addCase(get_support_detail_by_group.pending, (state, action) => {
        state.supportDetails = {};
      })
      .addCase(get_support_detail_by_group.fulfilled, (state, action) => {
        state.loading = false;
        state.supportDetails = action.payload;
      })
      .addCase(get_support_detail_by_group.rejected, (state, action) => {
        state.loading = false;
      })

      /* get_action_detail_by_support_by_group */
      .addCase(
        get_action_detail_by_support_by_group.pending,
        (state, action) => {
          state.supportActionDetails = [];
        }
      )
      .addCase(
        get_action_detail_by_support_by_group.fulfilled,
        (state, action) => {
          state.loading = false;
          state.supportActionDetails = action.payload;
        }
      )
      .addCase(
        get_action_detail_by_support_by_group.rejected,
        (state, action) => {
          state.loading = false;
        }
      )

      /* update_support_detail_by_group */
      .addCase(update_support_detail_by_group.fulfilled, (state, action) => {
        state.loading = false;
        const updatedBadge = action.payload;
        const updatedBadges = state.badges.map((badge) => {
          if (badge.id === updatedBadge.id) {
            return updatedBadge;
          }
          return badge;
        });
        state.badges = updatedBadges;
      })
      .addCase(update_support_detail_by_group.rejected, (state, action) => {
        state.loading = false;
      })

      /* create_blacklist_history_by_support */
      .addCase(
        create_blacklist_history_by_support.fulfilled,
        (state, action) => {
          state.loading = false;
          const updatedBlacklistBadge = action.payload;
          const updatedBadges = state.badges.map((badge) => {
            if (badge.id === updatedBlacklistBadge.id) {
              return {
                ...badge,
                is_blacklisted: updatedBlacklistBadge.blocked,
              };
            }
            return badge;
          });
          state.badges = updatedBadges;
        }
      )
      .addCase(
        create_blacklist_history_by_support.rejected,
        (state, action) => {
          state.loading = false;
        }
      )

      /* update_action_detail_by_support_by_group */
      .addCase(
        update_action_detail_by_support_by_group.fulfilled,
        (state, action) => {
          state.loading = false;
          const updatedBadgeAction = action.payload;
          const updatedActions = state.supportActionsById?.map(
            (badgeAction) => {
              if (badgeAction.id == updatedBadgeAction.id) {
                return {
                  ...badgeAction,
                  status: updatedBadgeAction.status,
                  status_label: updatedBadgeAction.status_label,
                };
              } else return badgeAction;
            }
          );
          state.supportActionDetails = updatedBadgeAction;
          state.supportActionsById = updatedActions;
        }
      )
      .addCase(
        update_action_detail_by_support_by_group.rejected,
        (state, action) => {
          state.loading = false;
        }
      )

      /* create_actions_by_support_by_group */
      .addCase(
        create_actions_by_support_by_group.fulfilled,
        (state, action) => {
          state.loading = false;
          state.supportActionsById = [
            action.payload,
            ...state.supportActionsById,
          ];
        }
      )
      .addCase(create_actions_by_support_by_group.rejected, (state, action) => {
        state.loading = false;
      })

      /* update_transaction_detail_by_group */
      .addCase(
        update_transaction_detail_by_group.fulfilled,
        (state, action) => {
          state.loading = false;
          const updatedTransaction = action.payload;
          const updatedTransactionsList = state.supportTransactionsById?.map(
            (transaction) => {
              if (transaction.id === updatedTransaction.id) {
                return {
                  ...transaction,
                  is_suspected_fraud: updatedTransaction.is_suspected_fraud,
                };
              }
              return transaction;
            }
          );
          state.supportTransactionsById = updatedTransactionsList;
        }
      )
      .addCase(update_transaction_detail_by_group.rejected, (state, action) => {
        state.loading = false;
      });
  },
});
export const { update_support_entity_detail } = walletLocalSlice.actions;

export default walletLocalSlice.reducer;
