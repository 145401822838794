import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Radio, DatePicker } from "antd";
import { useTranslation } from "react-i18next";
import { message } from "antd";
import dayjs from 'dayjs';
import {
    create_configuration_by_profil,
    update_configuration_detail_by_profil
} from "../slices/configurationSlice";
import { create_constraint_identification } from "../slices/constraintIdentificationSlice";
import { update_profil_configuration_count } from "../slices/profilSlice";
import IdentificationConstraints from "./IdentificationConstraints";
import { ReactComponent as ConfigNextIcon } from "../../modEntity/assets/images/awaitCircle.svg";
import { ReactComponent as ConfigActiveIcon } from "../../modEntity/assets/images/check.svg";
import FormLegend from "../../modUtils/components/FormLegend";
import AlertMessage from "../../modUtils/components/AlertMessage";
import MessageAlert from "../../modUtils/components/componentsLibrary/alertMessage/AlertMessage";
import CKToolTip from "../../modUtils/components/CKToolTip/CKToolTip";
import DismissButton from "../../modUtils/components/buttons/DismissButton";
import { Label, Spinner } from "../../modUtils/components/componentsLibrary/componentsLibrary";
import SubmitButton from "../../modUtils/components/buttons/submitButton/SubmitButton";

export default function ConfigurationDetails({
    selectedProfilId,
    selectedConfigId,
    closeModal,
    getConfigurationDetail
}) {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const isEdit = !!selectedConfigId;
    const [loading, setLoading] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState();
    const [initialFormValues, setInitialFormValues] = useState();
    const { groupId } = useSelector((state) => state.manager);
    const { profilDetail } = useSelector((state) => state.profil.profil);
    const { configurationDetail } = useSelector((state) => state.profil.configuration);
    const { constraintsIdentification } = useSelector((state) => state.profil.constraintIdentification);
    const [statusMessage, setStatusMessage] = useState(null);
    const [errorDate, setErrorDate] = useState(null);
    const defaultDate = dayjs().add(1, 'day').startOf('day').hour(12); // Définir la date de demain à 12h00

    const SupportFormatToDisplay = Object.freeze({
        1: 'eSupportFormat.badgeMifare',
        2: 'eSupportFormat.qrCode'
    });

    // Fonction pour définir les valeurs des champs du formulaire
    const getFormSetFieldsValues = () => {
        if (isEdit && profilDetail && configurationDetail) {
            const identification = constraintsIdentification === "Object Not found"
                ? Object.keys(SupportFormatToDisplay).map(Number)
                : selectedOptions
                    ? selectedOptions
                    : constraintsIdentification?.formats_authorized;

            // Convertir le timestamp en objet dayjs
            const dayjsDate = configurationDetail?.date_activation 
                ? dayjs.unix(configurationDetail.date_activation) // Utilise dayjs.unix pour convertir le timestamp
                : null;

            // Configuration des valeurs du formulaire
            const formValues = {
                name: configurationDetail?.name || "",
                description: configurationDetail?.description || "",
                price: profilDetail?.service_type === 0 ? configurationDetail?.price || "" : "",
                dateActivation: dayjsDate || null, // Utilise dayjs pour la date
                identification: identification
            };

            // Stocker les valeurs initiales
            setInitialFormValues(formValues);
            setSelectedOptions(identification);

            // Réinitialiser les champs du formulaire et définir les valeurs
            form.resetFields();
            form.setFieldsValue(formValues);
        }
    };

    useEffect(() => {
        getFormSetFieldsValues();
    }, [configurationDetail, profilDetail, selectedConfigId, selectedProfilId]);

    // modifier config
    const updateConfigurationDetail = async (values) => {
        try {
            setLoading(true);
            await dispatch(
                update_configuration_detail_by_profil({
                    groupId,
                    profilId: selectedProfilId,
                    configurationId: selectedConfigId,
                    ...values
                })
            ).unwrap();
            setStatusMessage("success");
        } catch (error) {
            setStatusMessage("error");
            message.error({
                content: (
                    <AlertMessage
                        status={error?.status}
                        alertMessage={`Une erreur est survenue lors de la modification des informations de la configuration !`}
                        errorDetail={error?.data?.detail}
                    />
                )
            });
        } finally {
            getConfigurationDetail(selectedProfilId, selectedConfigId);
            setLoading(false);
        }
    };

    // ajouter nouvelle config
    const addNewConfiguration = async (values) => {
        try {
            setErrorDate(null);
            setLoading(true);
            const reponse = await dispatch(
                create_configuration_by_profil({ groupId, profilId: selectedProfilId, ...values })
            ).unwrap();
            if(reponse){
                dispatch(update_profil_configuration_count({profilId: selectedProfilId}))
                closeModal && closeModal(); //fermer le modal d'ajout
            }
        } catch (error) {
            if (error.status === 400) {
                setErrorDate(error.data.detail);
            }else {
                message.error({
                    content: (
                        <AlertMessage
                            status={error?.status}
                            alertMessage={`Une erreur est survenue lors de l'ajout d'une nouvelle configuration !`}
                            errorDetail={error?.data?.detail}
                        />
                    )
                });
            }
        } finally {
            setLoading(false);
        }
    };

    // modifier la liste des contraintes d'identification
    const updateConstraintsIdentification = async (formatsAuthorized) => {
        try {
            setLoading(true);
            await dispatch(
                create_constraint_identification({
                    groupId,
                    configurationId: selectedConfigId,
                    formatsAuthorized: formatsAuthorized
                })
            ).unwrap();
            setStatusMessage("success");
        } catch (error) {
            setStatusMessage("error");
            message.error({
                content: (
                    <AlertMessage
                        status={error?.status}
                        alertMessage={`Une erreur est survenue lors de la récupération des contraintes d'identification !`}
                        errorDetail={error?.data?.detail}
                    />
                )
            });
        } finally {
            setLoading(false);
        }
    };

    const arraysEqualUnordered = (arrayInitial, arrayToCompare) => {
        if (arrayInitial?.length !== arrayToCompare?.length) {
            return false;
        }
        
        // Comparer les tableaux triés
        arrayInitial = [...arrayInitial].sort();
        arrayToCompare = [...arrayToCompare].sort();
        return arrayInitial.every((element, index) => element === arrayToCompare[index]);
    };

    // Fonction pour vérifier les champs modifiés avant de soumettre le formulaire
    const getModifiedFields = (currentValues) => {
        const modifiedFields = {};

        for (const key in currentValues) {
            if (key === "identification") {
                // Ignorer le champ identification
                continue;
            }

            const initialValue = initialFormValues[key];
            const currentValue = currentValues[key];

            if (key === "dateActivation") {
                // Si la date a changé, ajouter la version en timestamp (secondes)
                if (dayjs.isDayjs(initialValue) && dayjs.isDayjs(currentValue)) {
                    if (!initialValue.isSame(currentValue)) {
                        modifiedFields[key] = currentValue ? Math.floor(currentValue.valueOf() / 1000) : null;
                    }
                } else if (initialValue !== currentValue) {
                    modifiedFields[key] = currentValue ? Math.floor(currentValue.valueOf() / 1000) : null;
                }
            } else {
                // Comparaison normale des autres champs
                if (dayjs.isDayjs(initialValue) && dayjs.isDayjs(currentValue)) {
                    if (!initialValue.isSame(currentValue)) {
                        modifiedFields[key] = currentValue;
                    }
                } else if (initialValue !== currentValue) {
                    modifiedFields[key] = currentValue;
                }
            }
        }

        return modifiedFields;
    };

    // fonction appelée quand on envoie le formulaire
    const onFinish = (values) => {
        if (closeModal) {
            // Clone les valeurs actuelles
            const updatedValues = {
                ...values,
                // Convertit dateActivation en timestamp (secondes)
                dateActivation: values.dateActivation
                    ? Math.floor(values.dateActivation.valueOf() / 1000)
                    : null,  // Vérifie si dateActivation est défini
            };
            addNewConfiguration(updatedValues);
        }else {
            const modifiedFields = getModifiedFields(values);

            // Faire une mise à jour uniquement des champs modifiés
            if (Object.keys(modifiedFields).length > 0) {
                
                // Appeler une fonction d'update ici, en passant les champs modifiés
                if (selectedConfigId) {
                    updateConfigurationDetail(modifiedFields);
                }

                // Mettre à jour initialFormValues avec les nouvelles valeurs du formulaire
                setInitialFormValues((prevValues) => ({
                    ...prevValues,
                    ...modifiedFields, // Appliquer les champs modifiés
                }));
            }
            
            if (!arraysEqualUnordered(values.identification, selectedOptions) && values.identification) {
                // Mettre à jour les contraintes d'identification
                updateConstraintsIdentification(selectedOptions);

                // Mettre à jour les boutons activés (selectedOptions) après la modification
                setSelectedOptions((prevOptions) => {
                    const updatedOptions = [...selectedOptions];

                    // Mettre à jour les valeur du formulaire avec les nouvelles options sélectionnées
                    form.setFieldsValue({ identification: updatedOptions });

                    return updatedOptions;  // Mettre à jour l'état avec les nouvelles options
                });
            }
        }
    };

    const handleResetForm = () => {
        getFormSetFieldsValues();
        closeModal && closeModal();
    };

    const renderIconStatus = () => {
        const ConfigExpireIcon = <img className="max-w-[25px] max-h-[25px]" src={process.env.PUBLIC_URL + "/img/expire.png"}/>;
        let content;
        let icon;

        if (configurationDetail?.is_active) {
            content = "Configuration active";
            icon = <ConfigActiveIcon/>;
        }else if (configurationDetail?.is_future) {
            content = "Configuration suivante";
            icon = <ConfigNextIcon/>;
        }else {
            content = "Configuration expirer";
            icon = ConfigExpireIcon;
        }

        return (
            <CKToolTip
                placement="topLeft"
                content={content}
                trigger="click"
            >
                {icon}
            </CKToolTip>
        );
    }

    const handleDateChange = (date) => {
        const dayjsDate = dayjs(date); // Crée un objet dayjs
        form.setFieldsValue({ dateActivation: dayjsDate }); // Met à jour le formulaire avec l'objet dayjs

        // Efface les erreurs du champ quand l'utilisateur change la valeur
        setErrorDate(null);
        form.setFields([
            {
                name: 'dateActivation',
                errors: [] // Supprime l'erreur manuellement quand l'utilisateur modifie la date
            }
        ]);
    };

    useEffect(() => {
        // Vérifier s'il y a une erreur à appliquer au champ
        if (errorDate) {
            form.setFields([
                {
                    name: 'dateActivation',
                    errors: [errorDate] // Si errorDate est défini, définir l'erreur sur le champ
                }
            ]);
        } else {
            form.setFields([
                {
                    name: 'dateActivation',
                    errors: [] // Efface les erreurs si errorDate n'est pas défini
                }
            ]);
        }
    }, [errorDate, form]);

    return (
        <>
        <Form form={form} onFinish={onFinish} layout="vertical">
            <div className="flex flex-row">
                <div className="flex flex-col items-start">
                    {!configurationDetail?.is_future && isEdit ?
                        <div className="text-left">
                            <Label
                                label="Date d'activation"
                            />
                            <p>{dayjs(form.getFieldValue('dateActivation')).format('YYYY-MM-DD HH:mm')}</p>
                        </div>
                    :
                        <Form.Item
                            name="dateActivation"
                            initialValue={defaultDate} // Utilisez la date de demain comme valeur initiale
                            rules={[
                                {
                                    required: true,
                                    message: t("configurationDetails.pleaseSelectActivationDate")
                                },
                                {
                                    // Validation personnalisée avec errorDate
                                    validator: (_, value) => {
                                        if (errorDate) {
                                            return Promise.reject(new Error(errorDate));
                                        }
                                        return Promise.resolve();
                                    }
                                }
                            ]}
                            validateTrigger="onChange" // Déclenche la validation uniquement lorsqu'il y a un changement
                        >  
                            <DatePicker
                                format="YYYY-MM-DD HH:mm" // Format de la date et de l'heure
                                showTime={{ format: 'HH:mm' }} // Affichage de la sélection d'heure
                                onChange={(date) => handleDateChange(date ? dayjs(date) : defaultDate)}
                                value={form.getFieldValue('dateActivation') ? dayjs(form.getFieldValue('dateActivation')) : defaultDate}
                                style={{ width: '100%' }}
                                disabled={!configurationDetail?.is_future && isEdit} 
                            />
                        </Form.Item>
                    }
                </div>

                {!closeModal && 
                    <div className="px-4 flex items-center h-[32px]">
                        {renderIconStatus()}
                    </div>
                }
            </div>

            <div className="grid grid-cols-2 min-[1460px]:grid-cols-3 gap-0 min-[1460px]:gap-2">
                <div className="col-span-2 min-[1460px]:col-span-2">
                    {!configurationDetail?.is_future && isEdit ?
                        <div className="text-left">
                            <Label
                                label={t("configurationDetails.configurationName")}
                            />
                            <p>{configurationDetail?.name}</p>
                        </div>
                    :
                        <Form.Item
                            label={t("configurationDetails.configurationName")}
                            name="name"
                            rules={[{
                                required: true,
                                message: t("configurationDetails.pleaseEnterConfigurationName")
                            }]}
                        >
                            <Input placeholder=""/>
                        </Form.Item>
                    }
                   
                </div>
                
                {profilDetail?.service_type === 0 && (
                    <div className="col-span-2 min-[1460px]:col-span-1">
                        {!configurationDetail?.is_future && isEdit ?
                            <div className="text-left">
                                <Label
                                    label={t("configurationDetails.packagePriceIn")}
                                />
                                <p>{configurationDetail?.price}</p>
                            </div>
                        :
                            <Form.Item
                                label={t("configurationDetails.packagePriceIn")}
                                name="price"
                                rules={[{
                                    required: true, message: t("configurationDetails.pleaseEnterPrice")
                                }]}
                            >
                                <Input placeholder=""/>
                            </Form.Item>
                        }
                    </div>
                )}
            </div>

            <Form.Item
                label={t("configurationDetails.description")}
                name="description"
            >
                <Input.TextArea placeholder={t("configurationDetails.description")}/>
            </Form.Item>

            {!closeModal && 
                <Form.Item
                    name="identification"
                >
                    <Radio.Group value={selectedOptions}>
                        <div className="my-4">
                            <IdentificationConstraints
                                selectedConfigId={selectedConfigId} 
                                setSelectedOptions={setSelectedOptions}
                                selectedOptions={selectedOptions}
                                configurationDetail={configurationDetail}
                            />
                        </div>
                    </Radio.Group>
                </Form.Item>
            }
            
            <div className="flex flex-row justify-center gap-2 mt-2">
                <Form.Item>
                    <DismissButton onClick={() => handleResetForm()}/>
                </Form.Item>

                <Form.Item>
                    <SubmitButton
                        label={t("configurationDetails.save")}
                        className="h-[42px]"
                    />
                </Form.Item>
            </div>
        </Form>
        
        {configurationDetail?.is_future && !isEdit &&
            <FormLegend requiredFormItemLegend={true}/>
        }
        
        <MessageAlert
            statusMessage={statusMessage}
            defaultMessage={statusMessage
                ? t("configurationDetails.operationSuccessfully")
                : t("configurationDetails.problemOccurred")
            }
        />
        {loading && <Spinner/>}
        </>
    );
};
