import React ,{useState}from "react";
import BuildingPage from 'ck_commun/src/app/modUtils/components/errorPage/BuildingPage'

export default function Voucher() {

  return (
  <>
    <BuildingPage />
  </>
    
  );
}
