import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Table,
  Select,
  Spinner,
  Pagination,
  Input,
} from "../../../modUtils/components/componentsLibrary/componentsLibrary";
import { get_sales_by_group_paginated } from "../../slices/saleSlice";
import { dateFormat } from "../../../modUtils/functions/dateFormat";
import SaleActionsMenu from "../../components/saleActionsMenu/SaleActionsMenu";
import ExportModalSalePage from "../../components/exportModalSalePage/ExportModalSalePage";
import SubmitButton from "../../../modUtils/components/buttons/submitButton/SubmitButton";
import FilterByEquipmentType from "../../../modUtils/components/filters/FilterByEquipmentType";
import FilterByDate from "../../../modUtils/components/filters/FilterByDate";
import FilterBySearchSite from "ck_commun/src/app/modManager/components/filterBySearchSite/FilterBySearchSite";
import FilterByPaymentCategory from "../../../modUtils/components/filters/FilterByPaymentCategory";
import { hasPermission } from "../../../modUtils/functions/handlePermissions";
import { eCdbsPaymentType } from "../../../../commun_global/enums";
import moment from "moment";
import SaleDetailsModal from "../../components/saleDetailsModal/SaleDetailsModal";
import { amountFormatWithCurrencySymbol } from "../../../modUtils/functions/numberFormat";
import { message } from "antd";
import AlertMessage from "../../../modUtils/components/AlertMessage";
import i18next from "i18next";


export default function SalesPage() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const filterBySearchOptions = [
    { label: t("filterBySearchOptions.creditCard"), value: "creditCard" },
  ];
  
  const optionsPaymentGroupType = [
    { label: t("optionsPaymentGroupType.real"), value: "real" },
    { label: t("optionsPaymentGroupType.indirect"), value: "indirect" },
    { label: t("optionsPaymentGroupType.all"), value: null },
  ];  
  const formatDate = "DD/MM/YYYY HH:mm";
  const { sales, countSales } = useSelector((state) => state.sale);
  const { groupId, selectedGroup } = useSelector((state) => state.manager);
  const groupPermissions = selectedGroup.permissions;
  const verifyPermissionShowSale = hasPermission(
    "mod_sale:show_sale",
    groupPermissions
  );
  const verifyPermissionDataExport = hasPermission(
    "mod_data:data_export",
    groupPermissions
  );
  const [loading, setLoading] = useState(false);
  const [saleDetailsModalVisible, setSaleDetailsModalVisible] = useState(false);
  const [saleIdSelected, setSaleIdSelected] = useState();
  const [equipmentTypeList, setEquipmentTypeList] = useState([]);
  const [siteList, setSiteList] = useState([]);
  const siteCount = selectedGroup?.site_count;
  let [limit, setLimit] = useState(20);
  let [offset, setOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [paymentGroup, setPaymentGroup] = useState(
    optionsPaymentGroupType[2].value || null
  );
  
  const ordering = "-date";
  const [startDate, setStartDate] = useState(
    moment().subtract(6, "days").startOf("day").format(formatDate)
  );
  const [endDate, setEndDate] = useState(
    moment().endOf("day").format(formatDate)
  );
  const [selectedOption, setSelectedOption] = useState(
    filterBySearchOptions[0].value
  );
  const [creditCard, setCreditCard] = useState(null);

  const [selectForm, setSelectForm] = useState({
    dateStart: startDate,
    dateEnd: endDate,
  });

  //getSales : fonction qui appelle l'api get_sales_by_group_paginated pour récupérer la liste des ventes
  const getSales = async (values) => {
    let searchInputValue = values?.searchInputValue;
    try {
      setLoading(true);
      // console.log("limit", limit, "offset", offset);
      await dispatch(
        get_sales_by_group_paginated({
          groupId,
          limit: values.limit,
          offset: values.offset,
          payment: paymentGroup,
          ordering: ordering,
          equipmentType: equipmentTypeList?.join(","),
          sites: siteList?.join(","),
          dateStart: startDate ? moment(startDate, formatDate).unix() : null,
          dateEnd: endDate ? moment(endDate, formatDate).unix() : null,
          creditCard:
            selectedOption == "creditCard" &&
            searchInputValue?.trim().length > 0
              ? searchInputValue.trim()
              : null,
          // //TODO a modifier idBadge et nomClient apres màj filtres dans l'api
          // idBadge:
          //   selectedOption == "idBadge" && searchInputValue?.trim().length > 0
          //     ? searchInputValue.trim()
          //     : null,
          // nomClient:
          //   selectedOption == "nomClient" && searchInputValue?.trim().length > 0
          //     ? searchInputValue.trim()
          //     : null,
        })
      ).unwrap();
    } catch (error) {
      message.error({
        content: (
          <AlertMessage
            status={error?.status}
            alertMessage={t("saleDetailsModal.errorMessage")}
            errorDetail={error?.data?.detail}
          />
        ),
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setEquipmentTypeList(null);
    setSiteList(null);
    setPaymentGroup(optionsPaymentGroupType[2].value || null);
    if (startDate && endDate) {
      groupId && getSales({ offset: 0, limit });
    }
  }, [groupId, i18next.language]);

  // Fonction de gestion de changement de pagination
  const handlePaginationChange = (page, pageSize) => {
    // Mettez à jour les valeurs de limit et offset en fonction de la page et de la taille de page
    const newOffset = (page - 1) * pageSize;
    setOffset(newOffset);
    if (newOffset < 0) {
      setCurrentPage(1);
    } else {
      setCurrentPage(page);
    }
    setLimit(pageSize);
    // Effectuez une requête à l'API avec les nouvelles valeurs de limit et offset pour obtenir les données correspondantes
    getSales({ offset: newOffset, limit: pageSize });
  };
  //filtre par site
  const handleSiteValueChange = (selectedList) => {
    setSiteList(selectedList?.length > 0 ? selectedList : null);
    setSelectForm({ ...selectForm, siteListName: selectedList });
  };
  //filtre par type d'équipement
  const handleEquipmentTypeChange = (selectedList) => {
    setEquipmentTypeList(selectedList?.length > 0 ? selectedList : null);
    setSelectForm({ ...selectForm, equipmentType: selectedList });
  };

  function formatedDate(selectedDate) {
    let formatedDate;

    if (typeof selectedDate === "object") {
      const date = selectedDate;
      formatedDate = moment(date, formatDate, true).format(formatDate);
    } else if (typeof selectedDate === "string" && selectedDate.length > 0) {
      formatedDate = moment(selectedDate, formatDate, true).format(formatDate);
    }

    return formatedDate;
  }

  //filtre par date
  const handleDateChange = (selectedDate) => {
    const formatedStartDate = formatedDate(selectedDate[0]);
    const formatedEndDate = formatedDate(selectedDate[1]);

    setStartDate(formatedStartDate);
    setEndDate(formatedEndDate);

    setSelectForm({
      ...selectForm,
      dateStart: formatedStartDate,
      dateEnd: formatedEndDate,
    });
  };

  //filtre recherche carte de crédit / id badge / nom client
  const handleSearchInputPressEnter = (selectedOption, searchInputValue) => {
    if (searchInputValue.length > 3 && selectedOption == "creditCard") {
      setCreditCard(searchInputValue);
    }
    setSelectedOption(selectedOption[0]);
    getSales({ offset: 0, limit, searchInputValue });
  };
  //TODO
  const handleRowClick = (rowKey, event) => {
    setSaleIdSelected(rowKey);
    setSaleDetailsModalVisible(true);
  };
  //colonnes du tableau
  const columns = [
    {
      title: t("table.tabColId"),
      dataIndex: "id",
      key: "id",
      width: "5%",
      bold: true,
      ellipsis: true,
    },
    {
      title: t("table.tabColDate"),
      dataIndex: "date_probable",
      key: "date_probable",
      width: "10%",
      ellipsis: true,
      render: (text, record) => dateFormat(record.date_probable),
    },
    {
      title: t("table.tabColSite"),
      dataIndex: "site",
      key: "site",
      width: "15%",
      align: "left",
      bold: true,
    },
    {
      title: t("table.tabColEquipment"),
      dataIndex: "equipment",
      key: "equipment",
      width: "15%",
      align: "left",
      bold: true,
    },
    {
      title: t("table.tabColPayment"),
      dataIndex: "payments",
      key: "payments",
      align: "left",
      bold: true,
      //width: 182,
      render: (text, record) =>
        record.payments
          ?.filter(
            (payment, index, arr) =>
              arr.findIndex((p) => p.type_payment === payment.type_payment) ===
              index
          )
          .map((payment) => t(eCdbsPaymentType[payment.type_payment]))
          .join(", "), //filter les payments de même type pour n'afficher le type qu'une seule fois
    },
    {
      title: t("table.tabColProduct"),
      dataIndex: "retails",
      key: "retails",
      align: "left",
      bold: true,
      //width: 205,
      render: (text, record) =>
        record.retails
          ?.map(
            (retail) =>
              retail.label_local +
              // " (" +
              // retail.local_id +
              // ") " +
              (retail.quantity == 1 ? "" : " x" + retail.quantity)
          )
          .join(", "),
    },
    {
      title: t("table.tabColAmount"),
      dataIndex: "price_total_selected",
      key: "price_total_selected",
      width: "8%",
      className: "table-column-accent",
      headerClassName: "table-column-accent",
      bold: true,
      render: (text, record) => (
        <span>{amountFormatWithCurrencySymbol(text)}</span>
      ),
    },
    {
      title: "",
      dataIndex: "",
      key: "actions",
      width: "5%",
      render: (text, record) => (
        <div className="sale-actions-menu">
          <SaleActionsMenu sale={record} />
        </div>
      ),
    },
  ];

  const rowClassName = (record) => {
    if (record.has_error_balance) {
      return "table-row-highlight"; // Classe CSS pour les lignes où la vente présente une erreur
    }
    if (record.is_test) {
      return "table-row-test"; // Classe CSS pour les lignes où la vente est faite pour tester
    }
    return "";
  };

  const handleChangePaiement = (value) => {
    setPaymentGroup(value);
    setSelectForm({
      ...selectForm,
      payment: value,
    });
  };

  return (
    <>
      <div className="filters gap-2 flex flex-row items-end flex-wrap md:max-w-[calc(100vw-500px)]">
        <FilterByEquipmentType onValueChange={handleEquipmentTypeChange} />
        {siteCount > 1 && (
          <FilterBySearchSite onValueChange={handleSiteValueChange} />
        )}
        <FilterByDate
          onValueChange={handleDateChange}
          defaultPeriod="lastSevenDays"
        />
        <Select
          defaultValue={[optionsPaymentGroupType[2].value]}
          options={optionsPaymentGroupType}
          selectLabel={"Paiement"}
          onDeselect={() =>
            setPaymentGroup(optionsPaymentGroupType[2].value || null)
          }
          onSelect={(value) => handleChangePaiement(value)}
        />
        <SubmitButton onClick={() => getSales({ offset: 0, limit })} />
      </div>

      <Select
        fieldNames={{ label: "label", value: "value", options: "options" }}
        defaultActiveFirstOption={true}
        options={filterBySearchOptions}
        onSelect={(value) => setSelectedOption(value)}
        selectLabel={t("filterBySearchOptions.selectLabel")}
        searchInput
        onPressEnter={handleSearchInputPressEnter}
      />

      {loading ? (
        <Spinner />
      ) : (
        <>
          <div style={{ marginTop: 20 }}>
            <div className="flex sm:flex-row-reverse flex-col-reverse justify-between ">
              <div className="data-button-container">
                {/* <FiscButton /> */}
                {verifyPermissionShowSale && verifyPermissionDataExport && (
                  <ExportModalSalePage selectForm={selectForm} />
                )}
              </div>

              {sales.length > 0 && (
                <div>
                  <div className="flex flex-row sm:justify-between justify-center">
                    <Pagination
                      defaultCurrent={currentPage}
                      defaultPageSize={limit}
                      showQuickJumper={true}
                      showTotal={false}
                      total={countSales}
                      onChange={handlePaginationChange}
                      onShowSizeChange={handlePaginationChange}
                      hideOnSinglePage={true}
                    />
                  </div>
                  <div className="table-pre-text m-2">
                    {countSales} {t('saleDetailsModal.saleTitle')}
                  </div>
                </div>
              )}
            </div>
            <Table
              tableClassName={"min-w-[970px] "}
              columns={columns}
              dataSource={sales}
              rowClassName={rowClassName}
              // onRowClick={(rowKey, event)=>handleRowClick(rowKey, event)}
            />
          </div>
        </>
      )}
      {saleDetailsModalVisible && (
        <SaleDetailsModal
          saleId={saleIdSelected}
          closeModal={() => setSaleDetailsModalVisible(false)}
        />
      )}
    </>
  );
}
