import React from 'react';
import Option from './Option';
import OptionsList from './OptionsList';
import { useTranslation } from "react-i18next";

export default function Options({ options, id, onClick, placement, optionAll }) {
    const { t } = useTranslation();
    return (
        <OptionsList
            placement={placement}
        >
            {options.length === 1 && options[0]['name'] === "no data" ? (
                <Option
                    className={`cursor-not-allowed`}
                >
                    No data
                </Option>
            ) : (
                <>
                {optionAll &&
                    <Option
                        onClick={() => onClick({id: null, name: "Tous"})}
                        className={`cursor-pointer`}
                    >
                        Tous
                    </Option>
                }
                
                {options?.map((option) =>
                    <Option
                        onClick={() => onClick(option)}
                        className={`cursor-pointer`}
                    >
                        {t(option.name)}
                    </Option>
                )}
                </>
            )}
            
            <input id={id}
                className="hidden"
            />
        </OptionsList>
    );
};
