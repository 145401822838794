import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ConfirmationModal from "../../modUtils/components/confirmationModal/ConfirmationModal";
import { update_support_detail_by_group } from "../slices/walletLocalSlice";
import { message } from "antd";
import AlertMessage from "../../modUtils/components/AlertMessage";
import { useTranslation } from "react-i18next";

const SupportUnlinkFromCustomer = ({
  supportId,
  entity,
  badgeNumber,
  closeModal,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { groupId, loadingGroup } = useSelector((state) => state.manager);
  const [confirmModalVisible, setConfirmModalVisible] = useState(false); // État pour le modal de confirmation
  const [confirmLoading, setConfirmLoading] = useState(false);
  const openModal = () => {
    closeModal();
    setConfirmModalVisible(true);
  };
  const unlinkCustomer = async () => {
    setConfirmLoading(true);
    try {
      await dispatch(
        update_support_detail_by_group({
          groupId,
          supportId,
          entity: null, // Pour déliér un client d'un badge mettre entity à null.
        })
      ).unwrap();
    } catch (error) {
      message.error({
        content: (
          <AlertMessage
            status={error?.status}
            alertMessage={t("supportUnlinkFromCustomer.error")}
            errorDetail={error?.data?.detail}
          />
        ),
      });
    } finally {
      setConfirmLoading(false);
      setConfirmModalVisible(false);
    }
  };

  return (
    <>
      <p onClick={openModal}>{t("supportUnlinkFromCustomer.title")}</p>
      <ConfirmationModal
        onOk={unlinkCustomer}
        onCancel={() => setConfirmModalVisible(false)}
        visible={confirmModalVisible}
        confirmLoading={confirmLoading}
        content={
          <div>
            {t("supportUnlinkFromCustomer.content")}
            <b> N°{badgeNumber}</b> {t("supportUnlinkFromCustomer.client")}{" "}
            <b> {entity?.name}</b>?
          </div>
        }
      />
    </>
  );
};
export default SupportUnlinkFromCustomer;
