import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { message } from "antd";
import ProfilCard from "./ProfilCard";
import AddProfilModal from "./AddProfilModal";
import { get_profil_detail } from "../slices/profilSlice";
import { ReactComponent as EditIcon } from "../../modEntity/assets/images/pen.svg";
import AlertMessage from "../../modUtils/components/AlertMessage";

export default function ProfilDetails({ selectedProfilId }) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { groupId } = useSelector((state) => state.manager);
    const { profilDetail } = useSelector((state) => state.profil.profil);
    const [isModalUpdateProfilVisible, setIsModalUpdateProfilVisible] = useState();

    const getProfilDetail = async () => {
        try {
            await dispatch(get_profil_detail({ groupId, profilId: selectedProfilId })).unwrap();
        } catch (error) {
            message.error({
                content: (
                    <AlertMessage
                        status={error?.status}
                        alertMessage={`Une erreur est survenue lors de la récupération des détails de ce profil !`}
                        errorDetail={error?.data?.detail}
                    />
                )
            });
        }
    };

    useEffect(() => {
        if (selectedProfilId && groupId && profilDetail.id !== selectedProfilId) {
            getProfilDetail();
        }
    }, [groupId, selectedProfilId]);

    return (
        <>
        <div className="grid grid-row-1 grid-cols-3 gap-4 mb-4">
            <div className="col-span-3 xl:col-span-1">
                <ProfilCard
                    cardTitle={t("profilDetails.profileType")}
                    cardInfo={profilDetail?.service_type_label}
                />
            </div>

            <div className="col-span-3 xl:col-span-1">
                <ProfilCard
                    cardTitle={t("profilDetails.profileTitle")}
                    cardInfo={profilDetail?.name}
                />
            </div>

            <div className="col-span-3 xl:col-span-1">
                <ProfilCard
                    cardTitle={t("profilDetails.profile")}
                    cardInfo={
                        profilDetail?.is_public !== undefined && profilDetail?.is_public !== null
                            ? profilDetail.is_public
                                ? t("profilDetails.public")
                                : t("profilDetails.private")
                            : ""
                    }
                    cornerIcon={<EditIcon/>}
                    onClickCornerIcon={() => setIsModalUpdateProfilVisible(true)}
                />
            </div>
        </div>

        {isModalUpdateProfilVisible && (
            <AddProfilModal
                selectedProfilId={selectedProfilId}
                isModalVisible={isModalUpdateProfilVisible}
                setIsModalVisible={setIsModalUpdateProfilVisible}
            />
        )}
        </>
    );
};
