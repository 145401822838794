import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { message } from "antd";
import Tree from "rc-tree";
import "rc-tree/assets/index.css";
import ProfilNode from "./profilNode/ProfilNode";
import ConfigurationNode from "./configurationNode/ConfigurationNode";
import { get_configuration_by_profil_paginated, reset_configurations } from "../slices/configurationSlice";
import AlertMessage from "../../modUtils/components/AlertMessage";

export default function ProfilList({
    nameFilterValue,
    saveSelectedProfilCallback,
    saveSelectedConfigurationCallback,
    setIsOneNodeExpanded,
    getProfils,
    showExpiredconfigue
}) {
    const dispatch = useDispatch();
    const { groupId } = useSelector((state) => state.manager);
    const { profils } = useSelector((state) => state.profil.profil);
    const { configurations } = useSelector((state) => state.profil.configuration);
    const [selectedProfilId, setSelectedProfilId] = useState([]);
    const [expandedProfil, setExpandedProfil] = useState([]);
    const [selectedConfig, setSelectedConfig] = useState();

    const handleClickConfigurationNode = (configId) => {
        setSelectedConfig(configId);
        saveSelectedConfigurationCallback(configId);
    };

    useEffect(() => {
        groupId && getProfils();
    }, [groupId, nameFilterValue]);

    useEffect(() => {
        setExpandedProfil([]);
    }, [groupId]);

    // retourne les noeuds Profil
    const renderProfilNodes = (profils) => {
        return profils?.map((profil) => {
            // vérifier si le profil est le profil sélectionné
            const isProfilSelected = selectedProfilId == profil?.id.toString();

            return (
                <Tree.TreeNode
                    key={profil.id}
                    title={
                        <ProfilNode
                            expandedProfilId={expandedProfil[0]}
                            profilData={profil}
                            onClick={onSelect}
                            saveSelectedProfilCallback={saveSelectedProfilCallback}
                        />
                    }
                >
                    {isProfilSelected && configurations && configurations?.map((config) => {
                        if (showExpiredconfigue || config?.is_active || config?.is_future) {
                            return (
                                <Tree.TreeNode
                                    key={`${profil.id}-${config.id}`}
                                    title={
                                        <ConfigurationNode
                                            configurationData={config}
                                            saveSelectedConfigurationCallback={handleClickConfigurationNode}
                                            selectedConfig={selectedConfig}
                                            profilId={profil.id}
                                        />
                                    }
                                />
                            );
                        }
                    })}
                </Tree.TreeNode>
            );
        });
    };

    // récupère la liste des configurations d'un profil séléctionné et les retourne en noeud ConfigurationNode
    const renderConfigurationNode = async (profilId) => {
        try {
            let response = await dispatch(
                get_configuration_by_profil_paginated({
                    groupId,
                    profilId,
                    limit: 100,
                    offset: 0
                })
            ).unwrap();

            if (response?.results) {
                if (response?.results?.length > 0) {
                    handleClickConfigurationNode(response?.results[0].id);
                }
            }
        } catch (error) {
            message.error({
                content: (
                    <AlertMessage
                        status={error?.status}
                        alertMessage={`Une erreur est survenue lors de la récupération des configurations de ce profil !`}
                        errorDetail={error?.data?.detail}
                    />
                )
            });
        }
    };

    // to handle click on profil node
    const onSelect = (selectedKey) => {
        setSelectedProfilId([selectedKey]);

        // Si la clé sélectionnée est déjà dans les clés étendues, la retirer
        if (selectedKey == expandedProfil[0]) {
            setExpandedProfil([]);
            setIsOneNodeExpanded(false);
        } else {
            // Sinon, l'ajouter aux clés étendues
            setExpandedProfil([selectedKey.toString()]);
            // récupérer les configurations du profil sélectionné étendu
            const selectedProfil = profils.find(profil => profil.id === selectedKey);
            if (selectedProfil) {
                // je vérifie si profil.configurations_count = 0 pour éviter d'appeler l'api qui récupère la liste des config
                if (selectedProfil.configurations_count !== 0) {
                    renderConfigurationNode(selectedKey);
                } else {
                    saveSelectedConfigurationCallback();
                    dispatch(reset_configurations());
                }
            }
            setIsOneNodeExpanded(true);
        }
    };

    return (
        <Tree
            showLine={true}
            showIcon={false}
            switcherIcon={false}
            expandedKeys={expandedProfil}
            selectedKeys={selectedProfilId}
            draggable={false}
        >
            {renderProfilNodes(profils)}
        </Tree>
    );
};
