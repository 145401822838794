import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import './Tabs.scss'

export default function Tabs({
  children,
  defaultActiveKey,
  headerMatchesBodyWidth,
  style = {},
  openTab,
  tabHeight,
  onChange,
  classNameTabContent=""
}) {
  const [activeTab, setActiveTab] = useState(defaultActiveKey || openTab)
  const tabHeaderElementWidth = 100 / children.length + '%' //calculer la largeur d'un élément du header en pourcentage = 100% / nombre des élements du header

  useEffect(() => {
    setActiveTab(defaultActiveKey || openTab || children[0]?.props?.tabKey)
  }, [openTab, children[0]?.props?.tabKey])

  const handleClick = (e, newActiveKey) => {
    e.preventDefault()
    setActiveTab(newActiveKey)
    onChange && onChange(newActiveKey)
  }

  return (
    <div className="tabs" style={{ ...style.tabs }}>
      <ul className="tab-list overflow-auto" style={{ ...style.tabList }}>
        {children?.map((tab) => {
          const { label, tabKey, disabled } = tab?.props
          return (
            <li
              key={tabKey}
              {...(headerMatchesBodyWidth && {
                style: { width: tabHeaderElementWidth },
              })}
            >
              {/* si headerMatchesBodyWidth la largeur 100% est divisée sur les éléments du header*/}
              <a
                href="#"
                className={
                  tabKey === activeTab
                    ? 'tab-active'
                    : disabled
                    ? 'tab-disabled'
                    : ''
                }
                style={{ ...style.tabActive, ...style.tabDisabled }}
                onClick={(e) => !disabled && handleClick(e, tabKey)}
              >
                {label}
              </a>
            </li>
          )
        })}
      </ul>
      <div
        className={`tab-content ${classNameTabContent}`}
        style={{ ...style.tabContent, height: tabHeight, maxHeight: tabHeight }}
      >
        {children?.map((tab) => {
          if (tab?.props?.tabKey !== activeTab) return null
          return tab?.props?.children
        })}
      </div>
    </div>
  )
}

export const TabPane = ({ label, tabKey, children, disabled }) => {
  return (
    <div
      role="tabpanel"
      aria-labelledby={tabKey}
      hidden={tabKey !== activeTab}
      className="tab-pane"
      style={{ ...style.tabPane }}
    >
      {children}
    </div>
  )
}

Tabs.propTypes = {
  defaultActiveKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  headerMatchesBodyWidth: PropTypes.bool,
}

TabPane.propTypes = {
  label: PropTypes.string.isRequired,
  tabKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  disabled: PropTypes.bool,
}

TabPane.defaultProps = {
  disabled: false,
}
