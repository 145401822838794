import React from 'react';


// Composant Button generique 
export default function Button({onClick, color, size="md", className={}, ...props}) {

    const sizeVariant = {
        sm: "px-[15px] py-1 rounded-[10px] text-sm ",
        md: "px-4 py-2 rounded-md text-base",
        lg: "px-6 py-3 rounded-lg text-lg",
        xl: "px-8 py-4 rounded-lg text-xl",
        xxl: "px-10 py-5 rounded-lg text-2xl"
    };

    const colorVariants = {
        primary: 'bg-primary-color-600 hover:bg-primary-color border-primary-color-600 hover:border-primary-color text-white cursor-pointer',
        outline: 'bg-white hover:bg-primary-color border-primary-color-600 hover:border-primary-color text-primary-color hover:text-white cursor-pointer',
        secondary: 'bg-secondary-color-600 hover:bg-secondary-color border-secondary-color-600 hover:border-secondary-color text-white cursor-pointer',
        thirty: 'bg-thirty-color-600 hover:bg-thirty-color border-thirty-color-600 hover:border-thirty-color text-white cursor-pointer',
        success: 'bg-success-color-600 hover:bg-success-color border-success-color-600 hover:border-success-color text-white cursor-pointer',
        error: 'bg-error-color-600 hover:bg-error-color border-error-color-600 hover:border-error-color text-white cursor-pointer',
        neutral: 'bg-neutral-color-600 hover:bg-neutral-color border-neutral-color-600 hover:border-neutral-color text-white cursor-pointer',
        dark: 'bg-primary-color-900 hover:bg-primary-color-800 border-primary-color-900 hover:border-primary-color-800 text-white cursor-pointer'
    };

    return (
        <button
            className={`border border-solid cursor-pointer
                ${colorVariants[color]}
                ${sizeVariant[size]}
                ${className}`}
            onClick={onClick}
            type='button'
        >
            {props.children}
        </button>
    );
};
