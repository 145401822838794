import React from "react";
import Widget from "ck_commun/src/app/modUtils/components/widget/Widget";
import { useSelector } from "react-redux";
import { hasPermission } from "ck_commun/src/app/modUtils/functions/handlePermissions";
import PermissionFadeContainer from "../../../modUtils/components/permissionFadeContainer/PermissionFadeContainer";
import "./WidgetIncident.scss";
import { useTranslation } from "react-i18next";

export default function WidgetIncident({loading}) {
    const { countEquipmentStatus } = useSelector((state) => state.equipment);
    const { selectedGroup } = useSelector((state) => state.manager);
    const equimentsCount = selectedGroup?.equipment_count
    const permissions = selectedGroup?.permissions;
    const verifyPermission = hasPermission('mod_equipment:show_device', permissions);
    const groupPermissions = selectedGroup?.plan?.permissions;
    const verifyGroupPermission = hasPermission('mod_equipment:show_device', groupPermissions);
    const { t } = useTranslation();

  let content = (
    <div className="widget-equipment-statistic">
  <table>
    <tbody>
      <tr>
        <td>
          <img
            style={{ marginRight: "14px", width: 25 }}
            src={process.env.PUBLIC_URL + "/img/equipmentStatusIcons/offlineStatus.png"}
            alt="img"
          />
        </td>
        <td>{t("widgetIncident.offlineEquipment")}</td>
        <td style={{ whiteSpace : "nowrap" }}>{`${countEquipmentStatus.disconnectedCount} / ${equimentsCount}`}</td>
      </tr>
      <tr>
        <td>
          <img
            style={{ marginLeft: "1px", marginRight: "15px", width: 20 }}
            src={process.env.PUBLIC_URL + "/img/equipmentStatusIcons/errorConnected.svg"}
            alt="img"
          />
        </td>
        <td>{t("widgetIncident.errorEquipment")}</td>
        <td>{countEquipmentStatus.errorCount}</td>
      </tr>
      <tr>
        <td>
          <img
            style={{ marginRight: "14px", width: 20 }}
            src={process.env.PUBLIC_URL + "/img/equipmentStatusIcons/offlineConnected.svg"}
            alt="img"
          />
        </td>
        <td>{t("widgetIncident.OutServiceEquipment")}</td>
        <td>{countEquipmentStatus.outCount}</td>
      </tr>
    </tbody>
  </table>
</div>

  );

    return !loading && verifyPermission && (
        <PermissionFadeContainer
            permission={verifyGroupPermission}
        >
            <Widget title={t("widgetIncident.title")} content={content} />
        </PermissionFadeContainer>
    );
};