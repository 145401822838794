import React, { useState, useEffect } from "react";
import { message } from "antd";
import { useSelector } from "react-redux";
import AlertMessage from "../../AlertMessage";
import { useTranslation } from "react-i18next";
import { Label, Spinner, Table } from "../componentsLibrary";
import useScrollHandler from "../../../hooks/useScrollHandler";

export default function TablePaginagtedScroll({ label, state=false, fetch, effect, columns, onRowClick, rowClassName }) {
    const limit = 30;
    const defaultOffset = 0;
    const { t } = useTranslation();
    const [offset, setOffset] = useState(0);
    const [loading, setLoading] = useState(false);
    const [dataSource, setDataSource] = useState([]);
    const { groupId } = useSelector((state) => state.manager);
    const [hasMoreDataToLoad, setHasMoreDataToLoad] = useState(true); //pour savoir s'il y'a encore de données à charger

    const onScrollEnd = () => {
        if (!loading && hasMoreDataToLoad) {
            fetchData(offset, limit);
        }
    };
    const { tableRef, handleScroll } = useScrollHandler(onScrollEnd);

    const fetchData = async (offset, limit, Load=false) => {
        if (!Load) {
            if (!hasMoreDataToLoad) return; //eviter la redondance des appels
        }

        if (offset === defaultOffset) {
            setDataSource([]);
        }
    
        try {
            setLoading(true);
            let response;
            if(!state) {
                response = await fetch(limit, offset);

                if (response.next === null) {
                    setHasMoreDataToLoad(false);
                }
    
                if (response?.results?.length > 0 || response.entities?.length > 0) {
                    const data = response.entities ? [...response.entities] : [...response.results];
                    setDataSource((prevData) => [...prevData, ...data]);
                    setOffset(offset + limit);
                }
            }else {
                response = state;
                const data = response.entities ? [...response.entities] : [...response.results];
                setDataSource((prevData) => [...prevData, ...data]);
            }
        } catch (error) {
            message.error({
                content: (
                    <AlertMessage
                        status={error?.status}
                        alertMessage={"Une erreur est survenue."}
                        errorDetail={error?.data?.detail}
                    />
                )
            });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        setDataSource([]);
        setOffset(0);
        setHasMoreDataToLoad(true);

        if (groupId) {
            fetchData(defaultOffset, limit, true);
        }
    }, [groupId, effect]);

    return (
        <div className="flex flex-col justify-center items-center w-full mx-auto">
            {label &&
                <Label
                    label={t(label)}
                    className="my-8 text-lg font-black"
                />
            }
            {loading ? <Spinner/> :
                <div className="w-full" ref={tableRef}>
                    <Table
                        columns={columns}
                        dataSource={dataSource}
                        pagination={false}
                        scroll={{ y: 470 }}
                        onScroll={handleScroll}
                        onRowClick={onRowClick}
                        rowClassName={rowClassName}
                    />
                </div>
            }
    </div>
    );
};
