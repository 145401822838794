import React, { useEffect, useState, useTransition } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  Table,
  Select,
} from "../../modUtils/components/componentsLibrary/componentsLibrary";
import { amountFormat } from "../../modUtils/functions/numberFormat";
import { getDateSubtractedFromDateSource } from "../../modUtils/functions/dateFormat";
import { get_dashboard_top_sites_growth_by_group } from "../slices/cumulativeSlice";
import { Spin, message } from "antd";
import AlertMessage from "../../modUtils/components/AlertMessage";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

const TopSitesGrowthByGroupTable = ({ numberOfSites, duree, siteList }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { groupId } = useSelector((state) => state.manager);
  const [loading, setLoading] = useState(false);
  const todayDate = moment().unix();
  const [startDateTopGrowth, setStartDateTopGrowth] = useState(
    getDateSubtractedFromDateSource(todayDate, duree[0].value)
  );
  const endDateTopGrowth = todayDate;
  const { dashboardTopSitesGrowthByGroup } = useSelector(
    (state) => state.cumulative
  );
  //fonction d'appel à get_dashboard_top_sites_by_group
  const getDashboardTopSitesGrowth = async () => {
    setLoading(true);
    try {
      await dispatch(
        get_dashboard_top_sites_growth_by_group({
          groupId,
          startDate: startDateTopGrowth,
          endDate: endDateTopGrowth,
          sites: siteList?.join(","),
        })
      ).unwrap();
    } catch (error) {
      message.error({
        content: (
          <AlertMessage
            status={error?.status}
            alertMessage={t("TopSitesGrowthByGroupTable.error")}
            errorDetail={error?.data?.detail}
          />
        ),
      });
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (groupId && startDateTopGrowth && endDateTopGrowth) {
      getDashboardTopSitesGrowth();
    }
  }, [groupId, i18next.language]);

  const handleChangeTopGrowth = (value) => {
    setStartDateTopGrowth(getDateSubtractedFromDateSource(todayDate, value));
    getDashboardTopSitesGrowth();
  };

  const topGrowthColumns = [
    {
      title: t("topSitesCol.positionCol"),
      dataIndex: "position",
      key: "position",
      width: "5%",
      align: "right",
    },
    {
      title: t("topSitesCol.siteNameCol"),
      dataIndex: "site_name",
      key: "site_name",
      width: "50%",
      align: "left",
    },
    {
      title: t("topSitesCol.totalPer"),
      dataIndex: "percentage",
      key: "percentage",
      width: "15%",
      align: "right",
      render: (text) => amountFormat(text) + "%",
    },
  ];
  return (
    <>
      <div className="mb-[10px] flex items-center justify-around sm:justify-between flex-wrap ">
        <div className="cn_app_cardDate text-nowrap">{t("TopSitesGrowthByGroup.title")}</div>
        <div className="select-range">
          <Select
            defaultActiveFirstOption={true}
            dropdownMatchSelectWidth={true}
            options={duree}
            onSelect={(value, option) => handleChangeTopGrowth(value)}
            width={170}
          />
        </div>
      </div>
      {loading ? (
        <Spin />
      ) : (
        <Table
          dataSource={dashboardTopSitesGrowthByGroup}
          columns={topGrowthColumns}
          //scroll={{ y: 140 }}
        />
      )}
    </>
  );
};

export default TopSitesGrowthByGroupTable;
