import React from "react";
import { useTranslation } from "react-i18next";

function FormLegend({ legend, requiredFormItemLegend }) {
 const { t } = useTranslation();
  return (
    <div className="flex flex-col items-start align-self-start text-xs">
      {requiredFormItemLegend && (
        <div>
          <span className="text-error-color text-sm">* </span>{t("FormLegend.requiredField")}
        </div>
      )}
      <div>{legend}</div>
    </div>
  );
}

export default FormLegend;
