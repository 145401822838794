import translationModAuthenticationEN from 'ck_commun/src/app/modAuthentication/locale/en.json';
import translationModAuthenticationFR from 'ck_commun/src/app/modAuthentication/locale/fr.json';
import translationModEquipmentEN from 'ck_commun/src/app/modEquipment/locale/en.json';
import translationModEquipmentFR from 'ck_commun/src/app/modEquipment/locale/fr.json';
import translationModManagerEN from 'ck_commun/src/app/modManager/locale/en.json';
import translationModManagerFR from 'ck_commun/src/app/modManager/locale/fr.json';

import translationModUtilsEN from 'ck_commun/src/app/modUtils/locale/en.json';
import translationModUtilsFR from 'ck_commun/src/app/modUtils/locale/fr.json';

const commonTranslation = {
    en: {
        ...translationModAuthenticationEN,
        ...translationModEquipmentEN,
        ...translationModManagerEN,
        ...translationModUtilsEN
    },
    fr: {
        ...translationModAuthenticationFR,
        ...translationModEquipmentFR,
        ...translationModManagerFR,
        ...translationModUtilsFR
    }
};
  
export default commonTranslation;
