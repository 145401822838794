import React, { FC } from "react";
import { ReactComponent as BuildingPageSvg } from "../../assets/images/building-page.svg";
import { useTranslation } from "react-i18next";

const BuildingPage: FC = () => {
  const { t } = useTranslation();
  return (
    <div className="grid mt-40 bg-white">
      <div className="text-center flex items-center flex-col">
        <BuildingPageSvg className={`max-w-sm`} />
        <h2 className="mt-10 text-2xl font-bold tracking-tight text-gray-900 sm:text-4xl">
        {String(t("buildingPage.title"))}
        </h2>
        <p className="mt-4 text-xl text-gray-500">
          {" "}
          {String(t("buildingPage.description"))}
        </p>
      </div>
    </div>
  );
};

export default BuildingPage;
