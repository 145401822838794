import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { update_user_password } from "../slices/authenticationSlice";
import { Form, Input, message } from "antd";
import {
  Spinner,
  Button,
} from "../../modUtils/components/componentsLibrary/componentsLibrary";
import SubmitButton from "../../modUtils/components/buttons/submitButton/SubmitButton";
import AlertMessage from "../../modUtils/components/AlertMessage";
import FormLegend from "../../modUtils/components/FormLegend";
import { useTranslation } from "react-i18next";

function PasswordUpdateForm() {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const userId = localStorage.getItem("user");
  const { t } = useTranslation();
  const passwordInputTooltip = (
    <ul>
      <li>- {t("passwordUpdate.inputTootlipChart")}</li>
      <li>- {t("passwordUpdate.inputTootlipNum")}</li>
      <li>- {t("passwordUpdate.inputTootlipCommon")}</li>
    </ul>
  );
  // Fonction de validation personnalisée pour vérifier si le mot de passe n'est pas entièrement numérique
  const validatePassword = (_, value) => {
    if (/^\d+$/.test(value)) {
      return Promise.reject(t("passwordUpdate.validatePassword"));
    } else {
      return Promise.resolve();
    }
  };

  //les régles de validations communes pour le champs mot de passe
  const commonPasswordRules = [
    {
      required: true,
      message: t("passwordUpdate.required"),
    },
    { validator: validatePassword },
    {
      min: 8,
      max: 68,
      message: t("passwordUpdate.validate"),
    },
  ];
  const updateUserPassword = async (values) => {
    try {
      setLoading(true);
      await dispatch(update_user_password(values)).unwrap();
      setLoading(false);
      message.success({
        content: <AlertMessage alertMessage={t("passwordUpdate.success")} />,
      });
    } catch (error) {
      let detail = error.data.detail;
      message.error({
        content: (
          <AlertMessage
            status={error.status}
            alertMessage={t("passwordUpdate.error")}
            errorDetail={
              <div className="flex flex-col justify-start mt-4">
                {!detail.previous_password && !detail.next_password && (
                  <div className="text-md font-bold text-error-color">
                    {detail}
                  </div>
                )}
                {detail?.previous_password && (
                  <div>
                    <div className="text-md font-bold">
                      {t("passwordUpdate.labelOldPassword")}
                      <div />
                      <ul className="list-outside	list-disc text-error-color text-sm font-normal text-start">
                        {detail?.previous_password?.map((msg) => (
                          <li>{msg}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                )}
                {detail?.next_password && (
                  <div>
                    <div className="text-md font-bold">
                      {t("passwordUpdate.labelNewPassword")}
                      <div />
                      <ul className="list-outside	list-disc text-error-color text-sm font-normal text-start">
                        {detail?.next_password?.map((msg) => (
                          <li>{msg}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                )}
              </div>
            }
          />
        ),
      });
      setLoading(false);
      form.resetFields();
    }
  };

  useEffect(() => {
    form.resetFields();
  }, [dispatch, form]);

  const handleSubmit = (values) => {
    updateUserPassword({
      userId,
      previousPassword: values.previousPassword,
      nextPassword: values.nextPassword,
    });
  };

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <Form
          form={form}
          name="passwordUpdateForm"
          autoComplete="off"
          onFinish={handleSubmit}
          layout="vertical"
        >
          <Form.Item
            label={t("passwordUpdate.labelOldPassword")}
            name="previousPassword"
            rules={commonPasswordRules}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            label={t("passwordUpdate.labelNewPassword")}
            name="nextPassword"
            dependencies={["previousPassword"]}
            tooltip={passwordInputTooltip}
            rules={[
              ...commonPasswordRules,

              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("previousPassword") !== value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(t("passwordUpdate.newPasswordError"))
                  );
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            label={t("passwordUpdate.labelConfirmation")}
            name="validationNextPassword"
            dependencies={["nextPassword"]}
            rules={[
              {
                required: true,
                message: t("passwordUpdate.messageConfirmation"),
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("nextPassword") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(t("passwordUpdate.messageConfirmation"))
                  );
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
          <FormLegend requiredFormItemLegend={true} />
          <div className="mt-4 flex justify-content-center">
            <Form.Item>
              {/* <SubmitButton label="MODIFIER MOT DE PASSE" /> */}
              <Button htmlType="submit" type="primary">
                {t("passwordUpdate.updateButton")}
              </Button>
            </Form.Item>
          </div>
        </Form>
      )}
    </>
  );
}

export default PasswordUpdateForm;
