import React from "react";
import Button from "ck_commun/src/app/modUtils/components/buttons/Button";
import { ReactComponent as PlusIconSvg } from "../../../modEntity/assets/images/plus-square-white.svg";

export default function ButtonAdd({ onClick, label, className="" }) {
    return (
        <Button
            className={`
                bg-secondary-color-600 border-secondary-color-600 hover:bg-secondary-color-700
                h-9 pl-2 min-w-fit w-fit rounded text-xs flex items-center
                ${className}
            `}
            onClick={onClick}
        >
            <div className="flex flex-row items-center">
                <PlusIconSvg/>
                <div className="text-white font-semibold ml-2 h-[22px] flex items-center ">{label}</div>
            </div>
        </Button>
    );
};
