import React, { useEffect } from "react";
import BuildingPage from "ck_commun/src/app/modUtils/components/errorPage/BuildingPage";
import GroupInformationsForm from "ck_commun/src/app/modManager/components/GroupInformationsForm";
import UserInformationsForm from "ck_commun/src/app/modAuthentication/components/UserInformationsForm";
import PasswordUpdateForm from "ck_commun/src/app/modAuthentication/components/PasswordUpdateForm";
import { useTranslation } from "react-i18next";

export default function Account() {
  const { t } = useTranslation();
  return (
    <div className="grid grid-cols-2 gap-10  p-10 divide-x-0 divide-y-[1px] lg:divide-x-[1px] lg:divide-y-0 divide-gray-200 divide-solid ">
      <div className="col-span-2 lg:col-span-1 ">
        <div className="text-2xl font-bold text-primary-color mb-8">
        {t("Account.company")}
        </div>
        <GroupInformationsForm />
      </div>

      <div className="col-span-2 lg:col-span-1 pt-10 lg:pt-0 lg:pl-10">
        <div className="text-2xl font-bold text-primary-color mb-8">
          {t("Account.userConnected")}
        </div>
        <div className="divide-y-[1px] divide-x-0 divide-gray-200 divide-solid">
          <div>
            <div className="text-xl font-bold leading-10 text-primary-color-800">
              Informations
            </div>
            <UserInformationsForm />
          </div>
          <div className="pt-4 mt-10">
            <div className="text-xl font-bold	leading-10 text-primary-color-800">
            {t("Account.changePasssword")}
            </div>
            <PasswordUpdateForm />
          </div>
        </div>
      </div>
    </div>
  );
}
