import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Input,
  Modal,
  Select,
} from "../../../../modUtils/components/componentsLibrary/componentsLibrary";
import SubmitButton from "../../../../modUtils/components/buttons/submitButton/SubmitButton";
import { get_equipments_gb_sites_by_group_paginated } from "../../../../modEquipment/slices/equipmentSlice";
import { create_cash_boxes_by_group } from "../../../slices/statementSlice";
import { message } from "antd";
import * as Yup from "yup";
import { Formik } from "formik";
import { FaPen } from "react-icons/fa";

const UpdateCashBox = ({ cashbox, refresh, site }) => {
  const { groupId } = useSelector((state) => state.manager);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [equipments, setEquipments] = useState(
    cashbox.cash_box_equipments.map((eq) => eq.equipment.id)
  );
  const [cashBoxEquipments, setCashBoxEquipments] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [creationError, setCreationError] = useState(null);
  const [loading, setLoading] = useState(false);
  const formReset = useRef(null);
  const CashboxSchema = Yup.object().shape({
    name: Yup.string().required(t("addNewCashbox.addCashboxInputError")),
  });
  const fetchEquipments = async () => {
    try {
      const equipmentData = await dispatch(
        get_equipments_gb_sites_by_group_paginated({
          groupId,
          limit: 100,
          offset: 0,
        })
      ).unwrap();
      //find site object from site selection
      const siteDataSelection = equipmentData.results.find(
        (item) => item.id === site
      );
      //get equipements of site selection
      const equipmentSiteData = siteDataSelection.equipments;
      setEquipments(equipmentSiteData);
    } catch (error) {
      console.error("Error fetching equipment:", error);
    }
  };
  const createCashbox = async (values, resetForm) => {
    setCreationError(null);
    setLoading(true);
    try {
      const params = {
        groupId,
        name: values.name,
        site: site,
        isOld: false,
        cashBoxEquipments: cashBoxEquipments.map((equipment) => ({
          equipment: equipment.id,
        })),
      };
      await dispatch(create_cash_boxes_by_group(params)).unwrap();
      message.success(t("updateCashbox.successEdit"));
      refresh();
      setModalVisible(false);
    } catch (error) {
      setCreationError(error?.data?.detail);
    } finally {
      setLoading(false);
    }
  };
  const handleEquipementValueChange = (selectedValue) => {
    if (!Array.isArray(selectedValue)) {
      selectedValue = [selectedValue]; //s'assurer que c'est un array
    }
    const selectedEquipments = selectedValue
      .map((selectedId) => {
        const foundEquipment = equipments.find(
          (equipment) => Number(equipment.id) === Number(selectedId)
        ); //recherche de l equipement avec l id
        return foundEquipment;
      })
      .filter(Boolean); //filtrer les undifined
    setCashBoxEquipments((prevEquipement) => {
      const newEquipment = selectedEquipments.filter(
        (selectEq) =>
          !prevEquipement.some((prevEq) => prevEq.id === selectEq.id)
      ); ///verification des doublons
      if (newEquipment.length === 0) return prevEquipement;
      return [...prevEquipement, ...newEquipment];
    });
  };
  useEffect(() => {
    if (!modalVisible) {
      setCashBoxEquipments([]);
      setCreationError(null);
    }
  }, [modalVisible]);
  return (
    <>
      <Button type="image">
        <FaPen
          size={18}
          fill="black"
          onClick={() => {
            setModalVisible(true), fetchEquipments();
          }}
        />
      </Button>
      <Modal
        isOpen={modalVisible}
        onClose={() => {
          formReset.current();
          setModalVisible(false);
        }}
        width={700}
        zIndex={2000}
      >
        <div>
          <div className="flex items-center justify-center">
            <span className="font-bold text-base items-center justify-center uppercase text-primary-color">
              {t("updateCashbox.updateCashboxTitle")}
            </span>
          </div>

          <Formik
            validationSchema={CashboxSchema}
            initialValues={{ name: cashbox?.name || "" }}
            onSubmit={(values, { resetForm }) => {
              createCashbox(values, resetForm);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleSubmit,
              resetForm,
            }) => {
              formReset.current = resetForm;
              return (
                <form
                  i="form"
                  noValidate
                  onSubmit={handleSubmit}
                  ref={formReset}
                >
                  <div className="flex flex-row gap-8 mt-8">
                    <div className="items-start">
                      <Input
                        label={
                          <span className="text-black normal-case">
                            {t("addNewCashbox.cashboxName") + "*"}
                          </span>
                        }
                        value={values.name}
                        name="name"
                        onChange={handleChange}
                        placeholder={t("addNewCashbox.cashboxName")}
                      />

                      <p className="text-red-500 text-xs">
                        {errors.name && touched.name && errors.name}
                      </p>
                    </div>
                    <div className="items-end">
                      <Select
                        selectLabel={
                          <span className="text-black normal-case">
                            {t("addNewCashbox.cashboxEquipement")}
                          </span>
                        }
                        value={cashBoxEquipments.map((eq) => eq.id)}
                        options={
                          Array.isArray(equipments)
                            ? equipments.map((eq) => ({
                                value: eq.id,
                                label: eq.name,
                              }))
                            : []
                        }
                        onSelect={handleEquipementValueChange}
                        multiple
                        placeholder={t("addNewCashbox.cashboxEquipementSelect")}
                      />
                    </div>
                  </div>

                  <div className="grid justify-items-center mt-12 ">
                    <SubmitButton
                      label={
                        <span className="uppercase">
                          {t("addNewCashbox.addCashboxButton")}
                        </span>
                      }
                    />
                    {creationError && (
                      <div className="items-center gap-1">
                        {" "}
                        <FaExclamationCircle className="w-4" fill="red" />
                        <span className="text-red-500 text-xs ">
                          {creationError}
                        </span>
                      </div>
                    )}
                  </div>
                </form>
              );
            }}
          </Formik>
        </div>
      </Modal>
    </>
  );
};
export default UpdateCashBox;
