import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
    httpGet,
    httpPost,
    httpDelete,
    httpPut
} from "ck_commun/src/app/modUtils/apis/httpConfig";

// retourner la liste des contraintes périodes d'une configuration
export const get_constraint_period_paginated = createAsyncThunk(
    "mod_profil/get_constraint_period_paginated",
    async (values, { rejectWithValue }) => {
        try {
            const response = await httpGet(
                `mod_profil/groups/${values.groupId}/configurations/${values.configurationId}/constraints_period/`,
                {
                    params: {
                        limit: values.limit,
                        offset: values.offset
                    }
                }
            );
            return response.data;
        } catch (error) {
            return rejectWithValue({
                status: error.response.status,
                data: error.response.data
            });
        }
    }
);

// Créer une contrainte de période
export const create_constraint_period = createAsyncThunk(
    "mod_profil/create_constraint_period",
    async (values, { rejectWithValue }) => {
        try {
            const response = await httpPost(
                `mod_profil/groups/${values.groupId}/configurations/${values.configurationId}/constraints_period/`,
                {
                    name: values.name,
                    equipment_type: values.equipmentType,
                    is_global: values.isGlobal,
                    day_start: values.dayStart,
                    month_start: values.monthStart,
                    day_end: values.dayEnd,
                    month_end: values.monthEnd
                }
            );
            return response.data;
        } catch (error) {
            return rejectWithValue({
                status: error.response.status,
                data: error.response.data
            });
        }
    }
);

// retourner le détail d'une contrainte période
export const get_constraint_period_detail = createAsyncThunk(
    "mod_profil/get_constraint_period_detail",
    async (values, { rejectWithValue }) => {
        try {
            const response = await httpGet(
                `mod_profil/groups/${values.groupId}/configurations/${values.configurationId}/constraints_period/${values.constraintId}/`
            );
            return response.data;
        } catch (error) {
            return rejectWithValue({
                status: error.response.status,
                data: error.response.data
            });
        }
    }
);

// modifier une contrainte période
export const update_constraint_period_detail = createAsyncThunk(
    "mod_profil/update_constraint_period_detail",
    async (values, { rejectWithValue }) => {
        try {
            const response = await httpPut(
                `mod_profil/groups/${values.groupId}/configurations/${values.configurationId}/constraints_period/${values.constraintId}/`,
                {
                    name: values.name,
                    equipment_type: values.equipmentType,
                    is_global: values.isGlobal,
                    day_start: values.dayStart,
                    month_start: values.monthStart,
                    day_end: values.dayEnd,
                    month_end: values.monthEnd
                }
            );
            return response.data;
        } catch (error) {
            return rejectWithValue({
                status: error.response.status,
                data: error.response.data
            });
        }
    }
);

// Supprimer une contrainte période
export const delete_constraint_period_detail = createAsyncThunk(
    "mod_profil/delete_constraint_period_detail",
    async (values, { rejectWithValue }) => {
        try {
            const response = await httpDelete(
                `mod_profil/groups/${values.groupId}/configurations/${values.configurationId}/constraints_period/${values.constraintId}/`
            );
            return {...response.data, id: values.constraintId};
        } catch (error) {
            return rejectWithValue({
                status: error.response.status,
                data: error.response.data
            });
        }
    }
);

const constraintPeriodSlice = createSlice({
    name: "constraintPeriod",
    initialState: {
        loading: true,
        constraintsPeriod: [],
        constraintsPeriodDetail: {}
    },
    extraReducers: (builder) => {
        builder
            /* get_constraint_period_paginated */
            .addCase(get_constraint_period_paginated.pending, (state, action) => {
                state.constraintsPeriod = [];
            })
            .addCase(get_constraint_period_paginated.fulfilled, (state, action) => {
                state.loading = false;
                state.constraintsPeriod = action.payload.results;
            })
            .addCase(get_constraint_period_paginated.rejected, (state, action) => {
                state.loading = false;
            })

            /* create_constraint_period */
            .addCase(create_constraint_period.fulfilled, (state, action) => {
                state.loading = false;
                action.payload.index = action.payload.id;
                state.constraintsPeriod = [...state.constraintsPeriod, action.payload];
            })
            .addCase(create_constraint_period.rejected, (state, action) => {
                state.loading = false;
            })

            /* get_constraint_period_detail */
            .addCase(get_constraint_period_detail.pending, (state, action) => {
                state.constraintsPeriodDetail = {};
            })
            .addCase(get_constraint_period_detail.fulfilled, (state, action) => {
                state.loading = false;
                state.constraintsPeriodDetail = action.payload;
            })
            .addCase(get_constraint_period_detail.rejected, (state, action) => {
                state.loading = false;
            })

            /* update_constraint_period_detail*/
            .addCase(update_constraint_period_detail.fulfilled, (state, action) => {
                state.loading = false;
                const updatedConstraintPeriod = action.payload;
                const updatedConstraintsPeriodList = state.constraintsPeriod.map(
                    (constraint) => {
                        if (constraint.id === updatedConstraintPeriod.id) {
                            return updatedConstraintPeriod;
                        }
                        return constraint;
                    }
                );
                state.constraintsPeriod = updatedConstraintsPeriodList;
            })
            .addCase(update_constraint_period_detail.rejected, (state, action) => {
                state.loading = false;
            })

            /* delete_constraint_period_detail */
            .addCase(delete_constraint_period_detail.fulfilled, (state, action) => {
                const constraintPeriodToDeleteId = action.payload.id;
                state.constraintsPeriod = state.constraintsPeriod.filter(
                    (constraint) => constraint.id !== constraintPeriodToDeleteId
                );        
                state.loading = false;
            })
            .addCase(delete_constraint_period_detail.rejected, (state, action) => {
                state.loading = false;
            });
    }
});

export default constraintPeriodSlice.reducer;
