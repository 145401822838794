import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
// import { Popover } from "../componentsLibrary/componentsLibrary";
import { Popover } from "antd";
import "./DropdownActionsMenu.scss";
function DropdownActionsMenu({
  triggerImg,
  menuItems,
  placement,
  handleVisibleChange,
  visible,
}) {
  const content = (
    <ul className="action-button-menu-elements">
      {menuItems?.map((menuItem, index) => (
        <li key={index}>{menuItem}</li>
      ))}
    </ul>
  );
  return (
    <Popover
      content={content}
      arrow={false}
      className="popover-action-menu"
      trigger="click"
      open={visible}
      onOpenChange={handleVisibleChange}
      placement={placement}
    >
      <div style={{ cursor: "pointer" }}>{triggerImg}</div>
    </Popover>
  );
}
DropdownActionsMenu.propTypes = {
  menuItems: PropTypes.array.isRequired,
  triggerImg: PropTypes.node,
  placement: PropTypes.oneOf([
    "topLeft",
    "topRight",
    "bottomLeft",
    "bottomRight",
    "middle",
  ]),
  // onClose: PropTypes.func,
  // closable: PropTypes.bool,
  // duration: PropTypes.number,
  // visible: PropTypes.bool
};

DropdownActionsMenu.defaultProps = {
  placement: "bottomRight",
  triggerImg: <img src={process.env.PUBLIC_URL + "/img/actionsButton.png"} />,
  trigger:"click"
};
export default DropdownActionsMenu;
