import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { create_blacklist_history_by_support } from "../slices/walletLocalSlice";
import SupportLinkToCustomerModal from "./supportLinkToCustomerModal/SupportLinkToCustomerModal";
import SupportDetailsModal from "./SupportDetailsModal";
import SupportCreditModal from "./supportCreditModal/SupportCreditModal";
import SupportCustomerInfosModal from "./supportCustomerInfosModal/SupportCustomerInfosModal";
import DropdownActionsMenu from "../../modUtils/components/dropdownActionsMenu/DropdownActionsMenu";
import SupportUnlickFromCustomer from "./SupportUnlinkFromCustomer";
import { message } from "antd";
import AlertMessage from "../../modUtils/components/AlertMessage";
import { Spinner } from "../../modUtils/components/componentsLibrary/componentsLibrary";
import { useTranslation } from "react-i18next";

export default function SupportMenuButton({ support }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const { groupId } = useSelector((state) => state.manager);
  const [loading, setLoading] = useState(false);
  const [
    isSupportCustomerInfosModalVisible,
    setIsSupportCustomerInfosModalVisible,
  ] = useState(false);
  const [isSupportDetailsModalVisible, setIsSupportDetailsModalVisible] =
    useState(false);
    const [isSupportLinkCustomerModalVisible, setIsSupportLinkCustomerModalVisible] =
    useState(false);
  const hide = () => {
    setVisible(false);
  };

  const handleVisibleChange = (newVisible) => {
    setVisible(newVisible);
  };

  //blacklistSupport: appel à l'api create_blacklist_history_by_support pour blacklister/déblacklister un badge */
  const blacklistSupport = async (support) => {
    hide();
    const blocked = !support.is_blacklisted;
    setLoading(true);
    try {
      await dispatch(
        create_blacklist_history_by_support({
          groupId,
          supportId: support.id,
          blocked,
        })
      ).unwrap();
    } catch (error) {
      message.error({
        content: (
          <AlertMessage
            status={error?.status}
            alertMessage={"Une erreur est survenue!"}
            errorDetail={error?.data?.detail}
          />
        ),
      });
    } finally {
      setLoading(false);
    }
  };
  //fonction appelé quand on clique sur "Informations Client"
  const handleOpenCustomerInfosModal = () => {
    hide();
    setIsSupportCustomerInfosModalVisible(true);
  };
  //fonction appelé quand on clique sur "Details"
  const handleOpenSupportDetails = () => {
    hide();
    setIsSupportDetailsModalVisible(true);
  };
  //fonction appelé quand on clique sur "Associer Client"
  const handleOpenSupportLinkCustomerModal = () => {
    hide();
    setIsSupportLinkCustomerModalVisible(true);
  };
  const menuItems = [
    <p onClick={handleOpenSupportDetails}>{t("supportMenuButton.detailsButton")}</p>,
    <SupportCreditModal closeModal={hide} support={support} />,
    /*Si le badge est déjà associé à un client afficher le menu “information client” sinon afficher le menu "associer à un client" */
    support.entity ? (
      <p onClick={handleOpenCustomerInfosModal}>{t("supportMenuButton.infosClientButton")}</p>
    ) : (
      <p onClick={handleOpenSupportLinkCustomerModal}>{t("supportMenuButton.supportLinkClientButton")}</p>
    ),
    support.entity && (
      <SupportUnlickFromCustomer
        closeModal={hide}
        supportId={support?.id}
        entity={support?.entity}
        badgeNumber={support?.badge_number}
      />
    ),
    /*Si le badge est déjà blacklisté  afficher le menu "Déblacklister" sinon afficher le menu "blacklister" */
    <p onClick={() => blacklistSupport(support)}>
      {support?.is_blacklisted !== false ? t("supportMenuButton.Unblacklist") : t("supportMenuButton.blacklist")}
    </p>,
  ];

  return (
    <>
      {loading && <Spinner />}
      <DropdownActionsMenu
        menuItems={menuItems}
        handleVisibleChange={handleVisibleChange}
        visible={visible}
      />
      {isSupportCustomerInfosModalVisible && (
        <SupportCustomerInfosModal
          supportId={support?.id}
          entity={support?.entity}
          modalVisible={isSupportCustomerInfosModalVisible}
          setModalVisible={setIsSupportCustomerInfosModalVisible}
        />
      )}
      {isSupportDetailsModalVisible && (
        <SupportDetailsModal
          modalVisible={isSupportDetailsModalVisible}
          setModalVisible={setIsSupportDetailsModalVisible}
          supportId={support?.id}
        />
      )}
      {isSupportLinkCustomerModalVisible && <SupportLinkToCustomerModal modalVisible={isSupportLinkCustomerModalVisible}
          setModalVisible={setIsSupportLinkCustomerModalVisible} support={support} />}
           
    </>
  );
}
