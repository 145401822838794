import React from 'react'
import { Button, Card } from 'ck_commun/src/app/modUtils/components/componentsLibrary/componentsLibrary'
import PlusCircleOutlined from '@ant-design/icons'
import './Dashboard.css'
import { PromoCard_style } from './PromoCard.style'
import BuildingPage from 'ck_commun/src/app/modUtils/components/errorPage/BuildingPage'

export default function PromoDashboard() {
  return (
      <>
      <BuildingPage />
      </>
      )
}
