import React from 'react';

export default function OptionsList({ placement, ...props }) {

    return (
        <div
            className={`
                border border-solid border-primary-color rounded-md bg-white text-primary-color
                cursor-pointer w-full z-[100000] flex flex-col items-start
                absolute max-w-52 whitespace-nowrap overflow-hidden text-ellipsis
                ${placement === "top" && "bottom-full left-0"}
            `}
        >
            {props.children}
        </div>
    );
};