import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { httpGet } from "ck_commun/src/app/modUtils/apis/httpConfig";

// Liste des cumuls pour une entité
export const constraint_fincancial_entity_cumuls_list_get = createAsyncThunk(
    "mod_profil/constraint_fincancial_entity_cumuls_list_get",
    async (values, { rejectWithValue }) => {
        try {
            const response = await httpGet(
                `mod_profil/groups/${values.groupId}/entities/${values.entityId}/cumuls/`,
                {
                    params: {
                        limit: values.limit,
                        offset: values.offset
                    }
                }
            );
            return response.data;
        } catch (error) {
            return rejectWithValue({
                status: error.response.status,
                data: error.response.data
            });
        }
    }
);

const constraintFincancialEntityCumulsSlice = createSlice({
    name: "constraintFincancialEntityCumuls",
    initialState: {
        loading: true,
        ListOfAccumulations: []
    },
    extraReducers: (builder) => {
        builder
            /* constraint_fincancial_entity_cumuls_list_get */
            .addCase(constraint_fincancial_entity_cumuls_list_get.pending, (state, action) => {
                state.ListOfAccumulations = [];
            })
            .addCase(constraint_fincancial_entity_cumuls_list_get.fulfilled, (state, action) => {
                state.loading = false;
                state.ListOfAccumulations = action.payload;
            })
            .addCase(constraint_fincancial_entity_cumuls_list_get.rejected, (state, action) => {
                state.loading = false;
            })
    }
});

export default constraintFincancialEntityCumulsSlice.reducer;
