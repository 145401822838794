import { useEffect, useRef } from "react";

export default function useScrollHandler(onScrollEnd) {
  const tableRef = useRef();
  const lastScrollTop = useRef(0); // Stocker la dernière position de défilement

  const handleScroll = () => {
    const tableElement = tableRef.current;
    const scrollTop = tableElement.scrollTop; // Position actuelle du défilement

    // Vérifiez si le défilement est uniquement vertical
    if (scrollTop > lastScrollTop.current) { // Scroll down
      if (scrollTop + tableElement.clientHeight >= tableElement.scrollHeight - 10) {
        onScrollEnd();
      }
    }

    lastScrollTop.current = scrollTop; // Mettre à jour la dernière position de défilement
  };

  useEffect(() => {
    const tableElement = tableRef.current;
    if (tableElement) {
      tableElement.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (tableElement) {
        tableElement.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  return { tableRef, handleScroll };
};
