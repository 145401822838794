
import Cookies from "js-cookie";
import { currentDomain } from "../constants";

export function replaceCookieDomain(){
      const existingTokenRefresh = Cookies.get("token_refresh");
      Cookies.remove("token_refresh")
      //si le cookie est trouvé, créer un nv avec une durée de six mois et supprimer l'ancien 
      if (existingTokenRefresh) {
        Cookies.set("token_refresh", existingTokenRefresh, {
          domain: currentDomain,
        });
      }
  }
