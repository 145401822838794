import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Form, Radio, Row, Upload } from "antd";
import {
  Modal,
  Input,
} from "../../../modUtils/components/componentsLibrary/componentsLibrary";
import { UploadOutlined } from "@ant-design/icons";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { hasPermission } from "../../../modUtils/functions/handlePermissions";
import PermissionDeniedPage from "../../../modUtils/components/errorPage/PermissionDeniedPage";
//import { updateSite } from "redux/slices/managerSlice"

import ButtonDisabled from "../../../modUtils/components/buttons/ButtonDisabled";
import "./SiteUpdateModal.scss";
import Button from "../../../modUtils/components/buttons/Button";
import { update_group_detail } from "../../../modManager/slices/managerSlice";

//const { TextArea } = Input;

export default function SiteUpdateModel({ closeModal, site, mapModal }) {
  const dispatch = useDispatch();
  const { selectedGroup, groupId } = useSelector((state) => state.manager);
  const [modalVisible, setModalVisible] = useState(false);
  const [isSiteDefault, setIsSiteDefault] = useState(
    selectedGroup?.site_default?.id === site?.id
  );
  const permissions = selectedGroup?.permissions;

  const verifyPermission = hasPermission(
    "mod_manager:manage_company",
    permissions
  );
  const groupPermissions = selectedGroup?.plan?.permissions;
  const verifyGroupPermission = hasPermission(
    "mod_manager:manage_company",
    groupPermissions
  );
  // close SiteManageModel when opening SiteUpdateModel
  const openModal = () => {
    setModalVisible(true);
    closeModal();
  };
  const { t } = useTranslation();
  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }

    return e?.fileList;
  };
  const handleCancel = () => {
    setModalVisible(false);
  };

  const handleSubmit = (values, resetForm) => {
    setModalVisible(false);
  };

  const updateDefaultSite = (e) => {
    setIsSiteDefault(e.target.value);
    if (e.target.value) {
      dispatch(
        update_group_detail({
          groupId: groupId,
          siteDefault: site.id,
        })
      );
    }
  };

  return (
    <>
      {verifyPermission && verifyGroupPermission ? (
        <Button
          onClick={openModal}
          color="primary"
          className="h-10 w-full mx-[5px]"
          size="sm"
        >
          {t("site.transUpdateSiteButton")}
        </Button>
      ) : (
        <ButtonDisabled
          onClick={openModal}
          className="h-10 w-full mx-[5px]"
          size="sm"
        >
          {t("site.transUpdateSiteButton")}
        </ButtonDisabled>
      )}
      <Formik
        initialValues={{
          id: site.id,
          name: site.name,
          // adresseSite: site.adres,
          // cpSite: site.cpSite,
          // villeSite: site.villeSite,
          // latitudeSite: site.location_latitude,
          // longitudeSite: site.location_longitude,
          // code: site.code,
          // descriptionSite: site.descriptionSite,
          // photoSite: site.photoSite,
          isSiteDefault: isSiteDefault,
          // equipement: site.equipement,
        }}
        onSubmit={(values, { resetForm }) => {
          handleSubmit(values, resetForm);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isValid,
        }) => (
          <Modal
            title={t("site.transUpdateSiteModalTitle")}
            isOpen={modalVisible}
            onClose={() => setModalVisible(false)}
            onCancel={verifyPermission && handleCancel}
            onOk={verifyPermission && handleSubmit}
          >
            {!verifyPermission ? (
              <PermissionDeniedPage permission={verifyGroupPermission} />
            ) : (
              <div className="siteUpdateInfos">
                <div>
                  <Input
                    name="name"
                    label={t("site.transSiteNameLabel")}
                    value={values.name}
                    onChange={handleChange}
                    style={!site?.name && { display: "none" }}
                    disabled
                  />
                  {!site?.name && (
                    <p className="text-xs font-normal leading-4 mb-6">
                      {t("site.transUnknown")}
                    </p>
                  )}
                </div>
                <div>
                  <Input
                    name="adresseSite"
                    label={t("site.transSiteAddressLabel")}
                    style={
                      !site?.location_address?.street_number &&
                      !site?.location_address?.street && { display: "none" }
                    }
                    value={
                      `${
                        site?.location_address?.street_number
                          ? site?.location_address?.street_number
                          : ""
                      }` +
                      `${
                        site?.location_address?.street
                          ? site?.location_address?.street
                          : ""
                      }`
                    }
                    onChange={handleChange}
                    disabled
                  />
                  {!site?.location_address?.street_number &&
                    !site?.location_address?.street && (
                      <p className="text-xs font-normal leading-4 mb-6">
                        {t("site.transUnknown")}
                      </p>
                    )}
                </div>
                <div className="flex-display justify-content-between gap-2">
                  <div className="w-6/12">
                    <Input
                      name="cpSite"
                      label={t("site.transSiteCpLabel")}
                      value={site?.location_address?.zipcode}
                      style={
                        !site?.location_address?.zipcode && { display: "none" }
                      }
                      // onChange={handleChange}
                      disabled
                    />
                    {!site?.location_address?.zipcode && (
                      <p className="text-xs font-normal leading-4 mb-6">
                        {t("site.transUnknown")}
                      </p>
                    )}
                  </div>
                  <div className="w-6/12">
                    <Input
                      name="villeSite"
                      label={t("site.transSiteCityLabel")}
                      style={
                        !site?.location_address?.city && { display: "none" }
                      }
                      value={site?.location_address?.city}
                      // onChange={handleChange}
                      disabled
                    />
                    {!site?.location_address?.city && (
                      <p className="text-xs font-normal leading-4 mb-6">
                        {t("site.transUnknown")}
                      </p>
                    )}
                  </div>
                </div>
                <div className="flex-display justify-content-between gap-2">
                  <div className="w-6/12">
                    <Input
                      name="latitudeSite"
                      label={t("site.transSiteLatLabel")}
                      style={!site?.location_latitude && { display: "none" }}
                      value={site?.location_latitude}
                      // onChange={handleChange}
                      disabled
                    />
                    {!site?.location_latitude && (
                      <p className="text-xs font-normal leading-4 mb-6">
                        {t("site.transUnknown")}
                      </p>
                    )}
                  </div>
                  <div className="w-6/12">
                    <Input
                      name="longitudeSite"
                      label={t("site.transSiteLongLabel")}
                      style={!site?.location_longitude && { display: "none" }}
                      value={site?.location_longitude}
                      //onChange={handleChange}
                      disabled
                    />
                    {!site?.location_longitude && (
                      <p className="text-xs font-normal leading-4 mb-6">
                        {t("site.transUnknown")}
                      </p>
                    )}
                  </div>
                  {/* <FaLocationArrow></FaLocationArrow> */}
                </div>
                <Input
                  name="code"
                  label={t("site.transSiteCodeLabel")}
                  style={!site?.code && { display: "none" }}
                  //onChange={handleChange}
                  disabled
                />
                {!site?.code && (
                  <p className="text-xs font-normal leading-4 mb-6">
                    {t("site.transUnknown")}
                  </p>
                )}
                <Form
                  name="siteUpdateForm"
                  onSubmitCapture={handleSubmit}
                  initialValues={values}
                  labelCol={{ span: 6 }}
                  wrapperCol={{ span: 16 }}
                >
                  <div>
                    {mapModal && (
                      <div>
                        {/* <Form.Item
            name="descriptionSite"
            label={t("site.transSiteDescriptionLabel")}
        >
            <TextArea
            name="descriptionSite"
            rows={4}
            onChange={formik.handleChange}
            />
        </Form.Item> */}
                        <Form.Item
                          name="photoSite"
                          label={t("site.transSitePhotoLabel")}
                          valuePropName="fileList"
                          getValueFromEvent={normFile}
                        >
                          <Upload
                            name="photoSite"
                            listType="picture"
                            maxCount={1}
                            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                          >
                            {/* <Upload name="photoSite" listType="picture" maxCount={1} onChange={formik.handleChange} action="https://www.mocky.io/v2/5cc8019d300000980a055e76"> */}
                            <Button icon={<UploadOutlined />}>
                              {t("site.transSiteUploadPhotoButton")}
                            </Button>
                          </Upload>
                        </Form.Item>
                      </div>
                    )}

                    <Form.Item
                      label={
                        <img
                          className="meteoIcon"
                          src={process.env.PUBLIC_URL + "/img/meteoIcon.png"}
                          alt="img"
                        />
                      }
                    >
                      <Radio.Group
                        name="isSiteDefault"
                        onChange={updateDefaultSite}
                        value={isSiteDefault}
                      >
                        <Radio value={false}>
                          {t("site.transSiteMeteoOptionFalse")}
                        </Radio>
                        <Radio value={true}>
                          {t("site.transSiteMeteoOptionTrue")}
                        </Radio>
                      </Radio.Group>
                    </Form.Item>
                  </div>
                </Form>
              </div>
            )}
          </Modal>
        )}
      </Formik>
    </>
  );
}
