import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { eEquipmentType } from "../../../commun_global/enums";
import { constraint_fincancial_entity_cumuls_list_get } from "../../modProfil/slices/constraintFincancialEntityCumulsSlice";
import { getPeriodLabel } from "../../modProfil/functions/handleDaysOfWeek";
import { ReactComponent as BlockedSvg } from '../assets/images/blocked.svg';
import TablePaginagtedScroll from "../../modUtils/components/componentsLibrary/table/TablePaginagtedScroll";

export default function EntityAccounts({ entity }) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { groupId } = useSelector((state) => state.manager);

    const columns = [
        {
            title: t("entityAccounts.equipmentType"),
            dataIndex: "equipment_type",
            key: "equipment_type",
            render: (text, record) => record.constraint_financial.equipment_type ? t(eEquipmentType[record.constraint_financial.equipment_type]) : t("entityAccounts.all"),
            width: "20%"
        },
        {
            title: t("entityAccounts.period"),
            dataIndex: "period_type",
            key: "period_type",
            render: (text, record) => getPeriodLabel(record.constraint_financial.period_type),
            width: "20%"
        },
        {
            title: t("entityAccounts.cumulativeEuro"),
            dataIndex: "amount",
            key: "amount",
            width: "20%"
        },
        {
            title: t("entityAccounts.limit"),
            dataIndex: "amount_max",
            key: "amount_max",
            render: (text, record) => record.constraint_financial.amount_max,
            width: "20%"
        },
        {
            title: t("entityAccounts.cumulativeReached"),
            render: (text, record) =>  record.is_reached && <BlockedSvg/>,
            width: "20%"
        }
    ];

    const getConstraintFincancialEntityCumuls = async (limit, offset) => {
        const response = await dispatch(
            constraint_fincancial_entity_cumuls_list_get({
                groupId: groupId,
                entityId: entity.id,
                limit,
                offset
            })
        ).unwrap();
        return response;
    };

    return (
        <TablePaginagtedScroll
            label={t("entityAccounts.accumulationHistory")}
            fetch={getConstraintFincancialEntityCumuls}
            effect={entity}
            columns={columns}
        />
    );
};
