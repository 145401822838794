import React, { useState, useEffect } from "react";
import { Select } from "../componentsLibrary/componentsLibrary";
import RangePicker from "../../components/componentsLibrary/datePicker/RangePicker";
import moment from "moment";
import { setMomentLocale } from "../../functions/dateFormat";
import { useTranslation } from "react-i18next";

//  futureDateOptimized : permet d'arrondir la periode de fin (arrondir a la fin du mois actuelle, fin d'année actuelle)
//  pour gagner en performance et ne pas requeter les cumuls jours inutilement
export default function FilterByDate({
  format,
  onValueChange,
  futureDateOptimized = true,
  defaultPeriod = "currentMonth",
}) {
  const { t } = useTranslation();
  // Options pour la sélection de période prédéfinie
  const options = [
    { label: t("dates.period_today"), value: "today" },
    { label: t("dates.period_lastDay"), value: "lastDay" },
    { label: t("dates.period_lastSevenDays"), value: "lastSevenDays" },
    { label: t("dates.period_currentWeek"), value: "currentWeek" },
    { label: t("dates.lastWeekLabel"), value: "lastWeek" },
    { label: t("dates.period_currentMonth"), value: "currentMonth" },
    { label: t("dates.lastMonthLabel"), value: "lastMonth" },
    { label: t("dates.period_currentYear"), value: "currentYear" },
    { label: t("dates.lastYearLabel"), value: "lastYear" },
    { label: t("dates.custom"), value: "custom" },
  ];
  // State pour la sélection de période : Mois actuel sélectionné par défaut
  const [periodSelect, setPeriodSelect] = useState([defaultPeriod]);
  // State pour le type de période sélectionnée est personnalisé ou prédéfini
  const [isCustomPeriod, setIsCustomPeriod] = useState(false);
  // State pour suivre si une date a été modifiée à partir du DatePicker
  const [dateModified, setDateModified] = useState(false);
  // Variable pour suivre si la modification provient du sélecteur
  const [isSelectModified, setIsSelectModified] = useState(false);
  // States pour les dates de début et de fin
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  // Format de date par défaut s'il n'est pas fourni en prop
  const formatDate = format || "DD/MM/YYYY HH:mm";

  // Fonction pour gérer le changement de plage de dates
  const handleRangeChange = (start, end) => {
    setStartDate(start);
    setEndDate(end);
    onValueChange && onValueChange([start, end]);
  };

  const onDateModifiedChange = () => {
    setDateModified(true);
    setIsSelectModified(false); // Réinitialiser à false lorsque la modification vient du RangePicker
    setPeriodSelect(["custom"]);
  };

  // Effet pour initialiser la locale de moment
  useEffect(() => {
    setMomentLocale();
    handlePeriodSelectChange(periodSelect[0]);
  }, []);

  // Effet pour gérer le changement de la période sélectionnée
  useEffect(() => {
    if (dateModified) {
      if (!isCustomPeriod && !isSelectModified) {
        setPeriodSelect(["custom"]);
        setIsCustomPeriod(true);
      }
    }
  }, [dateModified, isCustomPeriod, isSelectModified]);
  // Fonction pour déterminer la plage de dat.es en fonction de la période sélectionnée
  function periodRangeSwitch(period) {
    switch (period) {
      case "today":
        return [
          moment().startOf("day").format(formatDate),
          moment().format(formatDate),
        ];

      case "lastDay":
        return [
          moment().subtract(1, "days").startOf("day").format(formatDate),
          moment().subtract(1, "days").endOf("day").format(formatDate),
        ];
      case "lastSevenDays":
        return [
          moment().subtract(6, "days").startOf("day").format(formatDate),
          // on set la date de fin au jour exact lorque futureDateOptimized est false
          moment().endOf("day").format(formatDate),
        ];
      case "currentWeek":
        return [
          moment().startOf("week").format(formatDate),
          // on set la date de fin au jour exact lorque futureDateOptimized est false
          futureDateOptimized
            ? moment().endOf("week").format(formatDate)
            : moment().format(formatDate),
        ];

      case "lastWeek":
        return [
          moment().subtract(1, "weeks").startOf("week").format(formatDate),
          moment().subtract(1, "weeks").endOf("week").format(formatDate),
        ];

      case "currentMonth":
        return [
          moment().startOf("month").format(formatDate),
          futureDateOptimized
            ? moment().endOf("month").format(formatDate)
            : moment().format(formatDate),
        ];

      case "lastMonth":
        return [
          moment().subtract(1, "months").startOf("month").format(formatDate),
          moment().subtract(1, "months").endOf("month").format(formatDate),
        ];

      case "currentYear":
        return [
          moment().startOf("year").format(formatDate),
          futureDateOptimized
            ? moment().endOf("year").format(formatDate)
            : moment().format(formatDate),
        ];

      case "lastYear":
        return [
          moment().subtract(1, "years").startOf("year").format(formatDate),
          moment().subtract(1, "years").endOf("year").format(formatDate),
        ];

      default:
        return [null, null];
    }
  }

  // Fonction pour gérer le changement de la période sélectionnée
  const handlePeriodSelectChange = (value) => {
    setPeriodSelect([value]);
    if (value === "custom") {
      setIsCustomPeriod(true);
    } else {
      setIsCustomPeriod(false);
      const [start, end] = periodRangeSwitch(value);
      setStartDate(start);
      setEndDate(end);
      onValueChange && onValueChange([start, end]);
    }
  };

  return (
    <>
      <Select
        value={periodSelect}
        options={options}
        onSelect={(value) => {
          setIsSelectModified(true);
          setDateModified(false); //réinitialiser à false si la modification provient du Select
          handlePeriodSelectChange(value);
        }}
        selectLabel={t("dates.predifinedPeriod")}
      />
      <RangePicker
        startDate={startDate}
        endDate={endDate}
        onRangeChange={handleRangeChange}
        format={formatDate}
        onDateModifiedChange={onDateModifiedChange}
      />
    </>
  );
}
