import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Form, Input, message } from "antd";
import { create_constraint_service } from "../slices/constraintServiceSlice";
import FormLegend from "../../modUtils/components/FormLegend";
import AlertMessage from "../../modUtils/components/AlertMessage";
import { eEquipmentType } from "ck_commun/src/commun_global/enums.js";
import { Modal, Select, Spinner } from "../../modUtils/components/componentsLibrary/componentsLibrary";

export default function AddServiceConstraintModal({
    isModalVisible,
    setIsModalVisible,
    selectedConfigId
}) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const { groupId } = useSelector((state) => state.manager);
    const [form] = Form.useForm();
    const serviceOptions = [
        { label: t("addServiceConstraintModal.amount"), value: 0 },
        { label: t("addServiceConstraintModal.selectionIdentifier"), value: 1 }
    ];
    const [serviceType, setServiceType] = useState(serviceOptions[0].value);

    // options Select
    const transformedData = Object.entries(eEquipmentType).map(([key, value]) => ({
        label: t(value),
        value: parseInt(key)
    }));

    // Ajout de la première valeur { label: "Tous", value: null }
    transformedData.unshift({ label: t("addFinancialConstraintModal.all"), value: null });

    const handleEquipmentTypeChange = (value) => {
        form.setFieldsValue({ equipmentType: value });
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleOk = () => {
        form.submit();
    };

    const handleSelectServiceType = (selectedValue) => {
        setServiceType(selectedValue);
        form.setFieldsValue({ constraintService: selectedValue });
    };

    const addNewServiceConstraint = async (values) => {
        try {
            setLoading(true);
            await dispatch(
                create_constraint_service({ groupId, configurationId: selectedConfigId, ...values })
            ).unwrap();
        } catch (error) {
            message.error({
                content: (
                    <AlertMessage
                        status={error?.status}
                        alertMessage={`Une erreur est survenue lors de l'ajout d'une nouvelle contrainte de service !`}
                        errorDetail={error?.data?.detail}
                    />
                )
            });
        } finally {
            setLoading(false);
            setIsModalVisible(false);
        }
    };

    useEffect(() => {
        form.resetFields();
        form.setFieldValue('constraintService', serviceType);
    }, [groupId, form]);

    const onFinish = (values) => {
        const formValuesByAmount = {
            equipmentType: values.equipmentType,
            amountMin: values.amountMin,
            amountMax: values.amountMax
        };
        const formValuesBySelectionId = {
            equipmentType: values.equipmentType,
            selectionId: values.selectionId
        };
        const formValues = serviceType === 0 ? formValuesByAmount : formValuesBySelectionId;
        addNewServiceConstraint(formValues);
    };

    // Fonction de validation réutilisable
    const validateAmount = async (value) => {
        const numericValue = parseFloat(value);
        if (isNaN(numericValue)) {
            return Promise.reject(new Error(t("addServiceConstraintModal.invalidAmount")));
        }
        if (numericValue < 0 || numericValue > 9999.99) {
            return Promise.reject(new Error(t("addServiceConstraintModal.maxLengthExceeded")));
        }
        return Promise.resolve();
    };

    return (
        <Modal
            title={t("addServiceConstraintModal.addServiceConstraint")}
            isOpen={isModalVisible}
            onClose={handleCancel}
            onCancel={handleCancel}
            onOk={handleOk}
        >
            <Form form={form} onFinish={onFinish} layout="vertical">
                <div className="flex flex-col items-center">
                    <div className="grid grid-cols-2 gap-0 md:gap-4">
                        <div className="col-span-2 md:col-span-1">
                            <Form.Item
                                label={t("addServiceConstraintModal.equipmentType")}
                                name="equipmentType"
                            >
                                <Select
                                    options={transformedData}
                                    placeholder={t("addFinancialConstraintModal.equipmentType")}
                                    onSelect={handleEquipmentTypeChange}
                                />
                            </Form.Item>
                        </div>

                        <div className="col-span-2 md:col-span-1">
                            <Form.Item
                                label={t("addServiceConstraintModal.serviceConstraintBy")}
                                name="constraintService"
                                rules={[{
                                    required: true,
                                    message: t("addServiceConstraintModal.pleaseSelectServiceConstraintType")
                                }]}
                            >
                                <Select
                                    options={serviceOptions}
                                    onSelect={handleSelectServiceType}
                                    defaultActiveFirstOption={true}
                                    placeholder={t("addServiceConstraintModal.chooseOption")}
                                />
                            </Form.Item>
                        </div>
                    </div>

                    {serviceType === 0 && (
                        <div className="grid grid-cols-2 gap-0 md:gap-4">
                            <div className="col-span-2 md:col-span-1">
                                <Form.Item
                                    label={t("addServiceConstraintModal.minAmountIn")}
                                    name="amountMin"
                                    rules={[
                                        {
                                            required: true,
                                            message: t("addServiceConstraintModal.pleaseEnterMinimumAmount"),
                                        },
                                        {
                                            validator: async (_, value) => {
                                                await validateAmount(value); // Utilisation de la fonction de validation
                                            },
                                        },
                                    ]}
                                >
                                    <Input placeholder="" type="number" step="0.01" />
                                </Form.Item>
                            </div>

                            <div className="col-span-2 md:col-span-1">
                                <Form.Item
                                    label={t("addServiceConstraintModal.maxAmountIn")}
                                    name="amountMax"
                                    dependencies={['amountMin']}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("addServiceConstraintModal.pleaseEnterMaximumAmount"),
                                        },
                                        {
                                            validator: async (_, value) => {
                                                const minValue = parseFloat(form.getFieldValue('amountMin'));
                                                await validateAmount(value); // Appel de la fonction de validation partagée

                                                // Vérification que amountMax est >= à amountMin
                                                const numericValue = parseFloat(value);
                                                if (numericValue < minValue) {
                                                    return Promise.reject(new Error(t("addServiceConstraintModal.maxAmountShouldBeGreaterThanMin")));
                                                }

                                                return Promise.resolve();
                                            },
                                        },
                                    ]}
                                >
                                    <Input placeholder="" type="number" step="0.01" />
                                </Form.Item>
                            </div>
                        </div>
                    )}

                    {serviceType === 1 && (
                        <Form.Item
                            label={t("addServiceConstraintModal.idSelection")}
                            name="selectionId"
                            rules={[
                                {
                                    required: true,
                                    message: t("addServiceConstraintModal.pleaseEnterSelectionId")
                                },
                                {
                                    pattern: /^(\d+\s*,\s*)*\d+$/,  // Valide le format 1, 2, 3 ou 1,2,3
                                    message: t("addServiceConstraintModal.invalidFormat")
                                },
                                {
                                    validator: async (_, value) => {
                                        if (value) {
                                            // Sépare les valeurs par des virgules et convertit en entier
                                            const ids = value.split(",").map((id) => parseInt(id.trim(), 10));
                                            
                                            // Vérifie si le premier identifiant est en dehors de la plage
                                            if (ids.some((id) => isNaN(id) || id < 0 || id > 255)) {
                                                return Promise.reject(
                                                    new Error(t("addServiceConstraintModal.selectionIdOutOfRange"))
                                                );
                                            }
                        
                                            // Vérifie s'il y a des doublons
                                            const uniqueIds = new Set(ids);
                                            if (uniqueIds.size !== ids.length) {
                                                return Promise.reject(
                                                    new Error(t("addServiceConstraintModal.duplicateSelectionId"))
                                                );
                                            }
                                        }
                                        return Promise.resolve();  // Tout est bon
                                    }
                                }
                            ]}
                        >
                            <Input placeholder="1, 2, 3" type="string"/>
                        </Form.Item>
                    )}
                </div>

                <FormLegend requiredFormItemLegend={true}/>
                {loading && <Spinner/>}
            </Form>
        </Modal>
    );
};
