import React, { useEffect, useState } from "react";
import SupportHomeCard from "ck_commun/src/app/modWalletLocal/components/supportHomeCard/SupportHomeCard";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useTranslation } from "react-i18next";

import "./BadgesHome.css";
import BuildingPage from "ck_commun/src/app/modUtils/components/errorPage/BuildingPage";
import i18next from "i18next";

export default function BadgesHome() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const { groupId, loadingGroup } = useSelector((state) => state.manager);
  const periods = [
    t("dates.period_today"),
    t("dates.period_week"),
    t("dates.period_month"),
    t("dates.period_year"),
  ];
  const today = [moment().format("YYYY[m]M[d]D")];
  const currentWeek = [moment().format("YYYY[w]W")];
  const currentMonth = [moment().format("YYYY[m]M")];
  const currentYear = [moment().format("YYYY")];
  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      //appel de l'api badge_cumulative_get pour récupérer les cumules des badges par jour, par semaine, par mois et par année
      // await dispatch(
      //   getBadgesCumule({
      //     groupId,
      //     day: JSON.stringify(today),
      //     month: JSON.stringify(currentWeek),
      //     week: JSON.stringify(currentMonth),
      //     year: JSON.stringify(currentYear),
      //   })
      // );
      setLoading(false);
    }
    if (groupId) {
      fetchData();
    }
  }, [groupId, i18next.language]);
  return (
    <BuildingPage />
    //     <>
    //   <div className="flex-display flex-row justify-content-center" >
    //       <div className='info-box badgeInfoBox'>
    //         <div className='info-box-icon badgeInfoBoxIcon' style={{background: '#1B8ADC'}}>
    //          <img src={process.env.PUBLIC_URL+"/img/badgesIcons/euro.svg"}/>
    //         </div>
    //         <div className='info-box-content badgeInfoBoxContent'>
    //           <div className='info-box-label'><div>crédit</div><div>badges</div></div>
    //           <div className='info-box-amount badgeInfoBoxAmount'>621 €</div>
    //           <div className='info-box-comment'>dont <span style={{fontWeight: 'bold'}}>130 €</span> non utilisé de + 1 an</div>
    //         </div>
    //       </div>
    // {/** _______________*/}
    //       <div className='info-box badgeInfoBox'>
    //         <div className='info-box-icon badgeInfoBoxIcon' style={{background: '#11CDEF'}}>
    //         <img src={process.env.PUBLIC_URL+"/img/badgesIcons/badge.svg"}/>
    //         </div>
    //         <div className='info-box-content badgeInfoBoxContent'>
    //           <div className='info-box-label'><div>badges</div> <div>en circulation</div></div>
    //           <div className='info-box-amount badgeInfoBoxAmount'>621</div>
    //           <div className='info-box-comment'>dont <span style={{fontWeight: 'bold'}} >130</span> badges orphelins</div>
    //         </div>
    //       </div>
    //    </div>
    //     <div className='APP_h1_text'>Activité des badges d'abonnement</div>

    //     <div className="flex-display flex-row justify-content-center" >
    //      {
    //         periods?.map((period, index) => {
    //           return(
    //           <SupportHomeCard title={period} indexOfPeriod={index}/>)
    //          })
    //       }
    //     </div>

    //     </>
  );
}
