import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { message, Checkbox } from "antd";
import { eEquipmentType } from "../../../commun_global/enums";
import { ReactComponent as DeleteIcon } from "../../modEntity/assets/images/trash-can.svg";
import AddServiceConstraintModal from "./AddServiceConstraintModal";
import { update_configuration_detail_by_profil } from "../slices/configurationSlice";
import { get_constraint_service_paginated, delete_constraint_service_detail} from "../slices/constraintServiceSlice";
import AlertMessage from "../../modUtils/components/AlertMessage";
import { amountFormat } from "../../modUtils/functions/numberFormat";
import ButtonAdd from "../../modUtils/components/buttons/ButtonAdd";
import { Spinner, Table } from "../../modUtils/components/componentsLibrary/componentsLibrary";

export default function ServiceConstraints({
    selectedProfilId,
    selectedConfigId,
    configuration,
    getConfigurationDetail
}) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const { groupId } = useSelector((state) => state.manager);
    const { constraintsService } = useSelector((state) => state.profil.constraintService);
    const [isServiceConstraintActive, setIsServiceConstraintActive] = useState(configuration?.constraintservice_is_active);
    const [isAddServiceConstraintModalVisible,setIsSetServiceConstraintModalVisible] = useState(false);

    // récupérer les contraintes de service
    const getConstraintService = async () => {
        try {
            setLoading(true);
            await dispatch(
                get_constraint_service_paginated({
                    groupId,
                    configurationId: selectedConfigId,
                    limit: 100,
                    offset: 0
                })
            ).unwrap();
        } catch (error) {
            message.error({
                content: (
                    <AlertMessage
                        status={error?.status}
                        alertMessage={`Une erreur est survenue lors de la récupération des contraintes de service !`}
                        errorDetail={error?.data?.detail}
                    />
                )
            });
        } finally {
            setLoading(false);
        }
    };

    // supprimer une contrainte de service
    const deleteConstraintService = async (constraintId) => {
        try {
            await dispatch(
                delete_constraint_service_detail({
                    groupId,
                    configurationId: selectedConfigId,
                    constraintId
                })
            ).unwrap();
        } catch (error) {
            message.error({
                content: (
                    <AlertMessage
                        status={error?.status}
                        alertMessage={`Une erreur est survenue lors de la suppression de la contrainte de service!`}
                        errorDetail={error?.data?.detail}
                    />
                )
            });
        }
    };

    useEffect(() => {
        groupId && selectedConfigId && getConstraintService();
    }, [groupId, selectedConfigId]);

    const columns = [
        {
            title: "serviceConstraints.equipmentType",
            dataIndex: "equipment_type",
            key: "equipment_type",
            align:'left',
            width: "25%",
            render: (text, record) => <div className="text-center">{text === null ? "Tous" : t(eEquipmentType[text])}</div>
        },
        {
            title: "serviceConstraints.selectionBy",
            dataIndex: "selection_id",
            key: "selection_type",
            width: "25%",
            render: (text, record) =>
                record.selection_ids ? "Id" : "Montant Min/Max"
        },
        {
            title: "serviceConstraints.minIn",
            dataIndex: "amount_min",
            key: "amount_min",
            width: "15%",
            render: (text, record) => (text ? amountFormat(text) : "-")
        },
        {
            title: "serviceConstraints.maxIn",
            dataIndex: "amount_max",
            key: "amount_max",
            width: "15%",
            render: (text, record) => (text ? amountFormat(text) : "-")
        },
        {
            title: "serviceConstraints.selectionNumber",
            dataIndex: "selection_ids",
            key: "selection_ids",
            width: "15%",
            render: (text, record) => (text ? text.join(", ") : "-")
        },
        ...(configuration.is_future
            ? [
                {
                    title: "",
                    dataIndex: "id",
                    key: "id",
                    width: "5%",
                    render: (text) => (
                        <div
                            className="w-[15px] m-auto cursor-pointer"
                            onClick={() => deleteConstraintService(text)}
                        >
                            <DeleteIcon/>
                        </div>
                    )
                }
            ]
            : [])
    ];

    // appelé au changement de checkbox pour activer ou désactiver les contraintes financières
    const handleOnChangeCheckbox = async () => {
        setIsServiceConstraintActive(!isServiceConstraintActive);
        try {
            await dispatch(
                update_configuration_detail_by_profil({
                    groupId,
                    profilId: selectedProfilId,
                    configurationId: selectedConfigId,
                    constraintsServiceIsActive: !isServiceConstraintActive
                })
            ).unwrap();
        } catch (error) {
            message.error({
                content: (
                    <AlertMessage
                        status={error?.status}
                        alertMessage={`Une erreur est survenue lors de la (dé)activation des contraintes de service !`}
                        errorDetail={error?.data?.detail}
                    />
                )
            });
        }
        getConfigurationDetail(selectedProfilId, selectedConfigId);
    };

    return (
        <>
        <div className="flex items-center justify-center">
            {configuration?.is_future == true && (
                <>
                <div className="pb-2">
                    <Checkbox
                        checked={isServiceConstraintActive}
                        onChange={handleOnChangeCheckbox}
                    >
                        
                    </Checkbox>
                </div>

                <span className="text-primary-color font-bold ml-2">
                    {t("serviceConstraints.enableServiceConstraints")}
                </span>
                </>
            )}
        </div>

        {isServiceConstraintActive &&
            <>
            <div className="text-center font-bold text-sm my-6">
                {t("serviceConstraints.serviceConstraints")}
            </div>

            {configuration?.is_future == true && (
                <ButtonAdd
                    label={t("serviceConstraints.addServiceConstraint")}
                    className="mb-4"
                    onClick={() => setIsSetServiceConstraintModalVisible(true)}
                />
            )}
            {loading ? <Spinner /> : (
                <Table columns={columns} dataSource={constraintsService}/>
            )}
        
            {isAddServiceConstraintModalVisible && (
                <AddServiceConstraintModal
                    isModalVisible={isAddServiceConstraintModalVisible}
                    setIsModalVisible={setIsSetServiceConstraintModalVisible}
                    selectedConfigId={selectedConfigId}
                />
            )}
            </>
        }
        </>
    );
};
