import React from "react";
import EntityGroupForm from "./EntityGroupForm";
import EntityCustomerCompanyForm from "./EntityCustomerCompanyForm";

export default function EntityInformations({ entity, getEntities, fetchUpdateEntityCustomer, fetchUpdateEntityCompany }) {

    return (
        <div className="flex flex-col justify-center items-center mx-20 xl:mx-10 sm:mx-0">
            {entity?.entity_type === "EntityGroup" ? (
                <EntityGroupForm 
                    entity={entity}
                    fetchEntities={getEntities}
                />
            ) : (
                (entity?.entity_type == "EntityCustomer" || entity?.entity_type == "EntityCompany") && (
                    <EntityCustomerCompanyForm
                        showSelectEntityType={false}
                        entity={entity}
                        getEntities={getEntities}
                        fetchUpdateEntityCustomer={fetchUpdateEntityCustomer}
                        fetchUpdateEntityCompany={fetchUpdateEntityCompany}
                    />
                )
            )}
        </div>
    );
};
