import React from "react";
import { useTranslation } from "react-i18next";
import EntityNodeActionsMenu from "../EntityNodeActionsMenu";
import CKToolTip from "../../../modUtils/components/CKToolTip/CKToolTip";
import { ReactComponent as CheckSvg } from "../../assets/images/check.svg";
import { ReactComponent as NotCheck } from "../../assets/images/notCheck.svg";
import { ReactComponent as SwitcherSvg } from "../../assets/images/arrow.svg";
import { ReactComponent as Hourglass } from "../../assets/images/hourglass.svg";
import { ReactComponent as ChainonSvg } from "../../assets/images/chainon-red.svg";
import { ReactComponent as EntityCustomerIcon } from "../../assets/images/user-blue.svg";
import { ReactComponent as EntityCompanyIcon } from "../../assets/images/company-building.svg";
import { ReactComponent as EntityGroupIcon } from "../../assets/images/users-three-bg-white.svg";
import "./EntityNode.scss";

export default function EntityNode({ entityData, saveSelectedEntityCallback, supportId, getEntities }) {
    const { t } = useTranslation();
    const entityType = entityData?.entity_type;
    
    const getIcon = () => {
        let icon;

        if (entityType === "EntityCompany") {
            icon = <EntityCompanyIcon/>
        }else if (entityType === "EntityGroup") {
            icon = <EntityGroupIcon/>
        }else if (entityType === "EntityCustomer") {
            icon = <EntityCustomerIcon/>
        }

        return icon;
    }

    // TODO CH FONCTION POSSIBLE DANS LE MOD UTILE 
    const toPascalCase = (str) => {
        if (!str) return '';  // Si la chaîne est vide ou null, retourner une chaîne vide
        return str
            .toLowerCase()
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };

    const getstatusContent = () => {
        let content;

        if (entityData?.status == 1) {
            content = t("entityNode.blocked");
        } else if (entityData?.status == 0) {
            content = t("entityNode.active");
        } else if (entityData?.status == 3) {
            content = t("entityNode.onHold");
        }

        return content;
    };

    const getstatusIcon = () => {
        let icon;

        if (entityData?.status == 1) {
            icon =  <NotCheck/>;
        } else if (entityData?.status == 0) {
            icon =  <CheckSvg/>;
        } else if (entityData?.status == 3) {
            icon = <Hourglass/>;
        }

        return icon;
    };
          
    return (
        <div className="w-full flex items-center">
            <div
                onClick={() => entityType !== "EntityFolder" && saveSelectedEntityCallback(entityData)}
                key={entityData?.id}
                className={`entity-tree-node font-bold w-full p-2 mb-1.5 border-solid rounded-md 
                    ${entityType === "EntityFolder" ? "border-0 fixed top-[120px] bottom-[40px] !w-[250px]" : "border-2"} 
                    ${entityType === "EntityCompany" ? "min-w-[250px] max-w-[250px]" : 
                        entityType === "EntityGroup" ? "min-w-[240px] max-w-[240px]" : 
                        entityType === "EntityCustomer" ? "min-w-[230px] max-w-[230px]" : ""} 
                    ${entityData?.status === 2 
                        ? "text-neutral-color-400 bg-neutral-color-100 border-neutral-color-200" 
                        : `border-[#5369804a] ${
                            entityType === "EntityCustomer" 
                                ? "text-primary-color" 
                                : (entityType === "EntityGroup" || entityType === "EntityCompany") 
                                    ? "text-white bg-primary-color" 
                                    : ""}`
                    }
                `}
            >
                <div className="pl-3.5 flex items-center justify-between">
                    <div className="flex items-center line-clamp-1 mr-2">
                        <div className="mr-2">{getIcon()}</div>
                        
                        {entityType !== "EntityFolder" &&
                            <div className="w-[140px] truncate">
                                {toPascalCase(entityData?.name)}
                                {entityData?.first_name && " - " + toPascalCase(entityData?.first_name)}</div>
                        }
                    
                        {entityData?.show_unlinked === true && (
                            <div className="mx-1 flex items-center h-6">
                                <CKToolTip
                                    placement="topLeft"
                                    content={t("entityNode.noUsersAssociated")}
                                    trigger="click"
                                >
                                    <ChainonSvg/>
                                </CKToolTip>
                            </div>
                        )}
                        
                        <div className="mx-1 flex items-center h-6" onClick={(event) => event.stopPropagation()}>
                            <CKToolTip
                                placement="topLeft"
                                content={getstatusContent()}
                                trigger="click"
                            >
                                {getstatusIcon()}
                            </CKToolTip>
                        </div>

                        {entityData?.childs?.length > 0 && (
                            <div className="node-switcher-icon cursor-pointer ml-1">
                                <SwitcherSvg/>
                            </div>
                        )}
                    </div>
                </div>

                {/**début affichage profil*/}
                {entityData?.profil && (
                    <div
                        id={`profil-${entityData?.id}-${entityData?.profil?.id}`}
                        className={`
                            min-w-[210px] max-w-[210px] entity-profil rounded-md px-3.5 py-2 mt-2
                            flex items-center text-xs text-ellipsis overflow-hidden whitespace-nowrap
                        `}
                    >
                        <div className="mr-2">
                            <img
                                src={entityData?.profil?.service_type_image}
                                alt={entityData?.profil?.service_type_label}
                            />
                        </div>

                        <div key={entityData?.profil?.id}>{entityData?.profil?.name}</div>
                    </div>
                )}
                {/**fin affichage profil*/}
            </div>

            <div lassName="ml-2">
                {(entityType === "EntityGroup" || entityType === "EntityCompany") && (
                    <EntityNodeActionsMenu
                        entityData={entityData}
                        supportId={supportId}
                        getEntities={getEntities}
                    />
                )}
            </div>
        </div>
    );
};
