import React from "react";

export default function CardOutlineIcon({
    active,
    color,
    label,
    iconDefault,
    iconIsActive,
    onClick,
    width,
    height,
    cornerIcon,
    disabled
}) {
    const cardWidth = "115px" || width;
    const cardHeight = cardWidth || height;
    const disabledBgColor = "#D5D5D5";
    const cardBgColor =  active && !disabled ? color : active && disabled ? disabledBgColor : 'transparent';

    const buttonStyle = {
        width: cardWidth,
        height: cardHeight,
        color: active || disabled ? "#ffff" : color,
        backgroundColor: cardBgColor,
        borderColor: disabled ? disabledBgColor : color
    };

    return (
        <div className="p-3 w-fit h-fit">
            <div className="relative inline-block w-fit">
                {cornerIcon && (
                    <div className="absolute fit-content top-0 right-0 transform translate-x-1/2 -translate-y-1/2">
                        {cornerIcon}
                    </div>
                )}

                <div
                    style={{ ...buttonStyle }}
                    onClick={onClick}
                    className="flex flex-col items-center justify-around capitalize rounded-lg border-2 border-solid"
                >
                    <div>{iconIsActive && active ? iconIsActive : iconDefault}</div>
                    <div>{label}</div>
                </div>
            </div>
        </div>
    );
};
