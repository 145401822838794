import React, { useEffect, useRef, useCallback } from "react";
import { Input } from "../componentsLibrary/componentsLibrary";

export default function SearchInput({ id, placeholder, label, value, onChange, fetchPressEnter }) {
    const inputValueRef = useRef(value); // Stocke la valeur actuelle de l'input
    const lastSentValueRef = useRef(null); // Utiliser useRef pour stocker la dernière valeur envoyée

    // Met à jour la référence chaque fois que la valeur change
    useEffect(() => {
        inputValueRef.current = value;
    }, [value]);

    // Fonction qui gère l'événement "keydown"
    const handleKeyPress = useCallback((event) => {
        if (event.key === 'Enter') {
            // Vérifie si la valeur a changé avant d'appeler fetchPressEnter
            if (inputValueRef.current !== lastSentValueRef.current) {
                fetchPressEnter && fetchPressEnter(inputValueRef.current);  // Appelle l'API avec la nouvelle valeur
                lastSentValueRef.current = inputValueRef.current;  // Met à jour la dernière valeur envoyée
            }
        }
    }, []);

    // Ajoute l'écouteur de touches quand l'input a le focus
    const handleFocus = useCallback(() => {
        window.addEventListener('keydown', handleKeyPress);
    }, [handleKeyPress]);

    // Supprime l'écouteur quand l'input perd le focus
    const handleBlur = useCallback(() => {
        window.removeEventListener('keydown', handleKeyPress);
    }, [handleKeyPress]);

    // Nettoyage à la fin du cycle de vie du composant
    useEffect(() => {
        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, [handleKeyPress]); // Assure que handleKeyPress est correctement nettoyé

    return (
        <Input
            id={id}
            value={value}
            placeholder={placeholder ? placeholder : "rechercher"}
            label={label}
            onChange={onChange}
            icon={process.env.PUBLIC_URL + "/img/design/zoom.svg"}
            onFocus={handleFocus}   // Active l'écouteur au focus
            onBlur={handleBlur}     // Désactive l'écouteur au blur
        />
    );
};
