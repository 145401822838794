import React from 'react';
import { useTranslation } from "react-i18next";

export default function Label({label, className=""}) {
    const { t } = useTranslation();

    return (
        <label className={`font-black ${className}`}>{t(label)}</label>
    );
};
