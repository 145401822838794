import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { httpGet } from "../../modUtils/apis/httpConfig";


//récupérer les des transactions par saleId
export const get_transaction_sale_paginated_get = createAsyncThunk(
  "mod_sale/get_transaction_sale_paginated_get",
  async (values, { rejectWithValue }) => {
    try {
      const response = await httpGet(
        `/mod_banking/groups/${values.groupId}/sales/${values.saleId}/transactions`
        ////{ params: { limit: 10, offset: 0 },}
      );
      return response.data;
    } catch (error) {
      return rejectWithValue({
        status: error.response.status,
        data: error.response.data,
      });
    }
  }
);
//export tellecollects by group
export const export_tellecollects_by_group_export_get = createAsyncThunk(
  "mod_banking/export_tellecollects_by_group_export_get",
  async (values, { rejectWithValue }) => {
    try {
      const response = await httpGet(
        `/mod_banking/exports/${values.fileFormat}/groups/${values.groupId}/telecollects/`,
        {
          params: {
            date_start: values.dateStart,
            date_end: values.dateEnd,
            sites: values.sites ? values.sites : null,
            equipment_type: values.equipmentType ? values.equipmentType : null,
            app: values.app,
            remittance: values.remittance,
            amount: values.amount,
            ordering: values.ordering,
            sequence_number: values.sequenceNumber,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue({
        status: error.response.status,
        data: error.response.data,
      });
    }
  }
);

//récupérer télécollectes par groupId
export const get_group_telecollects_paginated = createAsyncThunk(
  "mod_banking/get_group_telecollects_paginated",
  async (values, { rejectWithValue }) => {
    try {
      const response = await httpGet(
        `mod_banking/groups/${values.groupId}/telecollects`,
        {
          params: {
            limit: values.limit,
            offset: values.offset,
            date_start: values.dateStart,
            date_end: values.dateEnd,
            sites: values.sites ? values.sites : null,
            equipment_type: values.equipmentType ? values.equipmentType : null,
            app: values.app,
            remittance: values.remittance,
            amount: values.amount,
            ordering: values.ordering,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue({
        status: error.response.status,
        data: error.response.data,
      });
    }
  }
);

//récupérer télecollecte par telecollecteId
export const get_telecollect = createAsyncThunk(
  "mod_banking/get_telecollect",
  async (values, { rejectWithValue }) => {
    try {
      const response = await httpGet(
        `mod_banking/groups/${values.groupId}/telecollects/${values.telecollectId}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue({
        status: error.response.status,
        data: error.response.data,
      });
    }
  }
);

const bankingSlice = createSlice({
  name: "banking",
  initialState: {
    saleTransactions: [],
    telecollects: [],
    telecollectsCount: 0,
    loading: true,
    telecollectDetail: {},
  },
  extraReducers: (builder) => {
    builder
      //récupérer paiement infos CB par saleId
      .addCase(get_transaction_sale_paginated_get.pending, (state, action) => {
        state.saleTransactions = [];
      })
      .addCase(
        get_transaction_sale_paginated_get.fulfilled,
        (state, action) => {
          state.saleTransactions = action.payload.results;
          state.loading = false;
        }
      )
      .addCase(get_transaction_sale_paginated_get.rejected, (state, action) => {
        state.loading = false;
      })
      /* get_group_telecollects_paginated */
      .addCase(get_group_telecollects_paginated.pending, (state, action) => {
        state.telecollects = [];
        state.telecollectsCount = 0;
      })
      .addCase(get_group_telecollects_paginated.fulfilled, (state, action) => {
        state.telecollects = action.payload.results;
        state.telecollectsCount = action.payload.count;
        state.loading = false;
      })
      .addCase(get_group_telecollects_paginated.rejected, (state, action) => {
        state.loading = false;
      })
      /* get_telecollect */
      .addCase(get_telecollect.fulfilled, (state, action) => {
        state.telecollectDetail = action.payload;
        state.loading = false;
      })
      .addCase(get_telecollect.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(
        export_tellecollects_by_group_export_get.fulfilled,
        (state, action) => {
          state.telecollects = action.payload;
          state.loading = false;
        }
      )
      .addCase(
        export_tellecollects_by_group_export_get.rejected,
        (state, action) => {
          state.loading = false;
        }
      );
  },
});

export default bankingSlice.reducer;
