import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Form, Input, Radio, Select, message } from "antd";
import {
  create_profil,
  get_profil_detail,
  update_profil_detail,
} from "../slices/profilSlice";
import FormLegend from "../../modUtils/components/FormLegend";
import AlertMessage from "../../modUtils/components/AlertMessage";
import {
  Modal,
  Button,
  Spinner,
} from "../../modUtils/components/componentsLibrary/componentsLibrary";

const { Option } = Select;

export default function AddProfilModal({
  selectedProfilId,
  isModalVisible,
  setIsModalVisible,
}) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { groupId } = useSelector((state) => state.manager);
  const { profilDetail } = useSelector((state) => state.profil.profil);
  const [isPublicSelectedOption, setIsPublicSelectedOption] = useState();
  const [isActiveSelectedOption, setIsActiveSelectedOption] = useState();
  const isEdit = !!selectedProfilId;
  // TODO CH TRADUIRE
  const serviceTypeOptions = [
    { label: t("addProfilModal.subscription"), value: 0 },
    // { label: "Porte monnaie électronique", value: 1 },
    // { label: "Facturation", value: 2 },
    // { label: "Tarif préferentiel", value: 3 }
  ];

  // TODO CH TRADUIRE
  const isPublicOptions = [
    { label: t("profilDetails.public"), value: true },
    { label: t("profilDetails.private"), value: false },
  ];

  // TODO CH TRADUIRE
  const isActiveOptions = [
    { label: t("addProfilModal.isActiveTrue"), value: true },
    { label: t("addProfilModal.isActiveFalse"), value: false },
  ];

  // TODO CH TRADUIRE
  const serviceTypeInfo = (
    <ul>
      <li>
       {t("addProfilModal.serviceInfo1")}
      </li>
      <li>
      {t("addProfilModal.serviceInfo2")}
      </li>
      <li>
      {t("addProfilModal.serviceInfo3")}
      </li>
      <li>
      {t("addProfilModal.serviceInfo4")}
      </li>
    </ul>
  );

  // TODO CH TRADUIRE
  const profilInfo = (
    <ul>
      <li> {t("addProfilModal.profilInfoPrivate")}</li>
      <li>{t("addProfilModal.profilInfoPublic")}</li>
    </ul>
  );
  const getProfilDetail = async () => {
    setLoading(true);
    try {
      await dispatch(
        get_profil_detail({ groupId, profilId: selectedProfilId })
      ).unwrap();
    } catch (error) {
      message.error({
        content: (
          <AlertMessage
            status={error?.status}
            alertMessage={t("addProfilModal.error")}
            errorDetail={error?.data?.detail}
          />
        ),
      });
    } finally {
      setLoading(false);
    }
  };

  // modifier un profil
  const updateProfilDetail = async (values) => {
    setLoading(true);
    try {
      const response = await dispatch(
        update_profil_detail({ groupId, profilId: selectedProfilId, ...values })
      ).unwrap();
      console.log(response);
    } catch (error) {
      console.log(error);
      message.error({
        content: (
          <AlertMessage
            status={error?.status}
            alertMessage={t("addProfilModal.updateError")}
            errorDetail={error?.data?.detail}
          />
        ),
      });
    } finally {
      setLoading(false);
      setIsModalVisible(false);
    }
  };

  // ajouter un profil
  const addNewProfil = async (values) => {
    setLoading(true);
    try {
      const response = await dispatch(
        create_profil({ groupId, ...values })
      ).unwrap();
      if (response) {
        setIsModalVisible(false);
      }
    } catch (error) {
      message.error({
        content: (
          <AlertMessage
            status={error?.status}
            alertMessage={t("addProfilModal.addError")}
            errorDetail={error?.data?.detail}
          />
        ),
      });
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    form.resetFields();
    setIsModalVisible(false);
  };

  useEffect(() => {
    if (isEdit && groupId && selectedProfilId !== profilDetail?.id) {
      getProfilDetail();
    }
  }, [groupId, selectedProfilId]);

  useEffect(() => {
    form.resetFields();
    if (isEdit && profilDetail) {
      setIsPublicSelectedOption(profilDetail?.is_public);
      setIsActiveSelectedOption(profilDetail?.is_active);
      form.setFieldsValue({
        name: profilDetail.name || "",
        serviceType: profilDetail?.service_type || null,
        isPublic: profilDetail?.is_public,
        isActive: profilDetail?.is_active,
      });
    }
  }, [profilDetail, selectedProfilId, form]);

  // submit formulaire
  const onFinish = (values) => {
    if (isEdit) {
      let editValues = {
        ...values,
        isPublic: values.isPublic,
        isActive: values.isActive,
      };
      updateProfilDetail(editValues);
    } else {
      let addValues = { ...values, isPublic: isPublicSelectedOption };
      addNewProfil(addValues);
    }
  };

  // select isPublic
  const handleSelectIsPublic = (value) => {
    setIsPublicSelectedOption(value);
    form.setFieldValue("isPublic", value);
  };

  // select isActive
  // const handleSelectIsActive = (value) => {
  //     setIsActiveSelectedOption(value);
  //     form.setFieldValue("isActive",value)
  // };

  return (
    <Modal
      title={
        selectedProfilId
          ? t("addProfilModal.editProfile")
          : t("addProfilModal.createNewProfile")
      }
      isOpen={isModalVisible}
      onClose={handleCancel}
      onCancel={handleCancel}
      onOk={() => form.submit()}
    >
      <Form form={form} onFinish={onFinish} layout="vertical">
        <Form.Item
          label={t("addProfilModal.profileName")}
          name="name"
          rules={[
            {
              required: true,
              message: t("addProfilModal.pleaseEnterProfileName"),
            },
          ]}
        >
          <Input placeholder={t("addProfilModal.profileName")} />
        </Form.Item>

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-0 sm:gap-4">
          {!isEdit && (
            <Form.Item
              label={t("addProfilModal.profileType")}
              tooltip={serviceTypeInfo}
              name="serviceType"
              rules={[
                {
                  required: true,
                  message: t("addProfilModal.PleaseChooseProfileType"),
                },
              ]}
            >
              <Select placeholder={t("addProfilModal.chooseType")}>
                {serviceTypeOptions?.map((type) => (
                  <Option key={`service-type-${type.value}`} value={type.value}>
                    {type.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}

          <Form.Item
            label={t("addProfilModal.profile")}
            name="isPublic"
            tooltip={profilInfo}
            rules={[
              {
                required: true,
                message: t("addProfilModal.pleaseChooseOption"),
              },
            ]}
          >
            <Radio.Group value={isPublicSelectedOption}>
              {isPublicOptions?.map((type) => (
                <Button
                  key={type.value}
                  value={type.value}
                  type={
                    type.value == isPublicSelectedOption ? "primary" : "default"
                  }
                  htmltype={"button"}
                  onClick={() => handleSelectIsPublic(type.value)}
                  className={"w-24 mr-2 mb-2 sm:mb-0"}
                >
                  {type.label}
                </Button>
              ))}
            </Radio.Group>
          </Form.Item>

          {/* TODO CH ici on modifi isActive du profil */}
          {/* pas utilisé pour le moment */}
          {/* {isEdit && (
                        <Form.Item
                        label={t("addProfilModal.active")}
                        name="isActive"
                        rules={[{
                            required: true,
                            message: t("addProfilModal.pleaseChooseOption"),
                        }]}
                        >
                            <Radio.Group value={isActiveSelectedOption}>
                                {isActiveOptions?.map((type) => (
                                    <Button
                                        id={type.value}
                                        value={type.value}
                                        type={
                                        type.value == isActiveSelectedOption
                                            ? "primary"
                                            : "default"
                                        }
                                        htmltype={"button"}
                                        onClick={() => handleSelectIsActive(type.value)}
                                        className={"w-24 mr-2 mb-2 sm:mb-0"}
                                    >
                                        {type.label}
                                    </Button>
                                ))}
                            </Radio.Group>
                        </Form.Item>
                    )} */}
        </div>
      </Form>

      <FormLegend requiredFormItemLegend={true} />
      {loading && <Spinner />}
    </Modal>
  );
}
