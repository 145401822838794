import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { httpGet } from "ck_commun/src/app/modUtils/apis/httpConfig";

// support centralisé
export const support_centralized_paginated_get = createAsyncThunk(
    "mod_profil/support_centralized_paginated_get",
    async (values, {rejectWithValue}) => {
        try {
            const response = await httpGet(
                `mod_support_centralized/groups/${values.groupId}/supports_centralized/`,
                {
                    params: {
                        group_id: values.groupId,
                        support_format: values.supportFormat,
                        entity_id: values.entityId,
                        ordering: values.ordering,
                        limit: values.limit,
                        offset: values.offset
                    }
                }
            );
            return response.data;
        } catch (error) {
            return rejectWithValue({
                status: error.response.status,
                data: error.response.data
            });
        }
    }
);

// transactions centralisées par groupe
export const transactions_centralized_by_group_paginated_get = createAsyncThunk(
    "mod_profil/transactions_centralized_by_group_paginated_get",
    async (values, {rejectWithValue}) => {
        try {
            const response = await httpGet(
                `mod_support_centralized/groups/${values.groupId}/transactions_centralized/`,
                {
                    params: {
                        group_id: values.groupId,
                        support_id: values.supportId,
                        entity_id: values.entityId,
                        content_type: values.contentType,
                        object_id: values.objectId,
                        ordering: values.ordering,
                        limit: values.limit,
                        offset: values.offset
                    }
                }
            );
            return response.data;
        } catch (error) {
            return rejectWithValue({
                status: error.response.status,
                data: error.response.data
            });
        }
    }
);

const supportCentralizedSlices = createSlice({
    name: "supportCentralized",
    initialState: {
        loading: true,
        supportCentralized: [],
        supportCentralizedCount: 0,
        transactionsCentralized: []
    },
    extraReducers: (builder) => {
        builder
            /* support_centralized_paginated_get */
            .addCase(support_centralized_paginated_get.pending, (state, action) => {
                state.supportCentralized = [];
            })
            .addCase(support_centralized_paginated_get.fulfilled, (state, action) => {
                state.loading = false;
                state.supportCentralized = action.payload;
                state.supportCentralizedCount = action.payload.count;
            })
            .addCase(support_centralized_paginated_get.rejected, (state, action) => {
                state.loading = false;
            })

            /* transactions_centralized_by_group_paginated_get */
            .addCase(transactions_centralized_by_group_paginated_get.pending, (state, action) => {
                state.transactionsCentralized = [];
            })
            .addCase(transactions_centralized_by_group_paginated_get.fulfilled, (state, action) => {
                state.loading = false;
                state.transactionsCentralized = action.payload;
            })
            .addCase(transactions_centralized_by_group_paginated_get.rejected, (state, action) => {
                state.loading = false;
            })
    }
});

export default supportCentralizedSlices.reducer;
