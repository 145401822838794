import React, { useState } from "react";
import SaleDetailsModal from "../saleDetailsModal/SaleDetailsModal";
import DropdownActionsMenu from "../../../modUtils/components/dropdownActionsMenu/DropdownActionsMenu";
import AdminLink from "../../../modUtils/components/adminLink/AdminLink";
import { useTranslation } from "react-i18next";
export default function SaleActionsMenu({ sale }) {
  const { t } = useTranslation();
  const [isActionMenuVisible, setIsActionMenuVisible] = useState(false);
  const [isSaleDetailsModalVisible, setIsSaleDetailsModalVisible] =
    useState(false);
  const closeMenu = () => {
    setIsActionMenuVisible(false);
  };

  const handleVisibleChange = (newVisible) => {
    setIsActionMenuVisible(newVisible);
  };

  const menuItems = [
    <p onClick={() => setIsSaleDetailsModalVisible(true)}>{t("saleActionMenu.detail")}</p>,
    <p className="disabled">{t("saleActionMenu.sendTicket")}</p>,
    <p className="disabled">{t("saleActionMenu.refund")}</p>,
    <AdminLink
      adminPath={`mod_sale/sale/${sale.id}/change/`}        
      onClick={closeMenu}
    />
  ];
  return (
    <>
      <DropdownActionsMenu
        menuItems={menuItems}
        handleVisibleChange={handleVisibleChange}
        visible={isActionMenuVisible}
      />
      {isSaleDetailsModalVisible && (
        <SaleDetailsModal
          saleId={sale.id}
          closeMenu={closeMenu}
          closeModal={() => setIsSaleDetailsModalVisible(false)}
        />
      )}
    </>
  );
}
