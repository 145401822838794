import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Pagination from "../pagination/Pagination";
import { useTranslation } from "react-i18next";
import "./Table.scss";


const Table = ({
  stripped,
  dataSource,
  columns,
  rowSelection,
  rowClassName,
  onRow,
  onRowClick,
  style = {},
  scroll, 
  pagination,
  expandableRow,
  onScroll,
  tableClassName
}) => {
  const { t } = useTranslation();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const { onChange, getCheckboxProps } = rowSelection || {};

  const emptyRowCount = Math.max(0, scroll?.y - dataSource?.length);

    // Créer un tableau de lignes vides
    const emptyRows = Array.from({ length: emptyRowCount }, (_, index) => (
      <tr key={`empty-row-${index}`} style={{ height: 50 }}>
        <td colSpan={columns.length + (rowSelection ? 1 : 0)}>
          {/* Cellule vide */}
        </td>
      </tr>
    ));
    
  const handleRowClick = (event, rowId) => {
    event.stopPropagation();

    if (onRowClick) {
      onRowClick(rowId);
    }
    const targetTagName = event.target.tagName.toLowerCase();
    if (targetTagName === "input" && event.target.type === "checkbox") {
      const index = selectedRowKeys.indexOf(rowId);
      if (index > -1) {
        setSelectedRowKeys([
          ...selectedRowKeys.slice(0, index),
          ...selectedRowKeys.slice(index + 1),
        ]);
      } else {
        setSelectedRowKeys([...selectedRowKeys, rowId]);
      }
    } else if (onRow) {
      onRow.onClick(event, rowKey)
    }
  };

  const checkboxProps = (record) => {
    const props = getCheckboxProps ? getCheckboxProps(record) : {};
    return {
      disabled: props.disabled,
      checked: selectedRowKeys.includes(record.id),
      onChange: (event) => {
        event.stopPropagation();
        handleRowClick(event, record.id);
      },
      ...props,
    };
  };


  if (onChange) {
    onChange(
      selectedRowKeys,
      dataSource?.filter((row) => selectedRowKeys.includes(row.id))
    );
  }

  const getRowProps = (record, rowIndex) => {
    // Utilisez rowClassName s'il est défini, sinon utilisez une chaîne vide
    const className = rowClassName ? rowClassName(record, rowIndex) : '';
    
    if (onRow) {
      return {
        ...onRow(record, rowIndex),
        className: `${record.className || ''} ${className}`, // Ajoutez rowClassName à la classe existante
      };
    }
    return {
      className: `${record.className || ''} ${className}`, // Ajoutez rowClassName à la classe existante
    };
  };
  const handleTableScroll = (event) => {
    if (onScroll) {
      onScroll(event); // Appel de la fonction de rappel onScroll lors du défilement
    }
  };
  //check All Rows
  const handleCheckAll = (event) => {
    const checked = event.target.checked;
    const allRowKeys = dataSource?.map((row) => row.id);

    if (checked) {
      setSelectedRowKeys(allRowKeys);
    } else {
      setSelectedRowKeys([]);
    }
  };
  return (
    <div
      className={`overflow-x-auto${
        scroll && dataSource?.length > 0 ? " table-scrollable" : " table-overflow"
      }`}
      style={{ maxHeight: scroll?.y }}
      onScroll={handleTableScroll}
    >
      <table className={`${tableClassName ? tableClassName : ''}table${stripped ? " table-stripped" : ""}`}>
        <thead>
          <tr>
            {rowSelection && (
              <th>
                <input 
                  type="checkbox" 
                  onChange={(event) => handleCheckAll(event)} 
                  checked={selectedRowKeys?.length === dataSource?.length}
                />
              </th>
            )}
            {columns.map((column) => (
              <th
                key={column.id}
                className={column.headerClassName}
                style={{
                  textAlign: column.headerAlign,
                  width: column.width,
                  textOverflow: "ellipsis" , 
                  overflow: "hidden" ,
                  maxWidth: column.width,
                  whiteSpace:  "nowrap",
                  ...style.th,
                }}
              >
                {t(column.title)}
              </th>
            ))}
          </tr>
        </thead>
        {dataSource?.length === 0 ? (
          <tbody>
            <tr>
              <td colSpan={rowSelection ? columns.length + 1 : columns.length} >
                <img
                  src={process.env.PUBLIC_URL + "/img/design/noData.png"}
                  alt="no data to display"
                  width={50}
                  className="m-auto"
                ></img>
                <div>
                {t("table.tabNoDataFilter")}
                </div>
              </td>
            </tr>
          </tbody>
        ) : (
          <tbody
            style={{
              ...style.tr,
            }}
          >
            {dataSource?.map((row, index) => (
           
              <tr
                id={row.index}
                key={row.index}
                {...getRowProps(row, index)}
                onClick={(event) => handleRowClick(event , row.id)}
                //className={row.className}
                style={{
                  backgroundColor: selectedRowKeys.includes(row.id)
                    ? "#e6f7ff"
                    : null,
                  cursor: "pointer",
                  ...style.tr,
                }}
              >
                {rowSelection && (
                  <td>
                    <input type="checkbox" {...checkboxProps(row)} />
                  </td>
                )}
                {columns?.map((column) => (
                  <td
                    key={column.id}
                    className={column.className}
                    style={{
                      textAlign: column.align,
                      width: column.width,
                      fontWeight: column.bold && "bold",
                      //les 4 lignes suivantes pour traiter ellipsis
                      textOverflow: column.ellipsis &&"ellipsis" , 
                      overflow: column.ellipsis &&"hidden" ,
                      maxWidth: column.ellipsis && column.width,
                      whiteSpace: column.ellipsis && "nowrap",
                      //
                      ...style.td,
                    }}
                  >
                    {column.render
                      ? column.render(row[column.dataIndex], row)
                      : row[column.dataIndex]}
                  </td>
                ))}
                {/* {expandableRow && (
                  <td>
                    <span onClick={() => setExpand(!expand)}>+</span>
                  </td>
                )} */}
              </tr>
            ))}
             {/* Ajouter des lignes vides uniquement si nécessaire 
          {emptyRowCount > 0 && emptyRows}*/}
          </tbody>
        )}
      </table>
    </div>
  );
};

Table.defaultProps = {
  stripped: true,
};

Table.propTypes = {
  stripped: PropTypes.bool,
  rowClassName: PropTypes.func,
};

export default Table;
