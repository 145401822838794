import React from 'react';

export default function OptionHidden({onClick, optionsHidden}) {

    return (
        <div
            className={`h-[100vh] w-[100vw] fixed top-0 left-0 z-[99999]
                ${!optionsHidden && "hidden"}
            `}
            onClick={onClick}
        />
    );
};
