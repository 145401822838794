import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "ck_commun/src/app/modUtils/components/languageSwitcher/LanguageSwitcher";
import LoginForm from "ck_commun/src/app/modAuthentication/components/LoginForm";
import PasswordResetForm from "ck_commun/src/app/modAuthentication/components/PasswordResetForm";
import ModalMobilePage from "../../modUtils/pages/ModalMobilPage";

export default function Login({ appName, contactPhoneNumber, appLogo, displayFooter=true }) {
    const { t } = useTranslation();
    const [showLoginForm, setShowLoginForm] = useState(true);

    const handleForgetPasswordClick = () => {
        setShowLoginForm(false);
    };

    const handleBackToLoginClick = () => {
        setShowLoginForm(true)
    }

    return (
        <>
        <ModalMobilePage logoSrc={appLogo}/>
       
        <div className="bg-gray-300 flex flex-col items-center justify-center w-full min-w-full h-full min-h-full">
            <div className="flex flex-row items-center justify-center rounded-[9px]">
                <div className="hidden md:block w-[371px] min-w-[371px] h-[561px] min-h-[561px] bg-[#338ef5]  items-center flex-col text-white rounded-[9px_0_0_9px] p-[30px]">
                  <div className="flex justify-center">
                      <img
                          src={appLogo}
                          alt="img"
                          className="w-[120px] h-[120px]"
                      />
                  </div>

                  <div className="w-full h-[155px] text-[12pt] text-center leading-[22px] text-justify my-6">
                      {t("login.loginPresentation")}
                  </div>

                  <div className="flex justify-center">
                      <img
                          className="w-[50px] h-[50px]"
                          src={process.env.PUBLIC_URL + "/img/design/help.png"}
                          alt="help"
                      />
                  </div>

                  <div className="w-full h-[72px] text-[10pt] font-bold text-white text-center align-middle my-[15px] mb-[10px] no-underline">
                      <div>{t("login.loginHelp")}</div>
                      <div>{t("login.loginCallToHelp")}</div>
                  </div>

                  <div className="w-full h-[41px] text-[18pt] text-white text-center align-middle no-underline">{contactPhoneNumber}</div>
                </div>
                
                <div 
                    className={`max-w-[80%] min-w-[80%] md:min-w-[371px] md:h-[561px] md:min-h-[561px] bg-white p-[5px] px-[20px]
                    rounded-[9px] md:rounded-r-[9px] md:rounded-l-none`}
                >
                    <div className="h-[40px]">
                        <LanguageSwitcher color="dark"/>
                    </div>
                    
                    <div className="md:mt-[25px] flex items-center flex-col">
                      <div className="md:text-[12pt] leading-[37px] font-bold text-gray-500 text-center uppercase">
                        {t("login.loginTitle")}
                      </div>

                      <div className="keyIcon my-[7px] mb-[30px]">
                            <img
                                className="h-[25px] md:h-[50px]"
                                src={process.env.PUBLIC_URL + "/img/design/cle.png"}
                                alt="clé"
                            />
                      </div>

                      {showLoginForm ? <LoginForm /> : <PasswordResetForm onBackToLoginClick={handleBackToLoginClick}/>}

                      {showLoginForm && (
                          <div
                              className="underline text-[9pt] text-[#41B9FF] text-center align-middle cursor-pointer mt-5 hover:text-[#E53D3A]"
                              onClick={handleForgetPasswordClick}
                          >
                              {t("login.loginForgetPassword")}
                          </div>
                      )}
                    </div>
                </div>
            </div>
            {displayFooter && ( 
            <div className="hidden md:flex mt-2.5 bg-[#E53D3A] rounded-[9px] w-[742px] h-[147px] flex flex-row justify-between items-center p-[14px]">
              <div>
                  <div className="font-extrabold text-[18px] leading-[22px] text-left text-white uppercase w-[354px] mb-4.5">
                    {t("login.loginFooterTile")}
                  </div>

                  <ul className=" list-none m-0 p-0 font-medium text-[18px] leading-[28px] text-left text-white uppercase">
                      <li>
                        <span className="mr-2">✓</span>
                        {t("login.loginFooterNewDesign")}
                      </li>
                      <li>
                        <span className="mr-2">✓</span>
                        {t("login.loginFooterNewNavigation")}
                      </li>
                      <li>
                        <span className="mr-2">✓</span>
                        {t("login.loginFooterEnrichedContent")}
                      </li>
                  </ul>
              </div>

              <div className="loginFooterImage">
                  <img
                      src={appLogo}
                      alt="img"
                      className="h-[110px] w-[110px]"
                  />
              </div>

              <div>
                  <div className="text-white text-center leading-[19px] text-[15px] uppercase font-normal mb-[14px]">
                      <div className="mb-[2px]">{t("login.loginFooterCallToActionDiscover")}</div>

                      <div className="mb-[2px]">{t("login.loginFooterCallToActionNew")}</div>

                      <div className="mb-[2px]">
                        {t("login.loginFooterCallToActionVersion")}
                        <b> {appName}</b>
                      </div>
                  </div>
                </div>
            </div>
           )}
           </div>
        </>
    );
};
