import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
    httpGet,
    httpPost,
    httpDelete,
    httpPut
} from "ck_commun/src/app/modUtils/apis/httpConfig";

// retourner la liste des contraintes service d'une configuration
export const get_constraint_service_paginated = createAsyncThunk(
    "mod_profil/get_constraint_service_paginated",
    async (values, { rejectWithValue }) => {
        try {
            const response = await httpGet(
                `mod_profil/groups/${values.groupId}/configurations/${values.configurationId}/constraints_service/`,
                {
                    params: {
                        limit: values.limit,
                        offset: values.offset
                    }
                }
            );
            return response.data.results;
        } catch (error) {
            return rejectWithValue({
                status: error.response.status,
                data: error.response.data
            });
        }
    }
);

// Créer une contrainte service
export const create_constraint_service = createAsyncThunk(
    "mod_profil/create_constraint_service",
    async (values, { rejectWithValue }) => {
        try {
            const response = await httpPost(
                `mod_profil/groups/${values.groupId}/configurations/${values.configurationId}/constraints_service/`,
                {
                    name: values.name,
                    equipment_type: values.equipmentType,
                    is_global: values.isGlobal,
                    selection_ids: values.selectionId && values.selectionId?.split(",")?.map(Number),
                    amount_min: values.amountMin,
                    amount_max: values.amountMax
                }
            );
            return response.data;
        } catch (error) {
            return rejectWithValue({
                status: error.response.status,
                data: error.response.data
            });
        }
    }
);

// retourner le détail d'une contrainte  service
export const get_constraint_service_detail = createAsyncThunk(
    "mod_profil/get_constraint_service_detail",
    async (values, { rejectWithValue }) => {
        try {
            const response = await httpGet(
                `mod_profil/groups/${values.groupId}/configurations/${values.configurationId}/constraints_service/${values.constraintId}/`
            );
            return response.data;
        } catch (error) {
            return rejectWithValue({
                status: error.response.status,
                data: error.response.data
            });
        }
    }
);

// TODO CH PROCHAINE ISSUE METRE EN PLACE LA MODIFICATION DES CONTRAINT SERVICE ET AUTRE
// modifier une contrainte service
export const update_constraint_service_detail = createAsyncThunk(
    "mod_profil/update_constraint_service_detail",
    async (values, { rejectWithValue }) => {
        try {
            const response = await httpPut(
                `mod_profil/groups/${values.groupId}/configurations/${values.configurationId}/constraints_service/${values.constraintId}/`,
                {
                    name: values.name,
                    equipment_type: values.equipmentType,
                    is_global: values.isGlobal,
                    selection_id: values.selectionId,
                    amount_min: values.amountMin,
                    amount_max: values.amountMax
                }
            );
            return response.data;
        } catch (error) {
            return rejectWithValue({
                status: error.response.status,
                data: error.response.data
            });
        }
    }
);

// Supprimer une contrainte service
export const delete_constraint_service_detail = createAsyncThunk(
    "mod_profil/delete_constraint_service_detail",
    async (values, { rejectWithValue }) => {
        try {
            const response = await httpDelete(
                `mod_profil/groups/${values.groupId}/configurations/${values.configurationId}/constraints_service/${values.constraintId}/`
            );
            return {...response.data, id: values.constraintId};
        } catch (error) {
            return rejectWithValue({
                status: error.response.status,
                data: error.response.data
            });
        }
    }
);

const constraintServiceSlice = createSlice({
    name: "constraintService",
    initialState: {
        loading: true,
        constraintsService: [],
        constraintsServiceDetail: {}
    },
    extraReducers: (builder) => {
        builder
            /* get_constraint_service_paginated */
            .addCase(get_constraint_service_paginated.pending, (state, action) => {
                state.constraintsService = [];
            })
            .addCase(get_constraint_service_paginated.fulfilled, (state, action) => {
                state.loading = false;
                state.constraintsService = action.payload;
            })
            .addCase(get_constraint_service_paginated.rejected, (state, action) => {
                state.loading = false;
            })

            /* create_constraint_service */
            .addCase(create_constraint_service.fulfilled, (state, action) => {
                state.loading = false;
                action.payload.index = action.payload.id;
                state.constraintsService = [
                    ...state.constraintsService,
                    action.payload
                ];
            })
            .addCase(create_constraint_service.rejected, (state, action) => {
                state.loading = false;
            })

            /* get_constraint_service_detail */
            .addCase(get_constraint_service_detail.pending, (state, action) => {
                state.constraintsServiceDetail = {};
            })
            .addCase(get_constraint_service_detail.fulfilled, (state, action) => {
                state.loading = false;
                state.constraintsServiceDetail = action.payload;
            })
            .addCase(get_constraint_service_detail.rejected, (state, action) => {
                state.loading = false;
            })

            /* update_constraint_service_detail*/
            .addCase(update_constraint_service_detail.fulfilled, (state, action) => {
                state.loading = false;
                const updatedConstraintService = action.payload;
                const updatedConstraintsServiceList = state.constraintsService.map(
                    (constraint) => {
                        if (constraint.id === updatedConstraintService.id) {
                            return updatedConstraintService;
                        }
                        return constraint;
                    }
                );
                state.constraintsService = updatedConstraintsServiceList;
            })
            .addCase(update_constraint_service_detail.rejected, (state, action) => {
                state.loading = false;
            })

            /* delete_constraint_service_detail */
            .addCase(delete_constraint_service_detail.fulfilled, (state, action) => {
                const constraintServiceToDeleteId = action.payload.id;
                state.constraintsService = state.constraintsService.filter(
                    (constraint) => constraint.id !== constraintServiceToDeleteId
                );   
                state.loading = false;
            })
            .addCase(delete_constraint_service_detail.rejected, (state, action) => {
                state.loading = false;
            });
    }
});

export default constraintServiceSlice.reducer;
