import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Spinner, Button } from "../../../modUtils/components/componentsLibrary/componentsLibrary";
import ConfirmationModal from "../../../modUtils/components/confirmationModal/ConfirmationModal";
import { imachines_gb_machine_kinds_by_equipment_by_site_by_group_get } from "../../slices/machineSlice";
import { dateFormat } from "../../../modUtils/functions/dateFormat";
import { machine_management_by_id_action } from "../../slices/machineSlice";
import { eMachinesOperatingStatus } from "../../../../commun_global/js/machines/enums";
import spinnerPng from '../../assets/images/spinner.png';

export default function MachineAction({ siteId, equipment, machineOnClick }) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const { groupId } = useSelector((state) => state.manager);
    const { machines } = useSelector((state) => state.machine);
    const { messageAlert } = useSelector((state) => state.machine);
    const [confirmModalVisibleStart, setConfirmModalVisibleStart] = useState(false);
    const [confirmModalVisibleService, setConfirmModalVisibleService] = useState(false);
    const [confirmModalVisibleRestart, setConfirmModalVisibleRestart] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [machine, setmachine] = useState(machineOnClick);

    const timeout = (setConfirmModalVisible) => {
        setTimeout(() => {
            setConfirmModalVisible(false);
            setConfirmLoading(false);
            fetchData();
        }, 1000);
    };

    const handleConfirmStartOk = () => {
        setConfirmLoading(true);
        dispatch(
            machine_management_by_id_action({
                groupId: groupId,
                siteId: siteId,
                equipmentId: equipment.id,
                machineId: machine.id,
                command: machine.operating_status == 5 || 
                         machine.operating_status == 10 ? ('start') : ('stop')
            })
        );
        timeout(setConfirmModalVisibleStart);
    };

    const handleConfirmServiceOk = () => {
        setConfirmLoading(true);
        dispatch(
            machine_management_by_id_action({
                groupId: groupId,
                siteId: siteId,
                equipmentId: equipment.id,
                machineId: machine.id,
                command: 'value_set',
                value: {'out_of_order': machine?.operating_status >= 0 && 
                                        machine?.operating_status <= 2 ? (false) : (true)}
            })
        );
        timeout(setConfirmModalVisibleService);
    };

    const handleConfirmRestartOk = () => {
        setConfirmLoading(true);
        dispatch(
            machine_management_by_id_action({
                groupId: groupId,
                siteId: siteId,
                equipmentId: equipment.id,
                machineId: machine.id,
                command: 'power_set',
                power: 2
            })
        );
        timeout(setConfirmModalVisibleRestart);
    };

    const handleConfirmCancel = (setConfirmModalVisible) => {
        setConfirmModalVisible(false);
    };
    const handleConfirm = (setConfirmModalVisible) => {
        setConfirmModalVisible(true);
    };

    async function fetchData() {
        setLoading(true);
        await dispatch(
            imachines_gb_machine_kinds_by_equipment_by_site_by_group_get({
                groupId: groupId,
                equipmentId: equipment.id,
                siteId: siteId
            })
        );
        setLoading(false);
    };

    useEffect(() => {
        if (machines[0] !== undefined && machines[0].length > 0) {
            setmachine(setMachineById());
        }
    });

    function setMachineById() {
        let machineById;
        machines[0].map((machines) => {
            machines.machines.map((machine) => {
                if (machineOnClick?.id === machine["id"]) {
                    machineById = machine;
                }
            })
        })
        return machineById;
    };

    const setStyleButton = (color, lvl = "", lvlHover = "") => {
        let textWhite = "text-white";
        let hover = "hover:";
        let focus = "focus:";
        let bg = "bg-" + color + "-color";
        let border = "border-" + color + "-color";
        let bgLvl = bg + lvl;
        let borderLvl = border + lvl;
        let bgLvlHover = hover + bg + lvlHover;
        let borderLvlHover = hover + border + lvlHover;

        let style = bgLvl + " " + borderLvl;
        let styleHover = bgLvlHover + " " + borderLvlHover + " " + hover + textWhite;
        let styleFocus = focus + bgLvl + " " + focus + borderLvl + " " + focus + textWhite;

        // if (color === "primary") {
        //     tailwind = "bg-primary-color border-primary-color";
        //     tailwindHover = "hover:bg-primary-color-700 hover:border-primary-color-700 hover:text-white";
        //     tailwindFocus = "focus:bg-primary-color focus:border-primary-color focus:text-white";
        // }else if (color === "success") {
        //     tailwind = "bg-success-color border-success-color";
        //     tailwindHover = "hover:bg-success-color-600 hover:border-success-color-600 hover:text-white";
        //     tailwindFocus = "focus:bg-success-color focus:border-success-color focus:text-white";
        // }else if (color === "error") {
        //     tailwind = "bg-error-color border-error-color";
        //     tailwindHover = "hover:bg-error-color-600 hover:border-error-color-600 hover:text-white";
        //     tailwindFocus = "focus:bg-error-color focus:border-error-color focus:text-white";
        // }else if (color === "thirty") {
        //     tailwind = "bg-thirty-color border-thirty-color";
        //     tailwindHover = "hover:bg-thirty-color-600 hover:border-thirty-color-600 hover:text-white";
        //     tailwindFocus = "focus:bg-thirty-color focus:border-thirty-color focus:text-white";
        // }else if (color === "neutral") {
        //     tailwind = "bg-neutral-color border-neutral-color";
        //     tailwindHover = "hover:bg-neutral-color hover:border-neutral-color hover:text-white";
        //     tailwindFocus = "focus:bg-neutral-color focus:border-neutral-color focus:text-white";
        // }

        let textStyle = "btn min-w-32 h-10 " + style + " " + styleHover + " " + styleFocus;
        return textStyle;
    };

    const setStyleAlert = (color) => {
        let style = "";

        if (color === "error") {
            style = "bg-error-color-100 border-error-color-400 text-error-color-700 border px-4 py-3 rounded relative";
        }else if (color === "success") {
            style = "bg-success-color-100 border-success-color-400 text-success-color-700 border px-4 py-3 rounded relative";
        }

        return style;
    };

    const setStyleTextColorSatus = () => {
        let style = "";

        if (machine?.operating_status >= 1 && machine?.operating_status <= 4) {
            style = "text-error-color";
        } else if (machine?.operating_status === 5 || machine?.operating_status === 9) {
            style = "text-success-color";
        } else {
            style = "text-thirty-color";
        }

        return style
    };

    return (
        <div>
            {loading ? <Spinner /> : machines[0]?.length > 0 ? (
                <div className="font-bold p-5">
                    <Button type="image" className="absolute right-10 top-1.5"> {/* btn refresh */}
                        <img 
                            src={process.env.PUBLIC_URL + "/img/refreshIcon.png"}
                            alt="refresh"  
                            onClick={() => fetchData()}
                        />
                    </Button>

                    <div className="text-primary-color text-center flex justify-center">{/* titre label */}
                        <p className="text-4xl">N°{machine?.id} - {machine?.label}</p>
                    </div>


                    <table> {/* info Status */}
                        <tbody>
                            <tr>
                                <td className="text-end w-24">{t("machineAction.status")}</td>
                                <td className="pl-2.5">
                                    <span className={`${setStyleTextColorSatus()}`}>
                                        {t(eMachinesOperatingStatus[machine?.operating_status])}
                                    </span>
                                </td>
                            </tr>

                            <tr>
                                <td className="text-end w-24">{t("machineAction.startingDate")}</td>
                                <td className="pl-2.5">{dateFormat(machine?.starting_date,'HH:mm')}</td>
                            </tr>
                        </tbody>
                    </table>


                    {/* button Démarrer et Service*/}
                    <div className="flex justify-around mt-5">
                        <div>{/* button Démarrer */}
                            <ConfirmationModal
                                onOk={handleConfirmStartOk}
                                onCancel={() => handleConfirmCancel(setConfirmModalVisibleStart)}
                                visible={confirmModalVisibleStart}
                                confirmLoading={confirmLoading}
                                content={
                                    <div>
                                        {machine?.operating_status === 5 || machine?.operating_status === 10 ?
                                            (<span>{t("machineAction.operatingStatusStart")}</span>) :
                                            (<span>{t("machineAction.operatingStatusStop")}</span>)
                                        }
                                        {confirmLoading ? 
                                            (<img className="w-5 ml-2.5 animate-[rotate_1s_linear_infinite]" src={spinnerPng} alt="Icon spinner"/>) : 
                                            (<></>)
                                        }
                                    </div>
                                }
                            />

                            {machine?.operating_status >= 0 && machine?.operating_status <= 2 ? (
                                <Button type="primary"
                                    className={`cursor-not-allowed ${setStyleButton("neutral")}`}
                                >
                                    <span className="text-white">{t("machineAction.outOfService")}</span>
                                </Button>
                            ) : (
                                <>
                                    {machine?.operating_status === 5 || machine?.operating_status === 10 ? (
                                        <Button type="primary"
                                            className={`${setStyleButton("success", "", "-600")}`}
                                            onClick={() => handleConfirm(setConfirmModalVisibleStart)}
                                        >
                                            <span>{t("machineAction.startButton")}</span>
                                        </Button>
                                    ) : (
                                        <Button type="primary"
                                            className={`${setStyleButton("error", "", "-600")}`}
                                            onClick={() => handleConfirm(setConfirmModalVisibleStart)}
                                        >
                                            <span>{t("machineAction.startButton")}</span>
                                        </Button>
                                    )}
                                </>
                            )}
                        </div>

                        <div>{/* button Service */}
                            <ConfirmationModal
                                onOk={handleConfirmServiceOk}
                                onCancel={() => handleConfirmCancel(setConfirmModalVisibleService)}
                                visible={confirmModalVisibleService}
                                confirmLoading={confirmLoading}
                                content={
                                    <div>
                                        {machine?.operating_status >= 0 && machine?.operating_status <= 2 ?
                                            (<span>{t("machineAction.operatingStatusInServiceMessage")}</span>) : 
                                            (<span>{t("machineAction.operatingStatusOutOfServiceMessage")}</span>)
                                        }
                                        {confirmLoading ? 
                                            (<img className="w-5 ml-2.5 animate-[rotate_1s_linear_infinite]" src={spinnerPng} alt="Icon spinner"/>) : 
                                            (<></>)
                                        }
                                    </div>
                                }
                            />

                            <Button type="primary"
                                className={`${setStyleButton("thirty", "", "-600")}`}
                                onClick={() => handleConfirm(setConfirmModalVisibleService)} 
                            >
                                {machine?.operating_status >= 0 && machine?.operating_status <= 2 ?
                                    (<span>{t("machineAction.operatingStatusInService")}</span>) :
                                    (<span>{t("machineAction.operatingStatusOutOfService")}</span>)
                                }
                            </Button>
                        </div>
                    </div>

                    <div className="flex justify-around items-center mt-5 p-2.5 border-2 rounded-xl border-error-color"> {/* button Redémarrer */}
                        <div>
                            <p>{t("machineAction.messageProblem")}</p>
                            <p className="m-0">{t("machineAction.restartButtonRequest")}</p>
                        </div>

                        <ConfirmationModal
                            onOk={handleConfirmRestartOk}
                            onCancel={() => handleConfirmCancel(setConfirmModalVisibleRestart)}
                            visible={confirmModalVisibleRestart}
                            confirmLoading={confirmLoading}
                            content={
                                <>
                                    <span>{t("machineAction.restartRequest")}</span>
                                    {confirmLoading ? 
                                        (<img className="w-5 ml-2.5 animate-[rotate_1s_linear_infinite]" src={spinnerPng} alt="Icon spinner"/>) : 
                                        (<></>)
                                    }
                                </>
                            }
                        />

                        <Button type="primary"
                            className={`${setStyleButton("primary", "", "-700")}`}
                            onClick={() => handleConfirm(setConfirmModalVisibleRestart)} 
                        >
                            <span>{t("machineAction.restartButton")}</span>
                        </Button>
                    </div>
                    
                    <div className="mt-5 pt-5"> {/* message Alert */}
                        {messageAlert?.status === true ? (
                            <div class={`${setStyleAlert("success")}`} role="alert"> 
                                <span>{t("machineAction.successAlert")}</span>
                            </div>
                        ) : messageAlert?.status === false ? (
                            <div class={`${setStyleAlert("error")}`} role="alert"> 
                                <span>{t("machineAction.errorAlert")}</span>
                            </div>
                        ) : (
                            <>
                            </>
                        )}
                    </div>
                </div>
            ) : (
                <div className="flex-display flex-column align-items-center"> {/* Aucune machine */}
                    <img
                        src={process.env.PUBLIC_URL + "/img/design/noData.png"}
                        alt="no data to display"
                        width={50}
                    />
                    <div>
                    {t("machineAction.noEngine")}
                        <span title="Pensez à vérifier la connectivité de vos installations"> ⓘ</span>
                    </div>
                </div>
            )}
        </div>
    );
};