import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hasPermission } from "../../functions/handlePermissions";
import { get_equipments_gb_sites_by_group_paginated } from "../../../modEquipment/slices/equipmentSlice";
import { get_dashboard_cumulatives_latest_by_group } from "../../../modCumulative/slices/cumulativeSlice";
import { Spinner } from "../../components/componentsLibrary/componentsLibrary";
import WidgetCADaily from "../../../modCumulative/components/WidgetCADaily";
import WidgetCAHebdo from "../../../modCumulative/components/WidgetCAHebdo";
import WidgetCAMensual from "../../../modCumulative/components/WidgetCAMensual";
import WidgetCAAnnual from "../../../modCumulative/components/widgetCAAnnual";
import WidgetIncident from "../../../modEquipment/components/widgetIncident/WidgetIncident";
import moment from "moment";
import ModalMobilePage from "../ModalMobilPage";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import HomeHero from "ck_commun/src/app/modUtils/components/homeHero/HomeHero";

export default function HomePage({ appLogo }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const { groupId, selectedGroup } = useSelector((state) => state.manager);
  const { user } = useSelector((state) => state.authentication);
  const permissions = selectedGroup?.permissions;
  const verifyPermission = hasPermission(
    "mod_cumulative:show_statistic",
    permissions
  );
  const range = 7;
  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      try {
        const promises = [
          dispatch(
            get_equipments_gb_sites_by_group_paginated({
              groupId,
              limit: 100,
              offset: 0,
            })
          ),
          dispatch(
            get_dashboard_cumulatives_latest_by_group({ groupId, range })
          ),
        ];

        await Promise.all(promises);

        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    }
    if (groupId) {
      fetchData();
    }
  }, [groupId, i18next.language]);

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      <ModalMobilePage logoSrc={appLogo} />

      {loading ? (
        <Spinner />
      ) : (
        <div className="mx-[25px] md:mx-[150px] md:my-[50px]">
          <h1 className="text-center text-primary-color-600">
            {!loading &&
              `${t("homePage.title")}  ${user.first_name} ${user.last_name}`}
          </h1>
          <h3 className="text-center text-primary-color-600">
            {!loading &&
              `${t("homePage.date")} ${moment().format("DD-MM-YYYY HH:mm")}`}
          </h3>

          {!verifyPermission ? (
            <HomeHero logo={appLogo} alt="appLogo" />
          ) : (
            <div className="flex flex-row flex-wrap  justify-center ">
              <WidgetCADaily loading={loading} />
              <WidgetCAHebdo loading={loading} />
              <WidgetCAMensual loading={loading} />
              <WidgetCAAnnual loading={loading} />
              <WidgetIncident loading={loading} />
            </div>
          )}
        </div>
      )}
    </>
  );
}
