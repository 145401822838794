import React , {useState} from 'react'
import "./Widget.scss"

export default  function Widget({title, content}) {
    const [isVisible, setIsVisible]=useState(true)
    function hideWidget(){
        setIsVisible(false)
    }

  return (
      isVisible && 
          <div className='widget my-5 mx-auto max-w-[80%]'>
                <div className='widget-header flex flex-row items-center justify-between'>
                    <div className='widget-title'>{title}</div>
                    <div onClick={hideWidget}> X </div> 
                </div>

                <div className='widget-content'>{content}</div>
          </div>
  );
};
