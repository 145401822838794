import React, { useEffect, useState } from "react";
import {
  Spinner,
  Button,
  Badge,
} from "../../../modUtils/components/componentsLibrary/componentsLibrary";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { get_weather_by_site_list } from "../../../modWeather/slices/weatherSlice";
import { get_equipments_gb_sites_by_group_paginated } from "../../slices/equipmentSlice";
import SubmitButton from "../../../modUtils/components/buttons/submitButton/SubmitButton";
import FilterByEquipmentStatus from "../../../modUtils/components/filters/FilterByEquipmentStatus";
import EquipmentList from "../../components/equipmentList/EquipmentList";
import Site from "./Site";
import { hasPermission } from "ck_commun/src/app/modUtils/functions/handlePermissions";
import PermissionFadeContainer from "../../../modUtils/components/permissionFadeContainer/PermissionFadeContainer";
import { message, Spin } from "antd";
import CKToolTip from "../../../modUtils/components/CKToolTip/CKToolTip";
import AlertMessage from "../../../modUtils/components/AlertMessage";
import { ReactComponent as WeatherUnvailableIcon } from "ck_commun/src/app/modWeather/assets/images/weather-icon-UNAVAILABLE.svg";
import SiteWeatherModal from "../../../modWeather/components/weatherCard/SiteWeatherModal.jsx";
import "./SitesAndEquipmentPage.scss";
import AdminLink from "../../../modUtils/components/adminLink/AdminLink";
import i18next from "i18next";

const SitesAndEquipmentPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { sitesAndEquipment } = useSelector((state) => state.equipment);
  const [sitesAndEquipmentsData, setSitesAndEquipmentsData] = useState([]); // Pour stocker tous les sites récupérés
  const [loading, setLoading] = useState(true);
  const { groupId, selectedGroup } = useSelector((state) => state.manager);
  const [isModalWeatherVisible, setIsModalWeatherVisible] = useState(false);
  const [selectedSiteForWeather, setSelectedSiteForWeather] = useState();
  const [openPanels, setOpenPanels] = useState([]);
  const [open, setOpen] = useState(true);
  const permissions = selectedGroup?.permissions;
  const verifyPermissionShowDevice = hasPermission(
    "mod_equipment:show_device",
    permissions
  );
  const verifyPermissionManageCompany = hasPermission(
    "mod_manager:manage_company",
    permissions
  );
  const verifyPermissionShowWeather = hasPermission(
    "mod_weather:show_weather",
    permissions
  );
  const groupPermissions = selectedGroup?.plan?.permissions;
  const verifyGroupPermissionShowDevice = hasPermission(
    "mod_equipment:show_device",
    groupPermissions
  );
  const verifyGroupPermissionManageCompany = hasPermission(
    "mod_manager:manage_company",
    groupPermissions
  );
  const [equipment__is_connected, setEquipment__is_connected] = useState([]);
  const [equipment__search, setEquipment__search] = useState();
  const [equipment__state, setEquipment__state] = useState([]);
  const forecastDayNb = 1;
  const [weatherData, setWeatherData] = useState({});
  const defaultOffset = 0;
  const defaultLimit = 20;
  const [limit, setLimit] = useState(defaultLimit); // Nombre d'éléments à charger à chaque fois
  const [offset, setOffset] = useState(defaultOffset); // Offset actuel pour la pagination
  const [hasMoreDataToLoad, setHasMoreDataToLoad] = useState(false); //pour savoir s'il y'a encore de données à charger
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  //appel à l'api get_equipments_gb_sites_by_group_paginated pour récupérer la liste des sites et des equipements groupés par site
  const getEquipmentsGroupedBySites = async (offset, searchValue) => {
    if (offset == defaultOffset) {
      //test ajouté pour réinitialiser les data locales (sitesAndEquipmentsData et weatherData )
      setSitesAndEquipmentsData([]);
      setWeatherData([]);
    }
    try {
      setLoading(true);
      if (offset !== defaultOffset) {
        setIsLoadingMore(true);
      }
      const response = await dispatch(
        get_equipments_gb_sites_by_group_paginated({
          groupId,
          limit,
          offset,
          equipment__is_connected: equipment__is_connected[0],
          equipment__search: searchValue,
          equipment__state: equipment__state[0],
        })
      ).unwrap();
      if (response.results) {
        const newSitesAndEquipment = response.results;
        setSitesAndEquipmentsData((prevSites) => [
          ...prevSites,
          ...newSitesAndEquipment,
        ]);
        setOpenPanels((prevPanels) => [
          ...prevPanels,
          ...newSitesAndEquipment.map((site) => `site-panel-${site.id}`),
        ]);
        setOffset(offset + limit); // Augmente l'offset pour la prochaine pagination
        let hasMore = response.next !== null ? true : false; //tester si y'a encore de données à récupérer
        setHasMoreDataToLoad(hasMore);
      }
    } catch (error) {
      message.error({
        content: (
          <AlertMessage
            status={error?.status}
            alertMessage={t("siteAndEquipments.errorMessage")}
            errorDetail={error?.data?.detail}
          />
        ),
      });
    } finally {
      setLoading(false);
      setIsLoadingMore(false);
    }
  };
  //useEffect appelé à chaque chargement de la page
  useEffect(() => {
    setSitesAndEquipmentsData([]);
    setOffset(0);
    setWeatherData({});
    if (groupId) {
      getEquipmentsGroupedBySites(defaultOffset);
    }
  }, [groupId, i18next.language]);
  //code pour le scroll
  useEffect(() => {
    const contentElement = document.querySelector(".layout .content");
    const handleScroll = () => {
      const { scrollTop, clientHeight, scrollHeight } = contentElement;
      if (
        scrollTop + clientHeight >= scrollHeight - 20 &&
        !loading &&
        hasMoreDataToLoad == true
      ) {
        getEquipmentsGroupedBySites(offset);
      }
    };
    contentElement.addEventListener("scroll", handleScroll);
    return () => {
      contentElement.removeEventListener("scroll", handleScroll);
    };
  }, [loading]); //loading ajouté dans les dépendances pour s'assurer que le gestionnaire d'événements est correctement mis à jour

  //gérer l'état de tous les panneaux (tout replier/ tout déplier)
  const handleUpdatePanel = () => {
    if (open) {
      setOpenPanels([]);
    } else {
      setOpenPanels(
        sitesAndEquipmentsData?.map((site) => `site-panel-${site.id}`)
      );
    }
    setOpen(!open);
  };
  //fonction qui gère l'état d'un seul panneau
  const handleTogglePanel = (panelId) => {
    const isOpen = openPanels?.includes(panelId);
    let updatedOpenPanels = [];
    if (isOpen) {
      updatedOpenPanels = openPanels?.filter((panel) => panel !== panelId);
    } else {
      updatedOpenPanels = [...openPanels, panelId];
    }
    setOpenPanels(updatedOpenPanels);
  };

  //code pour la météo de chaque site
  useEffect(() => {
    async function fetchWeatherData() {
      // Array to store all the promises for fetching weather data
      const weatherPromises = sitesAndEquipment?.map((site) =>
        dispatch(get_weather_by_site_list({ siteId: site.id, forecastDayNb }))
      );
      try {
        // Wait for all weather promises to resolve using Promise.all
        const allWeatherData = await Promise.all(weatherPromises);
        // Create an object with site IDs as keys and weather data as values
        const weatherDataMap = allWeatherData.reduce((acc, weather, index) => {
          const siteId = sitesAndEquipment[index].id;
          acc[siteId] = weather.payload;
          return acc;
        }, {});
        setWeatherData({ ...weatherData, ...weatherDataMap });
      } catch (error) {
        setWeatherData({}); // Set an empty weather object
      }
    }

    // Call the function to fetch weather data for all sites
    if (sitesAndEquipment?.length > 0 && verifyPermissionShowWeather) {
      fetchWeatherData();
    }
  }, [sitesAndEquipment, dispatch]);

  //retourne les icônes et compteurs des équipements en erreur et hors service
  const renderSiteGlobalStatus = (counts) => {
    return (
      <>
        <div className="flex flex-row">
          {" "}
          {counts?.error != 0 && (
            <Badge
              color="#FCB641"
              overflowCount={99}
              count={counts?.error}
              offset="8px"
            >
              <CKToolTip
                placement="topLeft"
                trigger="hover"
                content={t("siteAndEquipments.equipmentNumber")}
              >
                <img
                  width={30}
                  src={
                    process.env.PUBLIC_URL +
                    "/img/equipmentStatusIcons/errorConnectedBordered.svg"
                  }
                  alt="offlineConnected"
                />
              </CKToolTip>
            </Badge>
          )}
          {counts?.out != 0 && (
            <Badge
              color="#E30613"
              overflowCount={99}
              count={counts?.out}
              offset="8px"
            >
              <CKToolTip
                placement="topLeft"
                trigger="hover"
                content={t("siteAndEquipments.outOfServiceEquipment")}
              >
                <img
                  style={{ border: "solid 2px #ffff", borderRadius: "50%" }}
                  width={30}
                  src={
                    process.env.PUBLIC_URL +
                    "/img/equipmentStatusIcons/offlineConnected.svg"
                  }
                  alt="outoforderStatus"
                />
              </CKToolTip>
            </Badge>
          )}
        </div>
      </>
    );
  };
  //calculer le nombre des équipements déconnectés
  function countDisconnectedEquipment(equipmentList) {
    const equipmentDisconnectedCount = equipmentList.reduce(
      (count, equipment) => {
        if (!equipment.is_connected) {
          return count + 1;
        }
        return count;
      },
      0
    );

    return equipmentDisconnectedCount;
  }
  //appelée quand on clique sur Executer
  const onExecuteSubmitBtn = () => {
    const inputElement = document.getElementById("searchInput");
    const searchValue = inputElement.value;
    getEquipmentsGroupedBySites(defaultOffset, searchValue);
  };

  //gère les filtres sélectionnés (status)
  const handleEquipmentStatus = (selectedList) => {
    setEquipment__state(selectedList[0]?.options);
    setEquipment__is_connected(selectedList[1]?.options);
  };

  //fonction qui calcule le nombre des equipements working / error / out of order
  const countEquipmentByStatus = (equipmentList) => {
    const counts = {
      working: 0,
      error: 0,
      out: 0,
    };

    equipmentList.forEach((equipment) => {
      if (equipment.out_of_order) {
        counts.out++;
      } else if (equipment.devices_error_count != 0) {
        counts.error++;
      } else {
        counts.working++;
      }
    });
    return counts;
  };
  //handleClick popover weather
  const handleClickOnShowWeather = (site) => {
    setSelectedSiteForWeather(site);
    setIsModalWeatherVisible(true);
  };
  //retourner l'icone de la météo d'un site
  const renderWeatherBysite = (site) => {
    const siteId = site?.id;
    let weatherDataUnavailable =
      weatherData[siteId].status && weatherData[siteId].status >= 400; // si l'api météo d'un site retourne une erreur weatherDataUnavailable == true
    return (
      <div className="flex-display flex-row flex-no-wrap cursor-pointer">
        {!weatherDataUnavailable ? (
          <div className="flex-display flex-row flex-no-wrap">
            <CKToolTip
              placement="topLeft"
              trigger="hover"
              content={t("siteAndEquipments.weather")}
            >
              <div
                className="hover:animate-bounce"
                onClick={() => handleClickOnShowWeather(site)}
              >
                <img width={30} src={weatherData[siteId]?.[0]?.day_image} />
              </div>{" "}
            </CKToolTip>
            <div style={{ marginLeft: "0.5em" }}>
              {weatherData[siteId]?.[0]?.day_maxtemp}°C
            </div>
          </div>
        ) : (
          <>
            <CKToolTip
              placement="topLeft"
              trigger="hover"
              content={weatherData[siteId]?.data?.detail?.detail}
            >
              <WeatherUnvailableIcon className="w-9" />
            </CKToolTip>
          </>
        )}
      </div>
    );
  };
  return (
    <>
      {loading && <Spinner />}
      <div className="flex-display flex-col sm:flex-row align-items-end filters gap-2 ">
        {verifyPermissionShowDevice && (
          <PermissionFadeContainer permission={verifyGroupPermissionShowDevice}>
            <FilterByEquipmentStatus
              onValueChange={handleEquipmentStatus}
              equipment__is_connected={equipment__is_connected}
              equipment__state={equipment__state}
              defaultLimit={defaultLimit}
            />
          </PermissionFadeContainer>
        )}
        <div className="search-site flex-display flex-row w-full sm:w-auto">
          <input
            id="searchInput"
            type="text"
            className="search-site"
            placeholder={t("siteAndEquipments.inputSearch")}
          />
          <img
            src={process.env.PUBLIC_URL + "/img/design/zoom.svg"}
            alt="zoom"
          />
        </div>
        <SubmitButton
          onClick={() => onExecuteSubmitBtn()}
          className="w-full sm:w-auto"
        />
      </div>
      <div className="flex-display justify-content-start sm:justify-content-end">
        {sitesAndEquipmentsData.length > 1 && (
          <Button type="text" onClick={handleUpdatePanel} className={"px-0"}>
            {open
              ? t("common.transButtonCollapseAll")
              : t("common.transButtonExpandAll")}
          </Button>
        )}
      </div>
      {sitesAndEquipmentsData.length > 0 ? (
        <div className="overflow-x-auto">
          {
            sitesAndEquipmentsData?.map((site) => {
              const panelId = `site-panel-${site.id}`;
              const isPanelOpen = openPanels?.includes(panelId);
              return (
                <div key={`site-collapse-${site.id}`} style={{ minWidth: "970px" }}>
                  <div
                    className="site-panel-header flex justify-content-between align-items-center"
                    onClick={() => handleTogglePanel(panelId)}
                  >
                    <div className="w-32">
                      {verifyPermissionShowDevice && (
                        <PermissionFadeContainer
                          permission={verifyGroupPermissionShowDevice}
                        >
                          {renderSiteGlobalStatus(
                            countEquipmentByStatus(site.equipments)
                          )}
                        </PermissionFadeContainer>
                      )}
                    </div>
                    <div className="w-full ml-11">
                      <Site site={site} />
                    </div>
    
                    <div className="flex mr-32">
                      {verifyPermissionShowDevice &&
                        countDisconnectedEquipment(site.equipments) > 0 && (
                          <PermissionFadeContainer
                            permission={verifyGroupPermissionShowDevice}
                          >
                            <Badge
                              color="#E30613"
                              overflowCount={99}
                              count={countDisconnectedEquipment(site.equipments)}
                              offset="8px"
                              title={countDisconnectedEquipment(site.equipments)}
                            >
                              <CKToolTip
                                placement="topLeft"
                                trigger="hover"
                                content={t("siteAndEquipments.offlineEquipment")}
                              >
                                <img
                                  width={30}
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/img/equipmentStatusIcons/offlineStatus.svg"
                                  }
                                  alt="not connected"
                                />
                              </CKToolTip>
                            </Badge>
                          </PermissionFadeContainer>
                        )}
                    </div>
                    <div className="flex justify-end w-2/12">
                      {verifyPermissionManageCompany && weatherData[site.id] && (
                        <PermissionFadeContainer
                          permission={verifyGroupPermissionManageCompany}
                        >
                          {renderWeatherBysite(site)}
                        </PermissionFadeContainer>
                      )}
                    </div>
                    {/* <AdminLink
                        type="button"
                        label="Lien Admin Site"
                        adminPath={`mod_manager/site/${site.id}/change/`}
                        className="whitespace-nowrap text-xs leading-3"
                    /> */}
                </div>
                {isPanelOpen && (
                  <EquipmentList
                    siteId={site.id}
                    siteName={site.name}
                    equipmentList={site.equipments}
                    loading={loading}
                  />
                )}
              </div>
            );
          })}
        </div>
      ) : (
        <div
          className="flex-display flex-column align-items-center mt-20"
          style={{ marginTop: "4em" }}
        >
          <img
            src={process.env.PUBLIC_URL + "/img/design/noData.png"}
            alt="no data to display"
            width={50}
          />
          <div style={{ textAlign: "center" }}>
          {t("siteAndEquipments.noSiteEquipment")}
          </div>
        </div>
      )}

      {isLoadingMore && (
        <div className="flex justify-center my-4">
          <Spin size="large" />
        </div>
      )}
      {isModalWeatherVisible && (
        <SiteWeatherModal
          isVisible={isModalWeatherVisible}
          setIsVisible={setIsModalWeatherVisible}
          site={selectedSiteForWeather}
        />
      )}
    </>
  );
};
export default SitesAndEquipmentPage;
