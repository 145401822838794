import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { FaEllipsisV } from "react-icons/fa";
import { Form, DatePicker, message } from "antd";
import { ReactComponent as SwitcherIcon } from "../../../modEntity/assets/images/arrow.svg";
import { create_configuration_duplicate } from "../../slices/configurationSlice";
import { ReactComponent as ConfigNextIcon } from "../../../modEntity/assets/images/awaitCircle.svg";
import { ReactComponent as ConfigActiveIcon } from "../../../modEntity/assets/images/check.svg";
import FormLegend from "../../../modUtils/components/FormLegend";
import AlertMessage from "../../../modUtils/components/AlertMessage";
import DismissButton from "../../../modUtils/components/buttons/DismissButton";
import { Modal } from "../../../modUtils/components/componentsLibrary/componentsLibrary";
import DropdownActionsMenu from "../../../modUtils/components/dropdownActionsMenu/DropdownActionsMenu";
import dayjs from 'dayjs';
import "./ConfigurationNode.scss";
import SubmitButton from "../../../modUtils/components/buttons/submitButton/SubmitButton";

export default function ConfigurationNode({
    configurationData,
    selectedConfig,
    saveSelectedConfigurationCallback,
    profilId
}) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const { groupId } = useSelector((state) => state.manager);
    const [modalVisible, setModalVisible] = useState(false);
    const [isMenuVisible, setIsMenuVisible] = useState(false);
    const [errorDate, setErrorDate] = useState(null);
    const defaultDate = dayjs().add(1, 'day').startOf('day').hour(12); // Définir la date de demain à 12h00

    const onClickHandler = () => {
        saveSelectedConfigurationCallback(configurationData.id);
    };

    // appel à l'api pour dupliquer une configuration
    const duplicateConfiguration = async (dateActivation) => {
        try {
            setErrorDate(null);
            const response = await dispatch(
                create_configuration_duplicate({
                    groupId,
                    profilId,
                    configurationId: configurationData.id,
                    dateActivation: dateActivation
                })
            ).unwrap();
            if (response) {
                setModalVisible(false);
            }
        } catch (error) {
            if (error.status === 400) {
                setErrorDate(error.data.detail);
            }else {
                message.error({
                    content: (
                        <AlertMessage
                            status={error?.status}
                            alertMessage={`Une erreur est survenue lors de la duplication de la configuration !`}
                            errorDetail={error?.data?.detail}
                        />
                    )
                });
            }
        }
    };

    const getNodeIcon = () => {
        let nodeIcon = "";

        if (configurationData?.is_active) {
            nodeIcon = <ConfigActiveIcon/>;
        }else if (configurationData?.is_future) {
            nodeIcon = <ConfigNextIcon/>;
        }else {
            nodeIcon =  <img className="max-w-7" src={process.env.PUBLIC_URL + "/img/expire.png"}/>;
        }

        return nodeIcon
    }

    const getNodeClassName = () => {
        let nodeClassName = "";

        if (configurationData?.is_active) {
            nodeClassName = "active";
        }else if (configurationData?.is_future) {
            nodeClassName = "next";
        }else {
            nodeClassName = "expired";
        }

        return nodeClassName
    }

    const handleClickMenuItem = () => {
        setModalVisible(true);
        setIsMenuVisible(false);
    }

    const menuItems = [
        <p onClick={() => handleClickMenuItem()}>{t("configurationNode.duplicate")}</p>
    ];

    const onFinish = (values) => {
        const dateActivation = values.dateActivation.unix();
        duplicateConfiguration(dateActivation);
    };

    const handleResetForm = () => {
        form.resetFields();
        setModalVisible(false);
    };

    useEffect(() => {
        // Vérifier s'il y a une erreur à appliquer au champ
        if (errorDate) {
            form.setFields([
                {
                    name: 'dateActivation',
                    errors: [errorDate] // Si errorDate est défini, définir l'erreur sur le champ
                }
            ]);
        } else {
            form.setFields([
                {
                    name: 'dateActivation',
                    errors: [] // Efface les erreurs si errorDate n'est pas défini
                }
            ]);
        }
    }, [errorDate, form]);

    const handleDateChange = (date) => {
        const dayjsDate = dayjs(date); // Crée un objet dayjs
        form.setFieldsValue({ dateActivation: dayjsDate }); // Met à jour le formulaire avec l'objet dayjs

        // Efface les erreurs du champ quand l'utilisateur change la valeur
        setErrorDate(null);
        form.setFields([
            {
                name: 'dateActivation',
                errors: [] // Supprime l'erreur manuellement quand l'utilisateur modifie la date
            }
        ]);
    };

    // TODO CH FONCTION POSSIBLE DANS LE MOD UTILE 
    const toPascalCase = (str) => {
        if (!str) return '';  // Si la chaîne est vide ou null, retourner une chaîne vide
        return str
            .toLowerCase()
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };

    return (
        <>
        <div
            onClick={onClickHandler}
            className="config-tree-node-container flex items-center"
        >
            <div
                className={`config-tree-node ${getNodeClassName()} ${
                    configurationData.id == selectedConfig && "selected"
                } rounded-md p-2 mb-1.5 min-w-[240px] max-w-[240px]`}
            >
                <div className="flex items-center justify-between">
                    <div className="flex items-center line-clamp-1">
                        <div className="mr-4">
                            {getNodeIcon()}
                        </div>

                        <div className="font-bold text-sm text-clip">
                            {toPascalCase(configurationData?.name)}
                        </div>

                    </div>

                    <div className="cursor-pointer node-switcher-icon">
                        {selectedConfig && <SwitcherIcon/>}
                    </div>
                </div>
            </div>

            <div className="ml-2 w-1"
                onClick={(event) => event.stopPropagation()}
            >
                <DropdownActionsMenu
                    trigger="hover"
                    triggerImg={<FaEllipsisV className="text-primary-color"/>}
                    placement={"right"}
                    menuItems={menuItems}
                    visible={isMenuVisible}
                    handleVisibleChange={() => setIsMenuVisible(!isMenuVisible)}
                />
            </div>
        </div>

        <Modal
            title={t("configurationNode.duplicateProfile")}
            isOpen={modalVisible}
            onClose={() => setModalVisible(false)}
        >
            <Form form={form} onFinish={onFinish} layout="vertical">
                <div className="flex flex-col items-start">
                    <Form.Item
                        label={t("configurationDetails.activationDate")}
                        initialValue={defaultDate} // Utilisez la date de demain comme valeur initiale
                        name="dateActivation"
                        rules={[
                            {
                                required: true,
                                message: t("configurationDetails.pleaseSelectActivationDate")
                            },
                            {
                                // Validation personnalisée avec errorDate
                                validator: (_, value) => {
                                    if (errorDate) {
                                        return Promise.reject(new Error(errorDate));
                                    }
                                    return Promise.resolve();
                                }
                            }
                        ]}
                    >
                        <DatePicker
                            onChange={(date) => handleDateChange(date ? dayjs(date) : defaultDate)} // Utilisation de defaultDate si la date est null
                            format="YYYY-MM-DD HH:mm" // Format de la date et de l'heure
                            showTime={{ format: 'HH:mm' }} // Affichage de la sélection d'heure
                            style={{ width: '100%' }}
                        />
                    </Form.Item>
                </div>

                <div className="flex flex-row justify-center gap-2 mt-2">
                    <Form.Item>
                        <DismissButton onClick={() => handleResetForm()}/>
                    </Form.Item>

                    <Form.Item>
                        <SubmitButton
                            label={t("configurationDetails.save")}
                            className="h-[42px]"
                        />
                    </Form.Item>
                </div>
            </Form>

            <FormLegend requiredFormItemLegend={true}/>
        </Modal>
        </>
    );
};
