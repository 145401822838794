import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Modal,
  Input,
  Spinner,
} from "../../modUtils/components/componentsLibrary/componentsLibrary";
import { dateFormat } from "../../modUtils/functions/dateFormat";
import { amountFormatWithCurrencySymbol } from "../../modUtils/functions/numberFormat";
import { get_telecollect } from "../slices/bankingSlice";
import { message } from "antd";
import AlertMessage from "../../modUtils/components/AlertMessage";

export default function TelecollectDetailsModal({
  telecollectData,
  telecollectId,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [modalVisible, setModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const { groupId } = useSelector((state) => state.manager);
  const { telecollectDetail } = useSelector((state) => state.banking);

  const getTelecollectDetail = async () => {
    try {
      setLoading(true);
      await dispatch(
        get_telecollect({
          groupId,
          telecollectId,
        })
      ).unwrap();
    } catch (error) {
      message.error({
        content: (
          <AlertMessage
            status={error?.status}
            alertMessage={t("telecollectDetail.errorMessage")}
            errorDetail={error?.data?.detail}
          />
        ),
      });
    } finally {
      setLoading(false);
    }
  };

  const openModal = () => {
    setModalVisible(true);
    !telecollect && getTelecollectDetail() 
  };

  let telecollect = telecollectData || telecollectDetail;
  return (
    <>
      <img
        src={process.env.PUBLIC_URL + "/img/actionsButton.png"}
        onClick={openModal}
      />
      <Modal
        title={t("telecollectDetail.title")}
        isOpen={modalVisible}
        onClose={() => setModalVisible(false)}
        width="50%"
      >
        {loading ? <Spinner/> : <>
        <Input
          label={t("table.tabColDate")}
          value={dateFormat(telecollect?.date)}
          disabled
        />
        <Input
          label={t("table.tabColSite")}
          value={telecollect?.site}
          disabled
        />
        <Input
          label={t("table.tabColEquipment")}
          value={telecollect?.equipment}
          disabled
        />
        <div className="flex-display align-items-center justify-content-between">
          <Input
            label={t("table.tabColApplication")}
            value={telecollect?.app}
            disabled
          />
          <Input
            label={t("table.tabColFile")}
            value={telecollect?.sequence_number}
            disabled
          />
          <Input
            label={t("table.tabColDiscount")}
            value={telecollect?.remittance}
            disabled
          />
        </div>
        <div className="flex-display align-items-center justify-content-between">
          {" "}
          <Input
            label={t("table.tabColOk")}
            value={telecollect?.ok_count}
            disabled
          />
          <Input
            label={t("table.tabColFail")}
            value={telecollect?.ko_count}
            disabled
          />{" "}
        </div>
        <Input
          label={t("table.tabColAmount")}
          value={amountFormatWithCurrencySymbol(telecollect?.amount)}
          disabled
        />
        <div className="flex-display align-items-center justify-content-between">
          <Input
            label="Contract" //a modifier
            value={telecollect?.contract_number}
            disabled
          />
          <Input
            label="Logical number system"
            value={telecollect?.logical_number_system} //a modifier
            disabled
          />
          <Input
            label="Siret" // a modifier
            value={telecollect?.siret_number}
            disabled
          />
        </div>
        </>}
      </Modal>
    </>
  );
}
