import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
    httpGet,
    httpPost,
    httpDelete,
    httpPut
} from "ck_commun/src/app/modUtils/apis/httpConfig";

// retourner la liste des contraintes temps d'une configuration
export const get_constraint_schedule_paginated = createAsyncThunk(
    "mod_profil/get_constraint_schedule_paginated",
    async (values, { rejectWithValue }) => {
        try {
            const response = await httpGet(
                `mod_profil/groups/${values.groupId}/configurations/${values.configurationId}/constraints_schedule/`,
                {
                    params: {
                        limit: values.limit,
                        offset: values.offset
                    }
                }
            );
            return response.data;
        } catch (error) {
            return rejectWithValue({
                status: error.response.status,
                data: error.response.data
            });
        }
    }
);

// Créer une contrainte horaire
export const create_constraint_schedule = createAsyncThunk(
    "mod_profil/create_constraint_schedule",
    async (values, { rejectWithValue }) => {
        try {
            const response = await httpPost(
                `mod_profil/groups/${values.groupId}/configurations/${values.configurationId}/constraints_schedule/`,
                {
                    name: values.name,
                    equipment_type: values.equipmentType,
                    is_global: values.isGlobal,
                    schedule_monday: values.scheduleMonday,
                    schedule_tuesday: values.scheduleTuesday,
                    schedule_wednesday: values.scheduleWednesday,
                    schedule_thursday: values.scheduleThursday,
                    schedule_friday: values.scheduleFriday,
                    schedule_saturday: values.scheduleSaturday,
                    schedule_sunday: values.scheduleSunday,
                    schedule_hours_start: values.scheduleHoursStart,
                    schedule_hours_end: values.scheduleHoursEnd
                }
            );
            return response.data;
        } catch (error) {
            return rejectWithValue({
                status: error.response.status,
                data: error.response.data
            });
        }
    }
);

// retourner le détail d'une contrainte horaires
export const get_constraint_schedule_detail = createAsyncThunk(
    "mod_profil/get_constraint_schedule_detail",
    async (values, { rejectWithValue }) => {
        try {
            const response = await httpGet(
                `mod_profil/groups/${values.groupId}/configurations/${values.configurationId}/constraints_schedule/${values.constraintId}/`
            );
            return response.data;
        } catch (error) {
            return rejectWithValue({
                status: error.response.status,
                data: error.response.data
            });
        }
    }
);

// modifier une contrainte horaire
export const update_constraint_schedule_detail = createAsyncThunk(
    "mod_profil/update_constraint_schedule_detail",
    async (values, { rejectWithValue }) => {
        try {
            const response = await httpPut(
                `mod_profil/groups/${values.groupId}/configurations/${values.configurationId}/constraints_schedule/${values.constraintId}/`,
                {
                    name: values.name,
                    equipment_type: values.equipmentType,
                    is_global: values.isGlobal,
                    schedule_monday: values.scheduleMonday,
                    schedule_tuesday: values.scheduleTuesday,
                    schedule_wednesday: values.scheduleWednesday,
                    schedule_thursday: values.scheduleThursday,
                    schedule_friday: values.scheduleFriday,
                    schedule_saturday: values.scheduleSaturday,
                    schedule_sunday: values.scheduleSunday,
                    schedule_hours_start: values.scheduleHoursStart,
                    schedule_hours_end: values.scheduleHoursEnd
                }
            );
            return response.data;
        } catch (error) {
            return rejectWithValue({
                status: error.response.status,
                data: error.response.data
            });
        }
    }
);

// Supprimer une contrainte horaire
export const delete_constraint_schedule_detail = createAsyncThunk(
    "mod_profil/delete_constraint_schedule_detail",
    async (values, { rejectWithValue }) => {
        try {
            const response = await httpDelete(
                `mod_profil/groups/${values.groupId}/configurations/${values.configurationId}/constraints_schedule/${values.constraintId}/`
            );
            return {...response.data, id: values.constraintId};
        } catch (error) {
            return rejectWithValue({
                status: error.response.status,
                data: error.response.data
            });
        }
    }
);

const constraintScheduleSlice = createSlice({
    name: "constraintSchedule",
    initialState: {
        loading: true,
        constraintsSchedule: [],
        constraintsScheduleDetail: {}
    },
    extraReducers: (builder) => {
        builder
            /* get_constraint_schedule_paginated */
            .addCase(get_constraint_schedule_paginated.pending, (state, action) => {
                state.constraintsSchedule = [];
            })
            .addCase(get_constraint_schedule_paginated.fulfilled, (state, action) => {
                state.loading = false;
                state.constraintsSchedule = action.payload.results;
            })
            .addCase(get_constraint_schedule_paginated.rejected, (state, action) => {
                state.loading = false;
            })

            /* create_constraint_schedule */
            .addCase(create_constraint_schedule.fulfilled, (state, action) => {
                state.loading = false;
                action.payload.index = action.payload.id;
                state.constraintsSchedule = [
                    ...state.constraintsSchedule,
                    action.payload
                ];
            })
            .addCase(create_constraint_schedule.rejected, (state, action) => {
                state.loading = false;
            })

            /* get_constraint_schedule_detail */
            .addCase(get_constraint_schedule_detail.pending, (state, action) => {
                state.constraintsScheduleDetail = {};
            })
            .addCase(get_constraint_schedule_detail.fulfilled, (state, action) => {
                state.loading = false;
                state.constraintsScheduleDetail = action.payload;
            })
            .addCase(get_constraint_schedule_detail.rejected, (state, action) => {
                state.loading = false;
            })

            /* update_constraint_schedule_detail */
            .addCase(update_constraint_schedule_detail.fulfilled, (state, action) => {
                state.loading = false;
                const updatedConstraintSchedule = action.payload;
                const updatedConstraintsSceduleList = state.constraintsSchedule.map(
                    (constraint) => {
                        if (constraint.id === updatedConstraintSchedule.id) {
                            return updatedConstraintPeriod;
                        }
                        return constraint;
                    }
                );
                state.constraintsSchedule = updatedConstraintsSceduleList;
            })
            .addCase(update_constraint_schedule_detail.rejected, (state, action) => {
                state.loading = false;
            })

            /* delete_constraint_schedule_detail */
            .addCase(delete_constraint_schedule_detail.fulfilled, (state, action) => {
                state.loading = false;
                const constraintScheduleToDelete = action.payload.id;
                state.constraintsSchedule = state.constraintsSchedule.filter(
                    (constraint) => constraint.id !== constraintScheduleToDelete
                );
            })
            .addCase(delete_constraint_schedule_detail.rejected, (state, action) => {
                state.loading = false;
            });
    }
});

export default constraintScheduleSlice.reducer;
