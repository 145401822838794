import React, { useState } from "react";
import { Modal } from "../../modUtils/components/componentsLibrary/componentsLibrary";
import EntityGroupForm from "./EntityGroupForm";
import { useTranslation } from "react-i18next";

export default function AddEntityGroupModal({
    entityCompany,
    closeModal,
    getEntities
}) {
    const { t } = useTranslation();
    const [modalVisible, setModalVisible] = useState(false);

    const handleCancel = () => {
        setModalVisible(false);
    };

    const handleClick = () => {
        setModalVisible(true);
        closeModal();
    };

    return (
        <>
        <div onClick={() => handleClick()}>
            {t("addEntityGroupModal.createGroup")}
        </div>
       
        <Modal
            title={t("addEntityGroupModal.createGroup")}
            isOpen={modalVisible}
            onClose={() => setModalVisible(false)}
        >
            <EntityGroupForm
                showSelectEntityType={false}
                entityCompanyParent={entityCompany}
                closeModal={handleCancel}
                fetchEntities={getEntities}
            />
        </Modal>
        </>
    );
};
