// on set la variable isProd a true quand le site est en ligne , on la passe a false uniquement si on est sur l'url de dev
// je prefère la mettre a true par défaut pour éviter les risques

const isProd = process.env.NODE_ENV === 'production' && window.location.origin !== 'https://dev.v3.ck-wash.fr';
const srcImg = process.env.PUBLIC_URL + "/img/menuIcons/";
// const appLogo = process.env.PUBLIC_URL + "/ckwashLogo.png";
const appLogo = process.env.PUBLIC_URL + "/ckwashLogo.svg"
const appName = "CkWash Manager"
const appShortName = "CkWash"
const contactPhoneNumber = "0 473 289 246"

const UrlMobileAppApple = "https://apps.apple.com/fr/app/ckwash-manager/id6477200533"
const UrlMobileAppAndroid = "https://play.google.com/store/apps/details?id=fr.cksquare.ckwash_manager"

export { isProd, srcImg, appLogo, UrlMobileAppApple, UrlMobileAppAndroid, appName, appShortName, contactPhoneNumber };
