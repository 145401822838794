import React from "react";
import { useTranslation } from "react-i18next";
import ConfigurationDetails from "./ConfigurationDetails";
import { Modal } from "../../modUtils/components/componentsLibrary/componentsLibrary";

export default function AddConfigurationModal({
    profil,
    isModalVisible,
    setIsModalVisible,
    closePopover
}) {
    const { t } = useTranslation();

    const closeModal = () => {
        setIsModalVisible(false);
    };

    closePopover && closePopover();

    return (
        <Modal
            title={t("addConfigurationModal.addConfigurationProfile")}
            isOpen={isModalVisible}
            onClose={closeModal}
        >
            <div className="mb-4 flex  justify-center flex-row ">
                <img
                    src={profil?.service_type_image}
                    alt={profil?.service_type_label}
                />

                <div className="text-center text-lg font-semibold text-primary-color ml-2">
                    {profil?.name}
                </div>
            </div>

            <ConfigurationDetails
                selectedProfilId={profil?.id}
                closeModal={closeModal}
            />
        </Modal>
    );
};
