import React, { useEffect, useRef, useState } from "react";
import { Checkbox, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import ProfilList from "../components/ProfilList";
import ProfilDetails from "../components/ProfilDetails";
import AddProfilModal from "../components/AddProfilModal";
import ConfigurationInfoContainer from "../components/ConfigurationInfoContainer";
import { ReactComponent as ActiveProfilIcon } from "../../modEntity/assets/images/check-circle-white.svg";
import { get_configuration_detail_by_profil } from "../slices/configurationSlice";
import { get_profil_paginated } from "../slices/profilSlice";
import SearchInput from "../../modUtils/components/searchInput/SearchInput";
import { Card, Pagination } from "../../modUtils/components/componentsLibrary/componentsLibrary";
import ButtonAdd from "../../modUtils/components/buttons/ButtonAdd";
import SubmitButton from "../../modUtils/components/buttons/submitButton/SubmitButton";
import AlertMessage from "../../modUtils/components/AlertMessage";
import AddConfigurationModal from "../components/AddConfigurationModal";

export default function ProfilsPage() {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { groupId } = useSelector((state) => state.manager);
    const { profils, profilDetail, profilsCount } = useSelector((state) => state.profil.profil);
    const { configurationDetail } = useSelector((state) => state.profil.configuration);
    const [inputSearchValue, setInputSearchValue] = useState();
    const [selectedProfilId, setSelectedProfilId] = useState(); //stocker l'id du profil sélectionné
    const [selectedConfigId, setselectedConfigId] = useState(); //stocker l'id de la configuration sélectionné
    const [filterProfilByName, setFilterProfilByName] = useState();
    const [isOneNodeExpanded, setIsOneNodeExpanded] = useState(false)
    const [isModalAddProfilVisible, setIsModalAddProfilVisible] = useState();
    const showActiveProfilOnly = true;
    const [currentPage, setCurrentPage] = useState(1);
    const [limit, setLimit] = useState(20);
    const [loading, setLoading] = useState(false);
    const groupIdRef = useRef(null); // Utiliser useRef pour stocker la dernière valeur envoyée
    const [showExpiredconfigue, setShowExpiredconfigue] = useState(false);
    const [isAddConfigrationModalVisible, setIsAddConfigurationModalVisible] = useState();

    // Met à jour la référence chaque fois que le groupId change, avec un délai
    useEffect(() => {
        const timer = setTimeout(() => {
            groupIdRef.current = groupId;
            setSelectedProfilId(null);
            setselectedConfigId(null); 
        }, 500);
        // Nettoyage du timer si le composant est démonté ou si le groupId change avant que le délai soit écoulé
        return () => clearTimeout(timer);
    }, [groupId]);

    //fonction appelée quand on clique sur le bouton exécuter pour chercher les profil par nom
    const onExecuteSubmitButton = () => {
        setFilterProfilByName(inputSearchValue);
    };

    // getProfils appelle l'api get_profil_paginated pour récupérer la liste des profils
    const getProfils = async (newOffset, name, idGroup) => {
        setLoading(true)
        try {
            await dispatch(
                get_profil_paginated({
                    groupId: idGroup ? idGroup : groupId,
                    limit: limit,
                    offset: newOffset,
                    name: name ? name : (inputSearchValue?.length > 0 ? inputSearchValue : null),
                    isActive: showActiveProfilOnly
                })
            ).unwrap();
        } catch (error) {
            message.error({
                content: (
                    <AlertMessage
                        status={error?.status}
                        alertMessage={`Une erreur est survenue lors de la récupération des profils !`}
                        errorDetail={error?.data?.detail}
                    />
                )
            });
        }finally {
            setLoading(false)
        }
    };

    const getConfigurationDetail = async (selectedProfilId, selectedConfigId) => {
        if (selectedProfilId) {
            const response = await dispatch(
                get_configuration_detail_by_profil({ groupId, profilId: selectedProfilId, configurationId: selectedConfigId })
            ).unwrap();
            setSelectedProfilId(selectedProfilId)
            return response;
        }
    };

    useEffect(() => {
        // Vérifie si selectedEntity est défini et que le groupId n'a pas changé
        if (selectedProfilId && selectedConfigId && groupIdRef.current === groupId) {
           getConfigurationDetail(selectedProfilId, selectedConfigId);
        }
   }, [groupId, selectedConfigId]);

    // Fonction de gestion de changement de pagination
    function handlePaginationChange(page, pageSize) {
        // Mettez à jour les valeurs de limit et offset en fonction de la page et de la taille de page
        const newOffset = (page - 1) * pageSize;
        if (newOffset < 0) {
            setCurrentPage(1);
        } else {
            setCurrentPage(page);
        }
        setLimit(pageSize);

        // Effectuez une requête à l'API avec les nouvelles valeurs de limit et offset pour obtenir les données correspondantes
        !loading && getProfils(newOffset);
    };

    // modifie la valeur de showExpiredconfigue
    const handleOnChangeCheckbox = () => {
        setShowExpiredconfigue(!showExpiredconfigue);
    };

    return (
        <>
        <div className="flex flex-col max-h-[100vh]">
            <div className="flex flex-row gap-1">
                <div className="w-1/4 pr-2 min-w-72 ">
                    <SearchInput
                        value={inputSearchValue}
                        onChange={(e) => setInputSearchValue(e.target.value)}
                        placeholder={t("profilsPage.searchProfile")}
                        fetchPressEnter={(value) => getProfils(0, value, groupIdRef.current)}
                    />
                </div>

                <div className="w-3/4 pl-2">
                    <SubmitButton onClick={onExecuteSubmitButton}/>
                </div>
            </div>

            <div className="flex flex-row grow gap-1 mt-2 overflow-y-clip">
                <div className="pr-2 w-[320px]">
                    <Card className="w-full h-full overflow-y-auto">
                        <div className="flex flex-col items-center mt-2 mx-1">
                            <ButtonAdd
                                label={t("profilsPage.createNewProfile")}
                                onClick={() => setIsModalAddProfilVisible(true)}
                            />

                            {showActiveProfilOnly && (
                                <div className="flex flex-row bg-primary-color items-center h-12 w-full rounded mb-2 mt-4 p-4">
                                    <ActiveProfilIcon/>
                                    
                                    <div className="uppercase text-white font-semibold ml-4">
                                        {t("profilsPage.activeProfiles")}
                                    </div>
                                </div>
                            )}

                            <div className="my-4 flex">
                                <Checkbox onChange={handleOnChangeCheckbox} className="h-[16px]">
                                    <span className="text-primary-color font-medium">
                                        {t("profilsPage.showExpiredConfigue")}
                                    </span>
                                </Checkbox>
                            </div>
                        </div>

                        {profilsCount > 0 &&
                            <div className="flex justify-center">
                                <Pagination
                                    defaultCurrent={currentPage}
                                    defaultPageSize={limit}
                                    showTotal={false}
                                    total={profilsCount}
                                    onChange={handlePaginationChange}
                                    onShowSizeChange={handlePaginationChange}
                                    hideOnSinglePage={false}
                                    showSizeChange={false}
                                    showQuickJumper={false}
                                />
                            </div>
                        }
                        
                        <ProfilList
                            nameFilterValue={filterProfilByName}
                            saveSelectedProfilCallback={setSelectedProfilId}
                            saveSelectedConfigurationCallback={setselectedConfigId}
                            setIsOneNodeExpanded={setIsOneNodeExpanded}
                            getProfils={getProfils}
                            showExpiredconfigue={showExpiredconfigue}
                        />
                    </Card>
                </div>

                <div
                    className={`w-3/4 pl-2 min-w-80 ${
                        (!selectedProfilId || !isOneNodeExpanded) && "opacity-50 pointer-events-none"
                    }`}
                >
                    <ProfilDetails selectedProfilId={selectedProfilId}/>

                    {!selectedConfigId ?
                        <div className="flex items-center justify-center h-1/2">
                            <ButtonAdd
                                label={t("profilNode.addConfiguration")}
                                onClick={() => setIsAddConfigurationModalVisible(true)}
                                
                            />

                            {isAddConfigrationModalVisible && (
                                <AddConfigurationModal
                                    isModalVisible={isAddConfigrationModalVisible}
                                    setIsModalVisible={setIsAddConfigurationModalVisible}
                                    profil={profils.find(profil => profil.id === selectedProfilId)}
                                />
                            )}
                        </div>
                    :
                    
                        <div className={`h-full ${!selectedConfigId && "opacity-50 pointer-events-none"}`}>
                            <Card className={`w-full h-full min-h-[77vh] max-h-[77vh] overflow-y-auto`}>
                                <ConfigurationInfoContainer
                                    profil={profilDetail}
                                    selectedProfilId={selectedProfilId}
                                    configuration={configurationDetail}
                                    selectedConfigId={selectedConfigId}
                                    getConfigurationDetail={getConfigurationDetail}
                                />
                            </Card>
                        </div>
                    }
                </div>
            </div>
        </div>

        {isModalAddProfilVisible && (
            <AddProfilModal
                isModalVisible={isModalAddProfilVisible}
                setIsModalVisible={setIsModalAddProfilVisible}
            />
        )}
        </>
    );
};
