import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Input,
  Modal,
} from "../../../modUtils/components/componentsLibrary/componentsLibrary";
import SubmitButton from "../../../modUtils/components/buttons/submitButton/SubmitButton";
import Tabs, { TabPane } from "../../../modUtils/components/componentsLibrary/tabs/Tabs";
import EquipmentSales from "../../../modSale/components/equipmentSales/EquipmentSales";
import EquipmentEventsHistory from "../equipmentEventsHistory/EquipmentEventsHistory";
import DevicesHistory from "../devicesHistory/DevicesHistory";
import EquipmentCredit from "../../../modFixing/components/equipmentCredit/EquipmentCredit";
import PermissionDeniedPage from "ck_commun/src/app/modUtils/components/errorPage/PermissionDeniedPage";
import { hasPermission } from "ck_commun/src/app/modUtils/functions/handlePermissions";
import { useTranslation } from "react-i18next";

export default function EquipmentFixingModal({
  equipment,
  siteId,
  isModalVisible,
  setIsModalVisible,
}) {
  const [openTabLeft, setOpenTabLeft] = useState();
  const [openTabRight, setOpenTabRight] = useState();
  const { selectedGroup } = useSelector((state) => state.manager);
  const permissions = selectedGroup?.permissions;
  const verifyPermission = hasPermission('mod_equipment:can_fixing', permissions);
  const groupPermissions = selectedGroup?.plan?.permissions;
  const verifyGroupPermission = hasPermission('mod_equipment:can_fixing', groupPermissions);
  const { t } = useTranslation();

  useEffect(() => {
    setOpenTabLeft("1");
    setOpenTabRight("6");
  }, []);

  return (
    <>
      <Modal
        title={t("equipmentFixingModal.title")}
        isOpen={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        width={1300}
      >
        {!verifyPermission ?
            <PermissionDeniedPage
                permission={verifyGroupPermission}
            />
        :
            <div className="flex-display flex-row justify-content-around">
                <div style={{ width: "65%" }}>
                    <Tabs
                    onChange={(key) => setOpenTabLeft(key)}
                    headerMatchesBodyWidth
                    tabHeight={710}
                    openTab={openTabLeft}
                    >
                    <TabPane label={t("equipmentFixingModal.saleLabel")} tabKey="1">
                        <EquipmentSales equipment={equipment} />
                    </TabPane>
                    <TabPane label={t("equipmentFixingModal.eventLabel")} tabKey="2">
                        <EquipmentEventsHistory equipment={equipment} />
                    </TabPane>
                    <TabPane label={t("equipmentFixingModal.deviceLabel")} tabKey="3">
                        <DevicesHistory equipment={equipment} />
                    </TabPane>
                    {/* <TabPane label="CAMERAS" tabKey="4">
                        <BuildingPage />
                    </TabPane> */}
                    </Tabs>
                </div>
                <div style={{ width: "33%" }}>
                    <Tabs
                    onChange={(key) => setOpenTabRight(key)}
                    headerMatchesBodyWidth
                    tabHeight={710}
                    openTab={openTabRight}
                    >
                    <TabPane label={t("equipmentFixingModal.refundLabel")} tabKey="5" disabled={true}>
                        <div style={{ height: 718, padding: "5%" }}>
                        <Input label={t("equipmentFixingModal.name")}></Input>
                        <Input label={t("equipmentFixingModal.reason")}></Input>
                        <Input label={t("equipmentFixingModal.amount")}></Input>
                        {/* <Search
                        placeholder="Recherche Client, N°Badge.."
                        onSearch={onSearch}
                        enterButton
        /> */}
                        {/* <Form
                            name="basic"
                            labelCol={{ span: 10 }}
                            wrapperCol={{ span: 16 }}
                            layout="vertical"
                        >
                            <Form.Item
                            name="remboursTypeCheckbox"
                            label="TYPE DE REMBOURSEMENT"
                            >
                            <Checkbox.Group>
                                <Row>
                                <Col>
                                    <Checkbox
                                    value="QRCode"
                                    style={{ lineHeight: "32px" }}
                                    >
                                    QRCode
                                    </Checkbox>
                                </Col>
                                <Col>
                                    <Checkbox value="crediterBadge">
                                    Créditer badge
                                    </Checkbox>
                                </Col>
                                <Col>
                                    <Checkbox value="CrediterCompte">
                                    Créditer compte
                                    </Checkbox>
                                </Col>
                                </Row>
                            </Checkbox.Group>
                            </Form.Item>

                            <Form.Item name="typeEnvoiCheckbox" label="TYPE D'ENVOI">
                            <Checkbox.Group>
                                <Row>
                                <Col>
                                    <Checkbox value="smsCh">SMS</Checkbox>
                                </Col>
                                <Col>
                                    <Checkbox value="emailCh">Email</Checkbox>
                                </Col>
                                </Row>
                            </Checkbox.Group>
                            </Form.Item>
                        </Form> */}

                        <Input label="EMAIL" />

                        {/* <Form.Item label="MESSAGE" name="message">
                            <Input.TextArea allowClear showCount />
                        </Form.Item>
        */}
                        <div className="flex-display justify-content-center">
                            <SubmitButton></SubmitButton>
                        </div>
                        </div>
                    </TabPane>

                    <TabPane label={t("equipmentFixingModal.creditEquipment")} tabKey="6">
                        <EquipmentCredit
                        equipment={equipment}
                        siteId={siteId}
                        ></EquipmentCredit>
                    </TabPane>
                    </Tabs>
                </div>
            </div>
        }
      </Modal>
    </>
  );
}
